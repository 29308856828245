import { CURRENT_USER, HOME_PAGE_UNLOADED } from "../constants/actionTypes";

const defaultState = { currentAdmin: null, currentError: false };
export default (state = { ...defaultState }, action) => {
    switch (action.type) {
        case CURRENT_USER: {
            return {
                ...state,
                currentError: !!action.error,
                currentAdmin: action.error ? null : action.payload.admin,
            };
        }
        case HOME_PAGE_UNLOADED:
            return { ...defaultState };
        default: {
            if (action.error && action.payload && action.payload.errors) {
                return {
                    ...state,
                    errors: action.payload.errors,
                };
            }
            return { ...state, errors: null };
        }
    }
};
