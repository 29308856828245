import React from "react";
import agent from "agent";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Statistic, Row, Col, Button, Divider } from "antd";
import { Link } from "react-router-dom";
import ReLogin from "components/ReLogin";
import Loading from "components/Loading";

import {
    DASHBOARD_PAGE_UNLOADED,
    DASHBOARD_PAGE_LOADED,
    USER_CRATE,
} from "constants/actionTypes";

const mapStateToProps = state => ({
    ...state.dashboard,
});

const mapDispatchToProps = dispatch => ({
    onLoad: () =>
        dispatch({
            type: DASHBOARD_PAGE_LOADED,
            payload: agent.Dashboard.load(),
        }),
    onUnload: () => dispatch({ type: DASHBOARD_PAGE_UNLOADED }),
    onCreateUser: payload =>
        dispatch({
            type: USER_CRATE,
            payload,
        }),
});

class Dashboard extends React.Component {
    static propTypes = {
        results: PropTypes.object.isRequired,
        loading: PropTypes.bool.isRequired,
        onLoad: PropTypes.func.isRequired,
        onUnload: PropTypes.func.isRequired,
        onCreateUser: PropTypes.func.isRequired,

        fetchError: PropTypes.bool.isRequired,
    };
    state = {
        userAdd: false,
    };
    componentDidMount() {
        const { onLoad } = this.props;

        onLoad();
    }
    handleUserAdd = e => {
        this.setState({ userAdd: true });
        this.props.onCreateUser();
        this.props.history.push("/user");
    };
    componentWillUnmount() {
        this.props.onUnload();
    }
    render() {
        const { results, history, fetchError, loading } = this.props;
        if (fetchError) {
            return <ReLogin history={history} />;
        }
        if (loading) {
            return <Loading />;
        }
        const {
            user,
            producer,
            audio,
            purchase,
            withdraw,
            contactus,
        } = results;
        return (
            <div>
                <Row gutter={16}>
                    <Col span={6}>
                        <Statistic title="Active Users" value={user.normal} />

                        <Button
                            style={{ marginTop: 16 }}
                            onClick={this.handleUserAdd}
                            type="primary"
                            loading={this.state.userAdd}
                            disabled={this.state.userAdd}
                        >
                            添加用户
                        </Button>
                    </Col>
                    <Col span={6}>
                        <Statistic
                            title="卖家总数"
                            value={producer.normal}
                            precision={0}
                        />
                        <Statistic
                            style={{ marginTop: 16 }}
                            title="待审核的卖家"
                            value={producer.unreviewed}
                            precision={0}
                        />
                        <Link to="/producer/review">审核卖家</Link>
                    </Col>
                    <Col span={6}>
                        <Statistic title="音乐总数" value={audio.normal} />
                        <Statistic
                            style={{ marginTop: 16 }}
                            title="待审核的音乐数"
                            value={audio.unreviewed}
                        />
                        <Link to="/audio/review">审核音乐</Link>
                    </Col>
                    <Col span={6}>
                        <Statistic title="交易总数" value={purchase.total} />
                        <Statistic
                            style={{ marginTop: 16 }}
                            title="待审核的线下交易数"
                            value={purchase.unreviewed}
                        />
                        <Link to="/purchase/review">审核线下交易</Link>
                    </Col>
                </Row>
                <Divider />

                <Row gutter={16}>
                    <Col span={6}>
                        <Statistic
                            title="结算总数"
                            value={withdraw.total}
                            precision={0}
                        />
                        <Statistic
                            style={{ marginTop: 16 }}
                            title="未处理的结算"
                            value={withdraw.unpaid}
                            precision={0}
                        />
                        <Link to="/withdraw/review">处理结算</Link>
                    </Col>
                    <Col span={6}>
                        <Statistic
                            title="联系我们"
                            value={contactus.total}
                            precision={0}
                        />
                        <Statistic
                            style={{ marginTop: 16 }}
                            title="未处理"
                            value={contactus.unreviewed}
                            precision={0}
                        />
                        <Link to="/page/contactus/review">处理联系我们</Link>
                    </Col>
                </Row>
            </div>
        );
    }
}
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Dashboard);
