import React from "react";
import { connect } from "react-redux";
import { Route, Switch } from "react-router-dom";
import NotFound from "components/NotFound";
import List from "components/Audio/List";
import Review from "components/Audio/Review";
import Playlist from "components/Audio/Playlist";
import Susume from "components/Audio/Susume";

const mapStateToProps = state => ({
    ...state.audio,
});

const mapDispatchToProps = dispatch => ({});

class Audio extends React.Component {
    render() {
        const { match } = this.props;
        const basepath = match.path === "/" ? "" : match.path;

        return (
            <Switch>
                <Route exact path={`${basepath}/list`} component={List} />
                <Route exact path={`${basepath}/review`} component={Review} />
                <Route path={`${basepath}/playlist`} component={Playlist} />
                <Route path={`${basepath}/susume`} component={Susume} />
                <Route exact component={NotFound} />
            </Switch>
        );
    }
}
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Audio);
