import {
    PURCHASE_PAGE_LOADED,
    PURCHASE_PAGE_UNLOADED,
    PURCHASE_STATUS_UPDATE,
    ASYNC_START,
    ORDER_PURCHASE,
    PURCHASE_PAGE_OPEN,
    PURCHASE_DERIVE,
    PURCHASE_DERIVE_FALSE,
} from "../constants/actionTypes";

const defaultState = {
    results: { purchases: [] },
    pagination: { pageSize: 10, current: 1 },
    loading: false,
    fetchError: false,
    updateStatus: { orderid: "-1", status: "", loading: false },
    subscribe: '',
    purchaseDerive: false,
};
export default (state = { ...defaultState }, action) => {
    switch (action.type) {
        case PURCHASE_PAGE_LOADED: {
            const pagination = action.pager;
            if (!action.error) {
                pagination.total = action.payload.total;
            }
            return {
                ...state,
                fetchError: !!action.error,
                results: action.payload,
                pagination,
                loading: false,
            };
        }
        case PURCHASE_DERIVE: {

            return {
                ...state,
                purchaseDerive: true
            };
        }
        case PURCHASE_DERIVE_FALSE: {

            return {
                ...state,
                purchaseDerive: false
            };
        }
        case PURCHASE_STATUS_UPDATE: {
            const updateStatus = { ...state.updateStatus };
            updateStatus.orderid = action.error ? "-1" : action.send.orderid;
            updateStatus.status = action.send.status;
            updateStatus.loading = false;
            return { ...state, updateStatus };
        }
        case ORDER_PURCHASE: {
            const updateStatus = { ...state.updateStatus };
            if (!action.error) {
                updateStatus.orderid = action.send.orderid;
                updateStatus.status = "processed";
                updateStatus.loading = false;
            } else {
                updateStatus.orderid = "";
            }
            return { ...state, updateStatus };
        }
        case PURCHASE_PAGE_OPEN: {

            return {
                ...state,
                subscribe: action.subscribe,
                loading: false,
            };
        }
        case ASYNC_START:
            if (action.subtype === PURCHASE_PAGE_LOADED) {
                return { ...state, loading: true };
            }
            if (action.subtype === PURCHASE_STATUS_UPDATE) {
                const updateStatus = { ...state.updateStatus };
                updateStatus.orderid = action.send.orderid;
                updateStatus.status = action.send.status;
                updateStatus.loading = true;
                return { ...state, updateStatus };
            }
            if (action.subtype === ORDER_PURCHASE) {
                const updateStatus = { ...state.updateStatus };
                updateStatus.orderid = action.send.orderid;
                updateStatus.loading = true;
                return { ...state, updateStatus };
            }
            return state;
        case PURCHASE_PAGE_UNLOADED:
            return { ...defaultState };
        default:
            return state;
    }
};
