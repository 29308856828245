import React from "react";
import PropTypes from "prop-types";
// import { Link } from "react-router-dom";
import QRCode  from 'qrcode.react';
import {
    Form,
    // Input,
    // Tooltip,
    // Icon,
    // Cascader,
    // AutoComplete,
    // Avatar,
    Modal,
    // Upload,
} from "antd";
// import agent from "agent";

import ListErrors from "components/ListErrors";


class UserModal extends React.Component {
    static propTypes = {
        visible: PropTypes.bool,
        onUserModalOk: PropTypes.func,
        onUserModalCancels: PropTypes.func,
        user: PropTypes.object.isRequired,
        errors: PropTypes.object.isRequired,

    };
    state = {
        fileList: [],
    };

    static getDerivedStateFromProps(nextProps, prevState) {
        if (!nextProps.visible) {
            return { fileList: [] };
        }
        return null;
    }

    handleSubmit = e => {
        e.preventDefault();
    };

    render() {
        const {
            visible,
            // confirmLoading,
            user,
            onUserModalCancels,
        } = this.props;
        // const { getFieldDecorator, getFieldValue } = this.props.form;
        // const tltle = user.userid ? `Edit user: ${user.userid}` : `Create User`;
        // const { fileList } = this.state;
        const url = "https://www.musiness.vip/addon/"+user.id;
        return (
            <Modal
                // title={tltle}
                centered
                destroyOnClose
                // confirmLoading={confirmLoading}
                visible={visible}
                footer={null}
                width={1}
                // onOk={this.handleUserModalOk}
                onCancel={onUserModalCancels}
            >
                <ListErrors errors={this.props.errors} />
                <QRCode
                    value={url} //value参数为生成二维码的链接
                    size={200} //二维码的宽高尺寸
                    fgColor="#000000"  //二维码的颜色
                    style={{margin:"auto"}}
                />
                <div>
                    链接：<span>{url}</span>
                </div>
                <div slot="footer"></div>
            </Modal>
        );
    }
}

export default Form.create({ name: "UserModal" })(UserModal);
