import React from "react";
import { Table, Button } from "antd";
import agent from "agent";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
    PRODUCER_PAGE_UNLOADED,
    PRODUCER_PAGE_LOADED,
    PRODUCER_STATUS_UPDATE,
} from "constants/actionTypes";

import ReLogin from "components/ReLogin";
import "./Producer.less";

import columnsCreate from "./columns";
const columns = columnsCreate();

for (const record of columns) {
    if (record.dataIndex === "user_id") {
        delete record.sorter;
    } else if (record.dataIndex === "status") {
        delete record.filters;
    }
}

const mapStateToProps = state => ({
    ...state.producer,
});

const mapDispatchToProps = dispatch => ({
    onLoad: (pager, payload) =>
        dispatch({ type: PRODUCER_PAGE_LOADED, pager, payload }),
    onUnload: () => dispatch({ type: PRODUCER_PAGE_UNLOADED }),
    onUpdateStatus: send =>
        dispatch({
            type: PRODUCER_STATUS_UPDATE,
            send,
            payload: agent.Producer.putStatus(send),
        }),
});

class Producer extends React.Component {
    static propTypes = {
        pagination: PropTypes.object.isRequired,
        results: PropTypes.object.isRequired,
        loading: PropTypes.bool.isRequired,
        onLoad: PropTypes.func.isRequired,
        onUnload: PropTypes.func.isRequired,
        fetchError: PropTypes.bool.isRequired,
        onUpdateStatus: PropTypes.func.isRequired,

        updateStatus: PropTypes.shape({
            user_id: PropTypes.number.isRequired,
            status: PropTypes.string.isRequired,
            loading: PropTypes.bool.isRequired,
        }).isRequired,
    };

    componentDidMount() {
        this.handleRefreashPage();
    }

    componentWillUnmount() {
        this.props.onUnload();
    }

    handleRefreashPage = e => {
        const { onLoad, pagination } = this.props;
        const pager = { ...pagination };
        pager.current = 1;
        const params = {
            page: pager.current,
            size: pagination.pageSize,
            orderby: "-user_id",
            status: "unreviewed",
        };
        onLoad(pager, agent.Producer.get(params));
    };

    handleTableChange = (pagination, filters, sorter) => {
        const pager = { ...this.props.pagination };
        pager.current = pagination.current;
        const params = {
            page: pager.current,
            size: pagination.pageSize,
            orderby: "-user_id",
            status: "unreviewed",
        };

        this.props.onLoad(pager, agent.Producer.get(params));
    };

    render() {
        const {
            results,
            pagination,
            loading,
            history,
            fetchError,
            updateStatus,
            onUpdateStatus,
        } = this.props;
        if (fetchError) {
            return <ReLogin history={history} />;
        }
        pagination.total = results.total;

        const data = results.producers;

        for (const record of data) {
            if (updateStatus.user_id === record.user_id) {
                record.loading = updateStatus.loading;
                record.status = updateStatus.status;
            }

            record.handleStatusChange = value => {
                const send = {
                    user_id: record.user_id,
                    status: value,
                    userid: record.author.userid,
                };
                onUpdateStatus(send);
            };
        }
        return (
            <div>
                <Button
                    onClick={this.handleRefreashPage}
                    type="primary"
                    style={{ marginBottom: 16 }}
                >
                    获取新的数据
                </Button>
                <Table
                    className="c382-producer-list"
                    columns={columns}
                    rowKey={record => record.user_id}
                    dataSource={data}
                    pagination={pagination}
                    loading={loading}
                    onChange={this.handleTableChange}
                />
            </div>
        );
    }
}
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Producer);
