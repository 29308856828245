import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Form, Modal, Input, Divider, DatePicker, message, InputNumber } from "antd";
import helper from "utils/helper";
import agent from "agent";
import moment from 'moment';
import locale from 'antd/es/date-picker/locale/zh_CN';
import {
    USER_PAGE_LOADED,
    MEMBER_SET_ADD,
} from "constants/actionTypes";
//import agent from "agent";
import "./PriceUpdateModal.less";
const { Search } = Input;
const { RangePicker } = DatePicker;
const mapStateToProps = state => ({
    ...state.user,
});
const mapDispatchToProps = dispatch => ({
    onLoad: (pager, payload) =>
        dispatch({ type: USER_PAGE_LOADED, pager, payload }),
    add: (pager, payload) => dispatch({
        type: MEMBER_SET_ADD, pager, payload
    }),
});

class PriceAddModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            startTime: "",
            endTime: "",
            proshow: false,
            price: 1,
            remark: ''
        };
    }
    static propTypes = {
        addShow: PropTypes.bool,
        show: PropTypes.bool,
        userEditModal: PropTypes.bool,
        onModalOk: PropTypes.func,
        onModalCancel: PropTypes.func,
        usershow: PropTypes.func,
        confirmLoading: PropTypes.bool,
        onLoad: PropTypes.func.isRequired,
        results: PropTypes.object.isRequired,
        add: PropTypes.func.isRequired,
    };

    handleSubmit = e => {
        const { onLoad, pagination, usershow } = this.props;
        const pager = { ...pagination };
        pager.current = 1;
        const params = {
            page: 1,
            size: 10,
            orderby: "-id",
            username: e,
        };
        onLoad(pager, agent.User.get(params));
        usershow()
    };

    //提交数据
    handleAddModalOk = e => {
        const { add, results, pagination, onModalCancel, onModalOk } = this.props;
        if (results.users.length > 0) {
            const res = results.users[0].id || '';
            console.log(this.state.startTime, this.state.endTime)
            const pager = { ...pagination };
            const params = {
                userId: res,
                startTime: this.state.startTime,
                endTime: this.state.endTime,
                price: this.state.price,
                remark: this.state.remark || ''
            };
            if (this.state.startTime == '' || this.state.endTime == '') {
                message.info('请选择有效期后提交');
            }else if (this.state.price == -1) {
                message.info('请选择优惠价格');
            } else {

                onModalCancel(), add(pager, agent.UnMemberset.post(params))
                onModalOk(params)
            }
        }


    };
    onChange(date, dateString) {
        this.setState({ startTime: new Date(dateString[0].replace(/-/g, "/")).getTime() });
        this.setState({ endTime: new Date(dateString[1].replace(/-/g, "/")).getTime() });
    }
    handleorderidChange = e => {
        this.setState({ remark: e.target.value });
    }
    onLoad = () => {
        const { onLoad, pagination } = this.props;
        const pager = { ...pagination };
        pager.current = 1;
        const params = {
            page: pager.current,
            size: pagination.pageSize,
            orderby: "-id",
        };
        onLoad(pager, agent.User.get(params));

    };
    handleAddPriceGroup = e => { }
    info = () => {

    }
    onChangePriceType = value => {
        console.log(`selected ${value}`);
        this.setState({ price: value*100 });
    }
    render() {
        const {
            addShow,
            confirmLoading,
            onModalCancel,
            results,
            show
        } = this.props;
        console.log(this.props)
        let data = results.users[0] || []
        const { getFieldDecorator } = this.props.form;
        const formItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 8 },
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 16 },
            },
        };
        let list;
        if (show) {
            list =
                (
                    <div className="ys">
                        <span>ID: </span>
                        {data.id}
                        <Divider />
                        <span>Name: </span>
                        {data.nickname}
                        <Divider />
                        <span>Email: </span>
                        {data.email}
                        <Divider />
                        <span>Phone: </span>
                        {data.phone}
                        <Divider />
                        <span>注册时间: </span>
                        {helper.showDate(data.create_at)}
                        <Divider />
                        <span className='mar'>有效期: </span>
                        <RangePicker onChange={this.onChange.bind(this)} locale={locale} allowClear showTime={{
                            hideDisabledOptions: true,
                            defaultValue: [moment('00:00:00', 'HH:mm:ss'), moment('00:00:00', 'HH:mm:ss')],
                        }}
                        format="YYYY-MM-DD HH:mm:ss"
                        />
                        <Divider />
                        <span className='mar'>价格(元): </span>
                        {/* <Select
                            style={{ width: '300px' }}
                            placeholder="选择价格"
                            onChange={this.onChangePriceType}
                        >
                            <Option value={10}>0.1元</Option>
                            <Option value={100}>1元</Option>
                        </Select> */}
                        <InputNumber
                            style={{ width: '300px' }}
                            defaultValue={this.state.price/100}
                            min="0.01"
                            step="0.01"
                            size="large"
                            precision={2}
                            onChange={this.onChangePriceType}
                        />
                        <Divider />
                        <span className='mar'>备注: </span>
                        <Input placeholder="备注" style={{ width: '300px' }} value={this.state.remark} onChange={this.handleorderidChange} />
                    </div>
                )
        }
        const tltle = `添加`;
        const no = false;
        return (
            <Modal
                title={tltle}
                centered
                destroyOnClose
                confirmLoading={confirmLoading}
                visible={addShow}
                onOk={this.handleAddModalOk}
                onCancel={onModalCancel}
                maskClosable={no}
            >
                <Form onSubmit={this.handleSubmit}>
                    <Form.Item {...formItemLayout} label="用户搜索">
                        {getFieldDecorator("title", {
                            rules: [
                                {
                                    required: true,
                                    message: "请输入名称!",
                                },
                            ],
                        })(<Search
                            placeholder="请输入用户账号"
                            enterButton="查询"
                            size="default"
                            onSearch={this.handleSubmit}
                        />)}
                    </Form.Item>
                </Form>
                <div>
                    {list}
                </div>

            </Modal>
        );
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Form.create({ name: "PriceAddModal" })(PriceAddModal));
