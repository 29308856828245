import React from "react";

import { Divider, Button } from "antd";
import helper from "utils/helper";

import { HtmlUtil } from "dispatch";

const columns = {
    id: {
        title: "Id",
        dataIndex: "id",
        sorter: true,
        defaultSortOrder: "descend",
    },
    news_info: {
        title: "Info",
        render: record => {
            return (
                <div style={{  width: '400px', height: 'auto',wordWrap:'break-word',wordBreak:'break-all',
                    overflow: 'hidden'}}>
                    <span>标题: </span>
                    {HtmlUtil.htmlDecode(record.title)}
                    <Divider />
                    <span>发布者: </span>
                    {HtmlUtil.htmlDecode(record.creator)}
                    <Divider />
                    <span>发布时间: </span>
                    {helper.showDate(record.news_time)}
                    <Divider />
                    <span>排序: </span>
                    {record.weight}
                    <Divider />
                    {/* <span>内容: </span>
                    <p>{HtmlUtil.htmlDecode(record.news_content)}</p>
                    <Divider /> */}
                   
                </div>
            );
        },
    },
    action: {
        title: <span>Action</span>,

        render: record => {
            const disabled = record.loading;
            return (
                <div>
                    <div style={{ marginBottom: 20 }}>
                        <Button type="dashed" onClick={record.handleEditClick}>
                            编辑
                        </Button>
                    </div>
                    <Button
                        type="danger"
                        disabled={disabled}
                        loading={disabled}
                        onClick={record.handleDeleteClick}
                    >
                        删除
                    </Button>
                </div>
            );
        },
    },
};

export default columns;
