import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Form, Modal, InputNumber, Select } from "antd";
//import agent from "agent";
import "./PriceUpdateModal.less";
const mapStateToProps = state => ({
});

const mapDispatchToProps = dispatch => ({});

class PriceModal extends React.Component {
    static propTypes = {
        visible: PropTypes.bool,
        modalData: PropTypes.object,
        onModalOk: PropTypes.func,
        onModalCancel: PropTypes.func,
        condition: PropTypes.object,
        confirmLoading:PropTypes.bool,
    };

    handleSubmit = e => {
        e.preventDefault();
    };
    
    //提交数据
    handlePartnerModalOk = e => {
        const { form ,modalData,onModalOk} = this.props;
        form.validateFieldsAndScroll((err, values) => {
            const priceInfo=modalData.priceInfo;
            let send=[];
            for (let i = 0; i < priceInfo.length; i++) {
                if (priceInfo[i].father_id>0) {
                    const key="price_"+priceInfo[i].id;
                    const termkKey="term_"+priceInfo[i].id;
                    let item={
                        group_id:modalData.id,
                        to_use_id:priceInfo[i].id,
                        price:values[key],
                        term:values[termkKey],
                    };
                    send.push(item);
                }
                
            }
            onModalOk(send);
        })
    };

    //循环数据
    renderCell(data) {
        const {modalData} = this.props;
        const { getFieldDecorator } = this.props.form;
        const formItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 8 },
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 16 },
            },
        };
        if (data.father_id==0) {
            return[
                <div key={data.id} className="title_style">{data.title}:</div>,
                modalData.priceInfo.map((x, i) => {
                    const { id, title,father_id,price,term } = x;
                    if (father_id==data.id) {
                        return (
                            <div style={{height:"50px"}} key={i}>
                                <Form.Item {...formItemLayout} label={ title}>
                                    {getFieldDecorator("term_"+id, {
                                        initialValue: term.toString()||"0",
                                    })(
                                        <Select getPopupContainer={triggerNode => triggerNode.parentNode}>
                                            <Select.Option value='0'>
                                                随片永久
                                            </Select.Option>
                                            <Select.Option value='1'>
                                                一年
                                            </Select.Option>
                                        </Select>
                                    )}
                                </Form.Item>

                                <Form.Item {...formItemLayout}>
                                    {getFieldDecorator("price_"+id, {
                                        rules: [
                                            {
                                                required: false,
                                                message: "",
                                            },
                                        ],
                                        initialValue: price||0,
                                    })(<InputNumber  formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')} parser={value => value.replace(/\$\s?|(,*)/g, '')}/>)}
                                </Form.Item>
                            </div>
                        );
                    }
                
                }),
            ]
        }
    }


    render() {
        const {
            visible,
            confirmLoading,
            modalData,
            onModalCancel,
        } = this.props;
        if (!visible) {
            return null;
        }
       
        const data = modalData || {};
        const tltle =  `编辑: ${data.title}`;
        const no =false;
        return (
            <Modal
                title={tltle}
                centered
                destroyOnClose
                confirmLoading={confirmLoading}
                visible={visible}
                onOk={this.handlePartnerModalOk}
                onCancel={onModalCancel}
                maskClosable={no}
                className="price_update_modal"
            > 
                <Form onSubmit={this.handleSubmit}>
                    {data.priceInfo.map(this.renderCell.bind(this))}
                </Form>
            </Modal>
        );
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Form.create({ name: "PriceModal" })(PriceModal));
