import React from "react";
import { Button, Table, Select, Layout, Input, DatePicker } from "antd";
import agent from "agent";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import helper from "utils/helper";
// import XLSX from 'xlsx';
import ExportJsonExcel from 'js-export-excel';
import {
    PURCHASE_PAGE_UNLOADED,
    PURCHASE_PAGE_LOADED,
    PURCHASE_PAGE_OPEN,
    PURCHASE_DERIVE_FALSE,
    PURCHASE_DERIVE,
} from "constants/actionTypes";

import ReLogin from "components/ReLogin";
import locale from 'antd/es/date-picker/locale/zh_CN';

import columnsCreate from "./list-columns";
const columns = columnsCreate();
const Option = Select.Option;
const { Content } = Layout;
const { RangePicker } = DatePicker;
const mapStateToProps = state => ({
    ...state.purchase,
});

const mapDispatchToProps = dispatch => ({
    onLoad: (pager, payload) =>
        dispatch({ type: PURCHASE_PAGE_LOADED, pager, payload }),
    onUnload: () => dispatch({ type: PURCHASE_PAGE_UNLOADED }),
    onDerive: payload => {
        dispatch({
            type: PURCHASE_DERIVE,
            payload,
        })
    },
    fsDerive: payload => {
        dispatch({
            type: PURCHASE_DERIVE_FALSE,
            payload,
        })
    },

    openmember: (payload) =>
        dispatch({ type: PURCHASE_PAGE_OPEN, payload }),
});

class Purchase extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            orderid: "",
            username: "",
            start_time: "",
            end_time: "",
            type: '',
            status: '',
            receipt: '',
            modal2Visible: false,
            price: '',
            orderids: '',
            isdisabled: false,
        };
    }
    static propTypes = {
        pagination: PropTypes.object.isRequired,
        results: PropTypes.object.isRequired,
        loading: PropTypes.bool.isRequired,
        onLoad: PropTypes.func.isRequired,
        onUnload: PropTypes.func.isRequired,
        fetchError: PropTypes.bool.isRequired,
        openmember: PropTypes.func.isRequired,
        onDerive: PropTypes.func.isRequired,
        fsDerive: PropTypes.func.isRequired,
        purchaseDerive: PropTypes.bool
    };
    componentDidUpdate(prevProps) {
        console.log(prevProps, this.props)
        if (prevProps.purchaseDerive === true && this.props.purchaseDerive === true) {
            this.saveExcel()
            return
        }
    }
    componentDidMount() {
        this.onLoad()
    }

    onLoad = () => {
        const { onLoad, pagination } = this.props;
        const pager = { ...pagination };
        pager.current = 1;
        const params = {
            page: pager.current,
            size: pagination.pageSize,
            orderby: "-id",
        };
        onLoad(pager, agent.Purchase.get(params));
    };
    downloadExl = (e, d) => {
        const { pagination, onDerive } = this.props;
        const pager = { ...pagination };
        pager.current = 1;
        const params = {
            page: pager.current,
            size: pagination.total,
            orderby: "-id",
        };
        if (this.state.orderid) {
            params.orderid = this.state.orderid
        }
        if (this.state.username) {
            params.username = this.state.username
        }
        if (this.state.start_time || this.state.end_time) {
            params.start_time = this.state.start_time
            params.end_time = this.state.end_time
        }
        if (this.state.receipt || this.state.receipt === 0) {
            params.receipt = this.state.receipt
        }
        onDerive()
        this.props.onLoad(pager, agent.Purchase.get(params))

    }

    // componentWillUnmount() {
    //     // this.props.onUnload();
    // }
    onChange(date, dateString) {
        this.setState({ start_time: new Date(dateString[0].replace(/-/g, "/")).getTime() });
        this.setState({ end_time: new Date(dateString[1].replace(/-/g, "/")).getTime() });
    }
    saveExcel() {
        const { results, fsDerive } = this.props;  //从props中获取数据源
        let option = {};  //option代表的就是excel文件
        let dataTable = [];  //excel文件中的数据内容
        let result = results.purchases
        if (result && result.length > 0) {
            for (let i in result) {  //循环获取excel中每一行的数据
                //   for(let j in result[i].audios){
                //   }
                let audios = result[i].audios || []
                let attachment = JSON.parse(result[i].attachment) || {}
                let type = attachment && attachment.payType ? ({ 1: '支付宝', 2: "银行卡转账", '3': '支付宝', 4: '微信支付', 5: '微信支付2' }[attachment.payType] || attachment.payType) : ""
                let title = ''
                let nickname = ''
                let channel = ''
                let czr = ''
                let price = ''
                if (audios.length > 0) {
                    for (let a of audios) {
                        if (a && a.title) {
                            title = a.title
                        }
                        if (a && a.channel) {
                            channel = a.channel
                        }
                        if (a.author && a.author.nickname) {
                            nickname = a.author.nickname
                        } else if (a.author && a.author.email) {
                            nickname = a.author.email
                        } else if (a.author && a.author.phone) {
                            nickname = a.author.phone
                        }
                        if (a.creatorInfo && a.creatorInfo.nickname) {
                            czr = a.creatorInfo.nickname
                        }
                        if (a && a.price) {
                            price = a.price
                        }

                        let obj = {
                            '卖家': nickname,
                            '创作人': czr,
                            '订单ID': result[i].orderid || '',
                            '歌曲名称': title,
                            '交易时间': helper.showDate(result[i].create_at),
                            '单价（元）': price / 100,
                            '支付方式': type,
                            '用户名': attachment.username || '',
                            '联系人邮箱': attachment.email,
                            '联系人电话': attachment.phoneNumber,
                            '授权项目': attachment.area,
                            '授权渠道': channel,
                            '项目描述': attachment.description,
                        }
                        dataTable.push(obj);  //设置excel中每列所获取的数据源
                    }
                }

            }
        }
        console.log(dataTable)
        option.fileName = '交易记录表';  //excel文件名称
        option.datas = [
            {
                sheetData: dataTable,  //excel文件中的数据源
                sheetName: '交易记录表',  //excel文件中sheet页名称
                sheetFilter: ['卖家', '创作人', '订单ID', '歌曲名称', '交易时间', '单价（元）', '支付方式', '用户名', '联系人邮箱', '联系人电话', '授权项目', '授权渠道','项目描述'],  //excel文件中需显示的列数据
                sheetHeader: ['卖家', '创作人', '订单ID', '歌曲名称', '交易时间', '单价（元）', '支付方式', '用户名', '联系人邮箱', '联系人电话', '授权项目', '授权渠道','项目描述'],  //excel文件中每列的表头名称
                columnWidths: ['7','7', '15', '10', '9', '4', '5', '13', '10', '6', '10', '10','10']
            }
        ]


        let toExcel = new ExportJsonExcel(option);  //生成excel文件
        toExcel.saveExcel();  //下载excel文件
        fsDerive()
    }
    handleorderidChange = e => {
        this.setState({ orderid: e.target.value });
    }
    handleusernameChangea = e => {
        this.setState({ username: e.target.value });
    }
    handleTableChange = (pagination, filters, sorter) => {
        const pager = { ...this.props.pagination };
        pager.current = pagination.current;
        let orderby = "-id";
        if (sorter && sorter.field) {
            orderby = sorter.field;
            if (sorter.order === "descend") {
                orderby = "-" + sorter.field;
            }
        }

        const params = {
            page: pager.current,
            size: pagination.pageSize,
            orderby,
            ...filters,
        };
        if (this.state.orderid) {
            params.orderid = this.state.orderid
        }
        if (this.state.username) {
            params.username = this.state.username
        }
        if (this.state.start_time || this.state.end_time) {
            params.start_time = this.state.start_time
            params.end_time = this.state.end_time
        }
        if (this.state.receipt || this.state.receipt === 0) {
            params.receipt = this.state.receipt
        }
        this.props.onLoad(pager, agent.Purchase.get(params));
    };
    handlereceiptChange = e => {
        this.setState({ receipt: Number(e) });
    }
    handleAddPriceGroup = e => {
        const { onLoad, pagination } = this.props;
        const pager = { ...pagination };
        pager.current = 1;
        const params = {
            page: 1,
            size: pagination.pageSize,
            orderby: "-id",
        };
        if (this.state.orderid) {
            params.orderid = this.state.orderid
        }
        if (this.state.username) {
            params.username = this.state.username
        }
        if (this.state.start_time || this.state.end_time) {
            params.start_time = this.state.start_time
            params.end_time = this.state.end_time
        }
        if (this.state.receipt || this.state.receipt === 0) {
            params.receipt = this.state.receipt
        }
        onLoad(pager, agent.Purchase.get(params));
    }
    render() {
        const {
            results,
            pagination,
            loading,
            history,
            fetchError,
        } = this.props;
        if (fetchError) {
            return <ReLogin history={history} />;
        }
        pagination.total = results.total;
        let orderid = this.state.orderid
        let username = this.state.username

        const data = results.purchases
        const inputStyle = {
            marginRight: "20px",
            marginLeft: "5px",
            width: "150px"
        }
        const inputStyles = {
            marginRight: "20px",
            marginLeft: "5px",
            width: "320px"
        }
        const devStyle = {
            marginBottom: "20px",
        }

        const spanStyle = {
            fontSize: "16px",
            color: "#666"
        }
        for (const record of data) {
            record.bindreceiptkp = val => {
                let send = {
                    orderid: val,
                    receipt: 2
                }
                this.props.openmember(agent.Purchase.postStatus(send))
                this.handleAddPriceGroup()
            };
        }
        return (
            <div>
                <Content>
                    <div style={devStyle}>
                        <span style={spanStyle}>订单ID :</span>
                        <Input size="large" placeholder="订单ID" style={inputStyles} value={orderid} onChange={this.handleorderidChange} />
                        <span style={spanStyle}>用户名 :</span>
                        <Input size="large" placeholder="手机号/邮箱" style={inputStyle} value={username} onChange={this.handleusernameChangea} />
                        <span style={spanStyle}>订单日期:</span>
                        <RangePicker onChange={this.onChange.bind(this)} allowClear locale={locale} style={{ marginRight: "20px", marginLeft: "5px" }} />
                        <span style={spanStyle}>开票状态:</span>
                        <Select
                            style={inputStyle}
                            onChange={this.handlereceiptChange}
                            placeholder="请选择"
                            allowClear
                            disabled={this.state.isdisabled}
                        >
                            <Option value="0">未申请</Option>
                            <Option value="1">待开票</Option>
                            <Option value="2">已开票</Option>
                        </Select>
                        <Button
                            onClick={this.handleAddPriceGroup}
                            type="primary"
                            style={{ marginBottom: 16 }}
                        >
                            搜索
                        </Button>
                        <Button
                            onClick={this.downloadExl.bind(this)}
                            type="primary"
                            style={{ marginBottom: 16, marginLeft: 20, }}
                        >
                            导出Excel
                        </Button>

                    </div>
                    <Table
                        className="c382-purchase-list"
                        columns={columns}
                        rowKey={record => record.orderid}
                        dataSource={data}
                        pagination={pagination}
                        loading={loading}
                        onChange={this.handleTableChange}
                    />
                </Content>

            </div>
        );

    }
}
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Purchase);
