import {
    AUDIO_STATUS_UPDATE,
    AUDIO_DETAIL_LOAD,
    AUDIO_DETAIL_SHOW,
    AUDIO_DETAIL_HIDE,
    ASYNC_START,
    PLAYLIST_AUDIO_REPLACE,
    SUSUME_AUDIO_ADD,
    SUSUME_AUDIO_DELETE,
} from "../constants/actionTypes";

const defaultState = {
    modalData: {},
    loading: false,
    fetchError: false,
    visible: false,
};
export default (state = { ...defaultState }, action) => {
    switch (action.type) {
        case AUDIO_DETAIL_LOAD: {
            if (action.error) {
                return {
                    ...state,
                    fetchError: true,
                };
            }
            return {
                ...state,
                fetchError: !!action.error,
                modalData: action.payload,
                loading: false,
            };
        }
        case PLAYLIST_AUDIO_REPLACE:
        case SUSUME_AUDIO_ADD:
        case SUSUME_AUDIO_DELETE: {
            if (action.error) {
                return {
                    ...state,
                    fetchError: true,
                };
            }
            const { modalData } = state;
            const data = modalData || {};

            const audio = data.audio || {};
            if (action.type === PLAYLIST_AUDIO_REPLACE) {
                audio._playlist = action.payload.playlist;
            } else if (action.type === SUSUME_AUDIO_ADD) {
                audio._susume = action.payload.susume;
            } else {
                audio._susume = null;
            }
            return {
                ...state,
                fetchError: false,
                modalData: data,
                loading: false,
            };
        }
        case AUDIO_DETAIL_SHOW: {
            return { ...state, visible: true };
        }
        case AUDIO_DETAIL_HIDE: {
            return { ...state, visible: false, modalData: {} };
        }
        case AUDIO_STATUS_UPDATE: {
            const audio = state.modalData.audio || {};
            if (action.send.audioid !== audio.audioid) {
                return state;
            }
            if (action.error) {
                return {
                    ...state,
                    fetchError: true,
                };
            }
            audio.status = action.send.status;
            return { ...state, loading: false };
        }

        case ASYNC_START: {
            if (
                [
                    AUDIO_DETAIL_LOAD,
                    PLAYLIST_AUDIO_REPLACE,
                    SUSUME_AUDIO_ADD,
                    SUSUME_AUDIO_DELETE,
                ].includes(action.subtype)
            ) {
                return { ...state, loading: true };
            }
            if (action.subtype === AUDIO_STATUS_UPDATE) {
                const audio = state.modalData.audio || {};
                if (action.send.audioid !== audio.audioid) {
                    return state;
                }
                return { ...state, loading: true };
            }

            return state;
        }
        default:
            return state;
    }
};
