import React from "react";
import { Table, Button } from "antd";
import agent from "agent";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
    AUDIO_PAGE_UNLOADED,
    AUDIO_PAGE_LOADED,
    AUDIO_STATUS_UPDATE,
    BATCH_AUDIT,
    PLAY_AUDIO,
    AUDIO_MODAL_HIDE,
    ADMIN_UPDATE_AUDIO,
    AUDIO_MODAL_SHOW,
} from "constants/actionTypes";
import ReLogin from "components/ReLogin";
import "./Audio.less";
//import {batchAudit} from "dispatch";
import columnsCreate from "./columns";
import AudioUpdateModal from "./AudioUpdateModal";
const columns = columnsCreate();
import { loadConditionAllApp } from "dispatch";

for (const record of columns) {
    if (record.dataIndex === "id") {
        delete record.sorter;
    } else if (record.dataIndex === "status") {
        delete record.filters;
    }
}

const mapStateToProps = state => ({
    ...state.audio,
    ...state.player,
});

const mapDispatchToProps = dispatch => ({
    onLoad: (pager, payload) =>
        dispatch({ type: AUDIO_PAGE_LOADED, pager, payload }),
    onUnload: () => dispatch({ type: AUDIO_PAGE_UNLOADED }),
    onUpdateStatus: send =>
        dispatch({
            type: AUDIO_STATUS_UPDATE,
            send,
            payload: agent.Audio.putStatus(send),
        }),
    batchAudit : send =>
        dispatch({
            type: BATCH_AUDIT,
            payload: agent.Audio.batchAudit(send),
        }),
    playAudio: (audio) => {
        return dispatch({
            type: PLAY_AUDIO,audio
        });
    },  
    onHideModal: () =>
        dispatch({
            type: AUDIO_MODAL_HIDE,
        }),
    updateAudio: (send,callback) => {
        return dispatch({
            type: ADMIN_UPDATE_AUDIO,
            payload: agent.Audio.updateAudio(send),
            callback,
        });
    }, 
    onShowModal: payload =>
        dispatch({
            type: AUDIO_MODAL_SHOW,
            payload,
        }), 
});

class Audio extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            keyword: "",
        };
    }
    static propTypes = {
        pagination: PropTypes.object.isRequired,
        results: PropTypes.object.isRequired,
        loading: PropTypes.bool.isRequired,
        onLoad: PropTypes.func.isRequired,
        onUnload: PropTypes.func.isRequired,
        fetchError: PropTypes.bool.isRequired,
        onUpdateStatus: PropTypes.func.isRequired,
        batchAudit:PropTypes.func.isRequired,
        updateStatus: PropTypes.shape({
            audioid: PropTypes.string.isRequired,
            status: PropTypes.string.isRequired,
            loading: PropTypes.bool.isRequired,
        }).isRequired,
        audio:PropTypes.object.isRequired,
        playAudio:PropTypes.func.isRequired,
        modal:PropTypes.object.isRequired,
        onHideModal:PropTypes.func.isRequired,
        updateAudio:PropTypes.func.isRequired,
        onShowModal: PropTypes.func.isRequired,
    };
    static defaultProps = {
        audio: {
            id: 0,
            audioid: "",
            audio: "",
            playing: true,
            merged_peaks: "",
        },
    };
    componentDidMount() {
        this.handleRefreashPage();
        loadConditionAllApp()
    }

    componentWillUnmount() {
        this.props.onUnload();
       
    }

    handleRefreashPage = e => {
        const { onLoad, pagination } = this.props;
        const pager = { ...pagination };
        pager.current = 1;
        const params = {
            page: pager.current,
            size: pagination.pageSize,
            orderby: "-id",
            status: "unreviewed",
        };
        onLoad(pager, agent.Audio.search(params));
    };
    handleBatchAudit = e =>{
        const key=this.state.keyword;
        if (key) {
            this.props.batchAudit({email:key});
        }
    }
    handleKeywordChange = e => {
        this.setState({ keyword: e.target.value });
    };
    handleTableChange = (pagination, filters, sorter) => {
        const pager = { ...this.props.pagination };
        pager.current = pagination.current;
        const params = {
            page: pager.current,
            size: pagination.pageSize,
            orderby: "-id",
            status: "unreviewed",
        };
        
        this.props.onLoad(pager, agent.Audio.search(params));
       
    };
    tryAuditionMusic = send => {
        const { playAudio,audio} = this.props;
        if (audio.audioid==send.audioid) {
            if (send.playing) {
                send.playing=false; 
            }else{
                send.playing=true; 
            }
        }else{
            send.playing=true; 
        }
        playAudio(send);
    }
    handleCustomerModalOk = send => {
        const { updateAudio, onHideModal} = this.props;
        const callback = () => {
            const { onLoad, pagination } = this.props;
            const pager = { ...pagination };
            const params = {
                page: pager.current,
                size: pagination.pageSize,
                orderby: "-id",
                status: "unreviewed",
            };
            onLoad(pager, agent.Audio.search(params));
            onHideModal();
        };
        updateAudio(send,callback.bind(this));
    };
    render() {
        const {
            results,
            pagination,
            loading,
            history,
            fetchError,
            updateStatus,
            onUpdateStatus,
            onHideModal,
            onShowModal,
            audio,
            modal,
        } = this.props;
        const { keyword} = this.state;
        if (fetchError) {
            return <ReLogin history={history} />;
        }
        pagination.total = results.total;
        pagination.showQuickJumper = true;
        const data = results.audios || [];

        for (const record of data) {
            // record.loading = true;
            if (updateStatus.audioid === record.audioid) {
                record.loading = updateStatus.loading;
                record.status = updateStatus.status;
            }
            if (audio.audioid === record.audioid) {
                record.playing = audio.playing;
            }else{
                record.playing = false;
            }
            record.handleEditClick = e => {
                e.preventDefault();
                e.stopPropagation();
                const send = {
                    ...record,
                };
                onShowModal(send);
            };
            record.handleStatusChange = value => {
                const send = {
                    audioid: record.audioid,
                    status: value,
                };
                onUpdateStatus(send);
            };
            record.handleTryClick = e => {
                e.preventDefault();
                e.stopPropagation();
                const send = {
                    ...record,
                };
                window.open(agent.getApiUrl(send.audio))
                // this.tryAuditionMusic(send);
            };
        }
        const { data: modalData, visible } = modal;
        const modalProps = {
            onModalOk: this.handleCustomerModalOk.bind(this),
            onModalCancel: onHideModal,
            modalData,
            confirmLoading:false,
            visible,
        };
        return (
            <div>
                <Button
                    onClick={this.handleRefreashPage}
                    type="primary"
                    style={{ marginBottom: 16 }}
                >
                    获取新的数据
                </Button>
                <input
                    type="text"
                    name=""
                    value={keyword}
                    className="searchSkin"
                    onChange={this.handleKeywordChange}
                    placeholder="请输入批量处理账号!"
                />
                <Button
                    onClick={this.handleBatchAudit}
                    type="primary"
                    style={{ marginBottom: 16 }}
                >
                    批量审核
                </Button>
                <Table
                    className="c382-audio-list"
                    columns={columns}
                    rowKey={record => record.audioid}
                    dataSource={data}
                    pagination={pagination}
                    loading={loading}
                    onChange={this.handleTableChange}
                />
                <AudioUpdateModal {...modalProps} />
            </div>
        );
    }
}
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Audio);
