import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Form, Input, Upload, Avatar, Modal, Select } from "antd";

import agent from "agent";

import ListErrors from "components/ListErrors";
import helper from "utils/helper";

const mapStateToProps = state => ({
    errors: state.home.errors,
});

const mapDispatchToProps = dispatch => ({});

class PartnerModal extends React.Component {
    static propTypes = {
        visible: PropTypes.bool,
        modalData: PropTypes.object,
        onModalOk: PropTypes.func,
        onModalCancel: PropTypes.func,
    };
    state = {
        fileList: [],
    };

    static getDerivedStateFromProps(nextProps, prevState) {
        if (!nextProps.visible) {
            return { fileList: [] };
        }
        return null;
    }

    handleSubmit = e => {
        e.preventDefault();
    };
    handlePartnerModalOk = e => {
        const { modalData: data, form, onModalOk, onModalCancel } = this.props;
        const modalData = data || {};
        form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                const send = {};
                const sendKeys = ["weight", "title", "status"];
                for (const key of sendKeys) {
                    send[key] = values[key];
                }
                // update thumb
                const { fileList } = this.state;
                if (fileList.length) {
                    send.thumb = fileList[0];
                }
                delete modalData.thumb;

                if (Object.keys(send).length) {
                    onModalOk({ id: modalData.id, ...send });
                } else {
                    onModalCancel();
                }
            }
        });
    };
    handleDeleteAvatarClick = e => {
        e.preventDefault();
        e.stopPropagation();
        this.props.form.setFields({
            thumb: {
                value: "",
            },
        });
    };

    render() {
        const {
            visible,
            confirmLoading,
            modalData,

            onModalCancel,
        } = this.props;
        if (!visible) {
            return null;
        }
        const data = modalData || {};
        const { getFieldDecorator, getFieldValue } = this.props.form;
        const formItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 8 },
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 16 },
            },
        };
        const tltle = data.id ? `编辑: ${data.id}` : `添加`;
        const { fileList } = this.state;
        const uploadProps = {
            onRemove: file => {
                this.setState(state => {
                    return {
                        fileList: [],
                    };
                });
                this.props.form.setFields({
                    thumb: {
                        value: data.thumb,
                    },
                });
            },
            beforeUpload: file => {
                this.setState(state => ({
                    fileList: [file],
                }));
                helper.getBase64(file).then(data => {
                    this.props.form.setFields({
                        thumb: {
                            value: data,
                        },
                    });
                });

                return false;
            },
            accept: "image/*",
            listType: "picture",
            fileList,
        };
        return (
            <Modal
                title={tltle}
                centered
                destroyOnClose
                confirmLoading={confirmLoading}
                visible={visible}
                onOk={this.handlePartnerModalOk}
                onCancel={onModalCancel}
            >
                <ListErrors errors={this.props.errors} />

                <Form onSubmit={this.handleSubmit}>
                    <div style={{ textAlign: "center", marginBottom: " 30px" }}>
                        {getFieldDecorator("thumb", {
                            rules: [
                                {
                                    required: false,
                                },
                            ],
                            initialValue: data.thumb,
                        })(<Input type="hidden" />)}
                        <Upload {...uploadProps}>
                            <Avatar
                                key={getFieldValue("thumb")}
                                size={128}
                                shape="square"
                                icon="picture"
                                src={agent.getApiUrl(getFieldValue("thumb"))}
                            />
                        </Upload>
                        {fileList.length == 0 && (
                            <Link
                                style={{ display: "block" }}
                                to="#"
                                onClick={this.handleDeleteAvatarClick}
                            >
                                Delete
                            </Link>
                        )}
                    </div>
                    <Form.Item {...formItemLayout} label="Title">
                        {getFieldDecorator("title", {
                            rules: [
                                {
                                    required: true,
                                    message: "请输入标题!",
                                },
                            ],
                            initialValue: data.title,
                        })(<Input />)}
                    </Form.Item>
                    <Form.Item {...formItemLayout} label="Weight">
                        {getFieldDecorator("weight", {
                            rules: [
                                {
                                    pattern: new RegExp(/^\d+$/),
                                    required: true,
                                    message: "权重值只能是数字!",
                                },
                            ],
                            initialValue: data.weight,
                        })(<Input />)}
                    </Form.Item>
                    <Form.Item {...formItemLayout} label="Select">
                        {getFieldDecorator("status", {
                            rules: [
                                {
                                    required: true,
                                    message: "清选择一个状态!",
                                },
                            ],
                            initialValue: data.status,
                        })(
                            <Select placeholder="Please select a val">
                                <Select.Option value="normal">
                                    Normal
                                </Select.Option>
                                <Select.Option value="blocked">
                                    Blocked
                                </Select.Option>
                            </Select>
                        )}
                    </Form.Item>
                </Form>
            </Modal>
        );
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Form.create({ name: "PartnerModal" })(PartnerModal));
