import React from "react";
import agent from "agent";
import { Divider, Avatar, Tooltip, Icon, Select, List, Button } from "antd";
import ImageZoom from "react-medium-image-zoom";
import helper from "utils/helper";

const Option = Select.Option;

const columns = [
    {
        title: "Id",
        dataIndex: "id",
        sorter: true,
        defaultSortOrder: "descend",
    },
    {
        title: "订单",
        key: "order",
        render: record => {
            const order = record.order;
            let attachment = null;
            try {
                attachment = JSON.parse(record.attachment);
            } catch (e) {
                attachment = {};
            }
            return (
                <div>
                    <span>ID: </span>
                    {order.orderid}
                    <Divider />
                    <div>交易凭证: </div>
                    {attachment.offline_purchase && (
                        <div className="attachment-div">
                            <ImageZoom
                                image={{
                                    src: agent.getApiUrl(
                                        attachment.offline_purchase
                                    ),
                                    className: "attachment",
                                }}
                            />
                        </div>
                    )}
                    <Divider />
                    <span>提交时间: </span>
                    {helper.showDate(record.create_at)}
                    <Divider />
                    <span>订单时间: </span>
                    {helper.showDate(order.create_at)}
                    <Divider />
                    <List
                        itemLayout="horizontal"
                        dataSource={order.audios}
                        renderItem={item => (
                            <List.Item>
                                <List.Item.Meta
                                    avatar={
                                        <Avatar
                                            src={agent.getApiUrl(item.thumb)}
                                        />
                                    }
                                    title={item.title}
                                    description={item.description}
                                />
                                <div>
                                    单价: {helper.showAudioMoney(item.price)}
                                </div>
                            </List.Item>
                        )}
                    />
                    <span>总价: </span>
                    {helper.showAudioMoney(order.price)}
                    <Divider />
                </div>
            );
        },
    },
    {
        title: "Author",
        key: "author",
        render: record => {
            const author = record.order.author || {};
            return (
                <span>
                    <span>ID: </span>
                    {author.userid}
                    <Divider />
                    <span>Avatar: </span>
                    <Avatar
                        key={author.avatar}
                        src={agent.getApiUrl(author.avatar)}
                        size="large"
                        icon="user"
                    />
                    <Divider />
                    <span>线下交易姓名: </span>
                    {record.family_name} {record.given_name}
                    <Divider />
                    <span>Nickname: </span>
                    {author.nickname}
                    <Divider />
                    <span>Email: </span>
                    {author.email}
                    <Divider />
                    <span>Phone: </span>
                    {author.phone}
                    <Divider />
                    <span>公司名: </span>
                    {record.company_name}
                    <Divider />
                    <span>公司营业号: </span>
                    {record.company_number}
                    <Divider />
                    <span>描述: </span>
                    {record.p_description}
                </span>
            );
        },
    },
    {
        title: (
            <span>
                Status&nbsp;
                <Tooltip title="`processed` 状态下不可修改。`unreviewed`状态下，可以“完成订单”">
                    <Icon type="question-circle-o" />
                </Tooltip>
            </span>
        ),
        dataIndex: "status",
        filters: [
            { text: "Unreviewed", value: "unreviewed" },
            { text: "Processed", value: "processed" },
            // { text: "Deleted", value: "deleted" },
            { text: "Reject", value: "reject" },
        ],
        render: (status, record) => {
            if (status === "processed") {
                return <div>{status}</div>;
            }
            const disabled = record.loading;
            return (
                <div style={{ textAlign: "center" }}>
                    {status === "unreviewed" && (
                        <Button
                            style={{ marginBottom: 20 }}
                            onClick={record.handlePurchaseClick}
                            loading={record.loading}
                            disabled={disabled}
                            type="danger"
                        >
                            完成订单
                        </Button>
                    )}
                    <Select
                        defaultValue={status}
                        style={{ width: 110 }}
                        onChange={record.handleStatusChange}
                        loading={record.loading}
                        disabled={disabled}
                    >
                        <Option value="reject">Reject</Option>
                        <Option value="unreviewed">Unreview</Option>
                    </Select>
                </div>
            );
        },
    },
];

export default () => {
    const data = [];
    for (const record of columns) {
        data.push({ ...record });
    }
    return data;
};
