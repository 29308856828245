import React from "react";
import agent from "agent";
import { Divider, Avatar, Button } from "antd";
import helper from "utils/helper";
import { showAudioDetailModal } from "dispatch";

const columns = {
    Id: {
        title: "Id",
        dataIndex: "id",
        sorter: true,
        defaultSortOrder: "descend",
    },
    Audio: {
        title: "Audio",
        key: "Audio",
        render: record => {
            return (
                <div>
                    <Avatar
                        key={record.thumb}
                        src={agent.getApiUrl(record.thumb+'?'+new Date())}
                        size={64}
                        shape="square"
                        icon="picture"
                    />
                    <Divider />
                    <span>Title: </span>
                    {record.title}
                    <Divider />
                    <span>Audio: </span>
                    {/* <a href={agent.getApiUrl(record.audio)} download>
                        下载
                    </a> */}
                    <Button
                        style={{ marginLeft: 20 }}
                        type="dashed"
                        onClick={() => showAudioDetailModal(record.audioid)}
                    >
                        详细信息
                    </Button>
                    <Divider />
                    <span>Duration: </span>
                    {helper.showAudioDurationTime(record.duration)}
                    <Divider />
                    <span>Description: </span>
                    {record.description}
                    <Divider />
                    <span>Audit Time: </span>
                    {record.examine_time}
                </div>
            );
        },
    },
    Author: {
        title: "Author",
        key: "author",
        render: record => {
            const author = record.author || {};
            return (
                <span>
                    <span>ID: </span>
                    {author.userid}
                    <Divider />
                    <span>Avatar: </span>
                    <Avatar
                        key={author.avatar}
                        src={agent.getApiUrl(author.avatar)}
                        size="large"
                        icon="user"
                    />
                    <Divider />
                    <span>Nickname: </span>
                    {author.nickname}
                   
                </span>
            );
        },
    },
};

export default columns;
