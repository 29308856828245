import React from "react";
import agent from "agent";
import { Divider, Avatar, Tooltip, Icon, Select } from "antd";
import ImageZoom from "react-medium-image-zoom";
import helper from "utils/helper";
const Option = Select.Option;

const columns = [
    {
        title: "Id",
        dataIndex: "user_id",
        sorter: true,
        defaultSortOrder: "descend",
    },
    {
        title: "附件",
        dataIndex: "attachment",
        key: "attachment",
        render: (attachment, record) => {
            try {
                attachment = JSON.parse(attachment);
            } catch (e) {
                attachment = {};
            }
            return (
                <div>
                    {attachment.shenfenzheng && !attachment.zhengjian &&(
                        <div>
                            <div>营业执照: </div>
                            <ImageZoom
                                image={{
                                    src: agent.getApiUrl(
                                        attachment.shenfenzheng
                                    ),
                                    className: "attachment",
                                }}
                            />
                            <Divider />
                        </div>
                    )}
                    {attachment.shenfenzheng && attachment.zhengjian &&(
                        <div>
                            <div>证件正面: </div>
                            <ImageZoom
                                image={{
                                    src: agent.getApiUrl(
                                        attachment.shenfenzheng
                                    ),
                                    className: "attachment",
                                }}
                            />
                            <Divider />
                        </div>
                    )}
                    {attachment.zhengjian && (
                        <div>
                            <div>证件反面: </div>
                            <ImageZoom
                                image={{
                                    src: agent.getApiUrl(attachment.zhengjian),
                                    className: "attachment",
                                }}
                            />
                            <Divider />
                        </div>
                    )}
                    {attachment.shouchi && (
                        <div>
                            <div>手持证件: </div>
                            <ImageZoom
                                image={{
                                    src: agent.getApiUrl(attachment.shouchi),
                                    className: "attachment",
                                }}
                            />
                            <Divider />
                        </div>
                    )}
                </div>
            );
        },
    },
    {
        title: "Author",
        key: "author",
        render: (author, record) => {
            // const timer = record.create_at;
            // const create_at = Date.parse(timer)+28800000;
            // const va = moment(create_at).format('YYYY-MM-DD HH:mm:ss');
            record.author = record.author || {};
            return (
                <span>
                    <span>ID: </span>
                    {record.author.userid}
                    <Divider />
                    <span>Avatar: </span>
                    <Avatar
                        key={record.author.avatar}
                        src={agent.getApiUrl(record.author.avatar)}
                        size="large"
                        icon="user"
                    />
                    <Divider />
                    <span>Name: </span>
                    {record.family_name} {record.given_name}
                    <Divider />
                    <span>Email: </span>
                    {record.author.email}
                    <Divider />
                    <span>Phone: </span>
                    {record.author.phone}
                    <Divider />
                    <span>公司名: </span>
                    {record.company_name}
                    <Divider />
                    <span>公司营业号: </span>
                    {record.company_number}
                    <Divider />
                    <span>描述: </span>
                    {record.p_description}
                    <Divider />
                    <span>注册时间: </span>
                    {helper.showDate(record.create_at)}
                    <Divider />
                    <span>审核时间: </span>
                    {helper.showDate(record.update_at)}
                </span>
            );
        },
    },
    {
        title: (
            <span>
                Status&nbsp;
                <Tooltip title="`normal` 状态下，用户为卖家">
                    <Icon type="question-circle-o" />
                </Tooltip>
            </span>
        ),
        dataIndex: "status",
        filters: [
            { text: "Unreviewed", value: "unreviewed" },
            { text: "Normal", value: "normal" },
            // { text: "Deleted", value: "deleted" },
            { text: "Reject", value: "reject" },
        ],
        render: (status, record) => {
            const disabled = record.loading;
            const pricegroup= record.priceGroup &&record.priceGroup.map((data, i) => {
                return <Option key ={i} value={data.id}>{data.title}</Option>
            });
            const zero=0;
            return (
                <div>
                    <div style={{ marginBottom: 20 }}>
                        <Select
                            defaultValue={record.price_group_id}
                            style={{ width: 120 }}
                            onChange={record.handlePriceChange}
                            loading={record.loading}
                            disabled={disabled}
                        >
                            <Option value={zero}>请选择价格组</Option>
                            {pricegroup}
                        </Select>
                    </div>
                    <Select
                        defaultValue={status}
                        style={{ width: 120 }}
                        onChange={record.handleStatusChange}
                        loading={record.loading}
                        disabled={disabled}
                    >
                        <Option value="normal">Normal</Option>
                        <Option value="reject">Reject</Option>
                        <Option value="unreviewed">Unreview</Option>
                    </Select>
                </div>
            );
        },
    },
];

export default () => {
    const data = [];
    for (const record of columns) {
        data.push({ ...record });
    }
    return data;
};
