import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
//import { Link } from "react-router-dom";
import { Form, Input, Upload, Avatar, Modal, Select } from "antd";

import agent from "agent";
import ClassCropperModal from '../../components/ClassCropperModal/ClassCropperModal';
import ListErrors from "components/ListErrors";
import helper from "utils/helper";
import "./Audio.less";
const mapStateToProps = state => ({
    errors: state.home.errors,
    condition: state.app.condition,
});

const mapDispatchToProps = dispatch => ({});

class AudioModal extends React.Component {
    static propTypes = {
        visible: PropTypes.bool,
        modalData: PropTypes.object,
        onModalOk: PropTypes.func,
        onModalCancel: PropTypes.func,
        condition: PropTypes.object,
    };
    state = {
        fileList: [],
        classModalVisible: false,
        classModalFile: null,
        classResultImgUrl: null,
        uploadModalFile: null,
    };

    static getDerivedStateFromProps(nextProps, prevState) {
        if (!nextProps.visible) {
            return { fileList: [] };
        }
        return null;
    }

    handleSubmit = e => {
        e.preventDefault();
    };
    handlePartnerModalOk = e => {
        const { modalData: data, form, onModalOk, onModalCancel } = this.props;

        //const { modalData: data, form } = this.props;
        const modalData = data || {};
        form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                let conditions = {};
                const sendKeys = ["tag", "tag_two", "speed", "area", "vocal"];
                for (const key of sendKeys) {
                    conditions[key] = values[key].toString();
                }
                let style = "0";
                if (values.style1) {
                    style = values.style1.toString();
                    if (values.style2) {
                        style = style + "," + values.style2;
                    }
                } else if (values.style2) {
                    style = values.style2.toString();
                }
                conditions.style = style;
                let instrument = "0";
                if (values.instrument1) {
                    instrument = values.instrument1.toString();
                    if (values.instrument2) {
                        instrument = instrument + "," + values.instrument2;
                    }
                } else if (values.instrument2) {
                    instrument = values.instrument2.toString();
                }
                conditions.instrument = instrument;
                let emotion = "0";
                if (values.emotion1) {
                    emotion = values.emotion1.toString();
                    if (values.emotion2) {
                        emotion = emotion + "," + values.emotion2;
                        if (values.emotion3) {
                            emotion = emotion + "," + values.emotion3;
                        }
                    } else if (values.emotion3) {
                        emotion = emotion + "," + values.emotion3;
                    }
                } else if (values.emotion2) {
                    emotion = values.emotion2.toString();
                    if (values.emotion3) {
                        emotion = emotion + "," + values.emotion3;
                    }
                } else if (values.emotion3) {
                    emotion = values.emotion3.toString();
                }
                conditions.emotion = emotion;

                let scene = "0";
                if (values.scene1) {
                    scene = values.scene1.toString();
                    if (values.scene2) {
                        scene = scene + "," + values.scene2;
                        if (values.scene3) {
                            scene = scene + "," + values.scene3;
                        }
                    } else if (values.scene3) {
                        scene = scene + "," + values.scene3;
                    }
                } else if (values.scene2) {
                    scene = values.scene2.toString();
                    if (values.scene3) {
                        scene = scene + "," + values.scene3;
                    }
                } else if (values.scene3) {
                    scene = values.scene3.toString();
                }
                let scope = 0
                if(values.scope ==  '全球'){
                    scope = 0
                }else if(values.scope == '中国大陆'){
                    scope = 1
                }else{
                    scope = values.scope || 0
                }
       
                conditions.scene = scene;
                // update thumb
                let send = {};
                const { uploadModalFile } = this.state;
                if (uploadModalFile) {
                    send.thumb = uploadModalFile;
                }
                delete modalData.thumb;
                send.title = values.title;
                send.condition = JSON.stringify(conditions);
                send.description = values.description;
                send.scope =  scope.toString();
         
                if (Object.keys(send).length) {
                    onModalOk({ id: modalData.id, ...send });
                    this.setState({
                        classResultImgUrl: null,
                    })
                } else {
                    onModalCancel();
                    this.setState({
                        classResultImgUrl: null,
                    })

                }
            }
        });
    };
    handleDeleteAvatarClick = e => {
        e.preventDefault();
        e.stopPropagation();
        this.props.form.setFields({
            thumb: {
                value: "",
            },
        });
    };
    createOptions(list) {

        return [
            <Select.Option value={0} key={-1}>
                {'请选择'}
            </Select.Option>,
            list.map((x, i) => {
                const { id, title } = x;
                return (
                    <Select.Option value={id} key={i}>
                        {title}
                    </Select.Option>
                );
            }),
        ];
    }
    createOptionss(list) {
        return [
            <Select.Option value={0} key={-1}>
                {'请选择'}
            </Select.Option>,
            list.map((x, i) => {
                const { id, title, father_id } = x;
                let til = "";
                if (father_id != 0) {
                    for (let j = 0; j < list.length; j++) {
                        const element = list[j];
                        if (element.id == father_id) {
                            til = element.title
                        }

                    }
                    return (
                        <Select.Option value={id} key={i}>
                            {til}-{title}
                        </Select.Option>
                    );
                }
            }),
        ];
    }
    handleClassFileChange = e => {
        const file = e.file;
        if (file) {
            // if (file.size <= MAX_FILE_SIZE) {
            this.setState({
                classModalFile: file // 先把上传的文件暂存在state中
            },
            () => {
                this.setState({
                    classModalVisible: true // 然后弹出modal
                })
            }
            )
        }
    }
    handleGetResulFile = key => file => {
        this.props.form.setFields({
            thumb: {
                value: file,
            },
        });
        this.setState({
            [key]: file
        })
    }
    handleGetResultImgUrl = key => file => {
        this.setState({
            [key]: file
        })
    }
    render() {
        const {
            visible,
            confirmLoading,
            modalData,
            onModalCancel,
        } = this.props;
        if (!visible) {
            return null;
        }
        const {
            emotion = [],
            instrument = [],
            scene = [],
            style = [],
            speed = [],
            vocal = [],
            area = [],

        } = this.props.condition;
        console.log(this.props.condition)
        const authorization = [
            {id: 0, title: "全球", update_at: new Date(), status: "normal"},
            {id: 1, title: "中国大陆", update_at: new Date(), status: "normal"}
        ]
        const styleOptions = this.createOptions(style);
        const speedOptions = this.createOptions(speed);
        const sceneOptions = this.createOptionss(scene);
        const emotionOptions = this.createOptions(emotion);
        const instrumentOptions = this.createOptions(instrument);
        const vocalOptions = this.createOptions(vocal);
        const areaOptions = this.createOptions(area);
        const authorizationOptions = this.createOptions(authorization);
        
        const data = modalData || {};
        
        const { getFieldDecorator, getFieldValue } = this.props.form;
       
        const formItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 8 },
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 16 },
            },
        };
        const tltle = `编辑: ${data.id}`;
        const { fileList } = this.state;
        const uploadProps = {
            onRemove: file => {
                this.setState(state => {
                    return {
                        fileList: [],
                    };
                });
                this.props.form.setFields({
                    thumb: {
                        value: data.thumb,
                    },
                });
            },
            beforeUpload: file => {
                this.setState(state => ({
                    fileList: [file],
                }));
                helper.getBase64(file).then(data => {
                    this.props.form.setFields({
                        thumb: {
                            value: data,
                        },
                    });
                });

                return false;
            },
            accept: "image/*",
            listType: "picture",
            fileList,
        };

        if (data.inverted_condition.scene == 0) {
            data.inverted_condition.scene = "0"
        }
        const no = false;
        const {
            classModalVisible,
            classModalFile,
            classResultImgUrl,
        } = this.state;
        return (
            <Modal
                title={tltle}
                centered
                destroyOnClose
                confirmLoading={confirmLoading}
                visible={visible}
                onOk={this.handlePartnerModalOk}
                onCancel={onModalCancel}
                maskClosable={no}
            >
                <ListErrors errors={this.props.errors} />

                <Form onSubmit={this.handleSubmit}>
                    <div style={{ textAlign: "center", marginBottom: " 30px" }}>
                        {getFieldDecorator("thumb", {
                            rules: [
                                {
                                    required: false,
                                },
                            ],
                            initialValue: data.thumb,
                        })(<Input type="hidden" />)}
                        <Upload {...uploadProps} onChange={this.handleClassFileChange}>
                            <Avatar
                                key={getFieldValue("thumb")}
                                size={128}
                                shape="square"
                                icon="picture"
                                src={classResultImgUrl ? classResultImgUrl : agent.getApiUrl(getFieldValue("thumb"))}

                            />
                            {/* <div style={{ cursor: "pointer"}}>修改</div> */}
                        </Upload>
                        {/* {fileList.length == 0 && (
                            <Link
                                style={{ display: "block" }}
                                to="#"
                                onClick={this.handleDeleteAvatarClick}
                            >
                                修改
                            </Link>
                        )} */}
                    </div>
                    <Form.Item {...formItemLayout} label="歌曲名称">
                        {getFieldDecorator("title", {
                            rules: [
                                {
                                    required: true,
                                    message: "请输入标题!",
                                },
                            ],
                            initialValue: data.title,
                        })(<Input />)}
                    </Form.Item>
                    <Form.Item {...formItemLayout} label="音乐描述">
                        {getFieldDecorator("description", {
                            rules: [
                                {
                                    required: false,
                                    message: "",
                                },
                            ],
                            initialValue: data.description,
                        })(<Input />)}
                    </Form.Item>
                    <Form.Item {...formItemLayout} label="标签1">
                        {getFieldDecorator("tag", {
                            rules: [
                                {
                                    required: false,
                                    message: "",
                                },
                            ],
                            initialValue: data.tag,
                        })(<Input />)}
                    </Form.Item>
                    <Form.Item {...formItemLayout} label="标签2">
                        {getFieldDecorator("tag_two", {
                            rules: [
                                {
                                    required: false,
                                    message: "",
                                },
                            ],
                            initialValue: data.tag_two,
                        })(<Input />)}
                    </Form.Item>
                    <Form.Item {...formItemLayout} label="速度">
                        {getFieldDecorator("speed", {
                            rules: [
                                {
                                    required: false,
                                    message: "请选择速度!",
                                },
                            ],
                            initialValue: parseInt(data.speed),
                        })(
                            <Select getPopupContainer={triggerNode => triggerNode.parentNode} placeholder="请选择速度">
                                {speedOptions}
                            </Select>
                        )}
                    </Form.Item>
                    <Form.Item {...formItemLayout} label="授权区域">
                        {getFieldDecorator("scope", {
                            rules: [
                                {
                                    required: false,
                                    message: "请选择地区!",
                                },
                            ],
                            initialValue: data.scope==0?'全球':'中国大陆',
                        })(
                            <Select getPopupContainer={triggerNode => triggerNode.parentNode} placeholder="请选择地区">
                                {authorizationOptions}
                            </Select>
                        )}
                    </Form.Item>
                    <Form.Item {...formItemLayout} label="地区">
                        {getFieldDecorator("area", {
                            rules: [
                                {
                                    required: false,
                                    message: "请选择地区!",
                                },
                            ],
                            initialValue: parseInt(data.area),
                        })(
                            <Select getPopupContainer={triggerNode => triggerNode.parentNode} placeholder="请选择地区">
                                {areaOptions}
                            </Select>
                        )}
                    </Form.Item>
                    <Form.Item {...formItemLayout} label="人声">
                        {getFieldDecorator("vocal", {
                            rules: [
                                {
                                    required: false,
                                    message: "请选择人声!",
                                },
                            ],
                            initialValue: parseInt(data.vocal),
                        })(
                            <Select getPopupContainer={triggerNode => triggerNode.parentNode} placeholder="请选择人声">
                                {vocalOptions}
                            </Select>
                        )}
                    </Form.Item>
                    <Form.Item {...formItemLayout} label="风格1">
                        {getFieldDecorator("style1", {
                            rules: [
                                {
                                    required: false,
                                    message: "请选择风格!",
                                },
                            ],
                            initialValue: parseInt(data.inverted_condition.style.split(',')[0]),
                        })(
                            <Select getPopupContainer={triggerNode => triggerNode.parentNode} placeholder="请选择风格">
                                {styleOptions}
                            </Select>
                        )}
                    </Form.Item>
                    <Form.Item {...formItemLayout} label="风格2">
                        {getFieldDecorator("style2", {
                            rules: [
                                {
                                    required: false,
                                    message: "请选择风格!",
                                },
                            ],
                            initialValue: parseInt(data.inverted_condition.style.split(',').length > 1 ? data.inverted_condition.style.split(',')[1] : 0),
                        })(
                            <Select getPopupContainer={triggerNode => triggerNode.parentNode} placeholder="请选择风格">
                                {styleOptions}
                            </Select>
                        )}
                    </Form.Item>
                    <Form.Item {...formItemLayout} label="乐器1">
                        {getFieldDecorator("instrument1", {
                            rules: [
                                {
                                    required: false,
                                    message: "请选择乐器!",
                                },
                            ],
                            initialValue: parseInt(data.inverted_condition.instrument.split(',').length > 0 ? data.inverted_condition.instrument.split(',')[0] : 0),
                        })(
                            <Select getPopupContainer={triggerNode => triggerNode.parentNode} placeholder="请选择乐器">
                                {instrumentOptions}
                            </Select>
                        )}
                    </Form.Item>
                    <Form.Item {...formItemLayout} label="乐器2">
                        {getFieldDecorator("instrument2", {
                            rules: [
                                {
                                    required: false,
                                    message: "请选择乐器!",
                                },
                            ],
                            initialValue: parseInt(data.inverted_condition.instrument.split(',').length > 1 ? data.inverted_condition.instrument.split(',')[1] : 0),
                        })(
                            <Select getPopupContainer={triggerNode => triggerNode.parentNode} placeholder="请选择乐器">
                                {instrumentOptions}
                            </Select>
                        )}
                    </Form.Item>
                    <Form.Item {...formItemLayout} label="情绪1">
                        {getFieldDecorator("emotion1", {
                            rules: [
                                {
                                    required: false,
                                    message: "请选择情绪!",
                                },
                            ],
                            initialValue: parseInt(data.inverted_condition.emotion.split(',').length > 0 ? data.inverted_condition.emotion.split(',')[0] : 0),

                        })(
                            <Select getPopupContainer={triggerNode => triggerNode.parentNode} placeholder="请选择情绪" >
                                {emotionOptions}
                            </Select>
                        )}
                    </Form.Item>
                    <Form.Item {...formItemLayout} label="情绪2">
                        {getFieldDecorator("emotion2", {
                            rules: [
                                {
                                    required: false,
                                    message: "请选择情绪!",
                                },
                            ],
                            initialValue: parseInt(data.inverted_condition.emotion.split(',').length > 1 ? data.inverted_condition.emotion.split(',')[1] : 0),

                        })(
                            <Select getPopupContainer={triggerNode => triggerNode.parentNode} placeholder="请选择情绪" >
                                {emotionOptions}
                            </Select>
                        )}
                    </Form.Item>
                    <Form.Item {...formItemLayout} label="情绪3">
                        {getFieldDecorator("emotion3", {
                            rules: [
                                {
                                    required: false,
                                    message: "请选择情绪!",
                                },
                            ],
                            initialValue: parseInt(data.inverted_condition.emotion.split(',').length > 2 ? data.inverted_condition.emotion.split(',')[2] : 0),

                        })(
                            <Select getPopupContainer={triggerNode => triggerNode.parentNode} placeholder="请选择情绪" >
                                {emotionOptions}
                            </Select>
                        )}
                    </Form.Item>
                    <Form.Item {...formItemLayout} label="场景1">
                        {getFieldDecorator("scene1", {
                            rules: [
                                {
                                    required: false,
                                    message: "请选择场景!",
                                },
                            ],
                            initialValue: parseInt(data.inverted_condition.scene.split(',').length > 0 ? data.inverted_condition.scene.split(',')[0] : 0),

                        })(
                            <Select getPopupContainer={triggerNode => triggerNode.parentNode} placeholder="请选择场景">
                                {sceneOptions}
                            </Select>
                        )}
                    </Form.Item>
                    <Form.Item {...formItemLayout} label="场景2">
                        {getFieldDecorator("scene2", {
                            rules: [
                                {
                                    required: false,
                                    message: "请选择场景!",
                                },
                            ],
                            initialValue: parseInt(data.inverted_condition.scene.split(',').length > 1 ? data.inverted_condition.scene.split(',')[1] : 0),
                        })(
                            <Select getPopupContainer={triggerNode => triggerNode.parentNode} placeholder="请选择场景">
                                {sceneOptions}
                            </Select>
                        )}
                    </Form.Item>
                    <Form.Item {...formItemLayout} label="场景3">
                        {getFieldDecorator("scene3", {
                            rules: [
                                {
                                    required: false,
                                    message: "请选择场景!",
                                },
                            ],
                            initialValue: parseInt(data.inverted_condition.scene.split(',').length > 2 ? data.inverted_condition.scene.split(',')[2] : 0),
                        })(
                            <Select getPopupContainer={triggerNode => triggerNode.parentNode} placeholder="请选择场景">
                                {sceneOptions}
                            </Select>
                        )}
                    </Form.Item>
                </Form>
                {classModalVisible && (
                    <ClassCropperModal
                        uploadedImageFile={classModalFile}
                        onClose={() => {
                            this.setState({ classModalVisible: false })
                        }}
                        onSubmit={this.handleGetResultImgUrl('classResultImgUrl')}
                        onShowImg={this.handleGetResulFile('uploadModalFile')}
                    />
                )}
            </Modal>
        );
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Form.create({ name: "AudioModal" })(AudioModal));
