import React from "react";
import { connect } from "react-redux";
import { Route, Switch } from "react-router-dom";
import Memberset from "components/Memberset";
import Users from "./Users";
import Codes from "./Codes";

const mapStateToProps = state => ({
    ...state.producer,
});

const mapDispatchToProps = dispatch => ({});

class Producer extends React.Component {
    render() {
        const { match } = this.props;
        const basepath = match.path === "/" ? "" : match.path;
        return (
            <Switch>
                <Route exact path={`${basepath}/users`} component={Users} />
                <Route exact path={`${basepath}/codes`} component={Codes} />
                <Route exact component={Memberset} />
            </Switch>
        );
    }
}
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Producer);