import React from "react";
import { Table, Button, Popconfirm, Modal, DatePicker, Input, InputNumber } from "antd";
import agent from "agent";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import moment from 'moment';
import locale from 'antd/es/date-picker/locale/zh_CN';
import helper from "utils/helper";
import {
    MEMBER_SET_FONT,
    PRICE_GROUP_ADD_SHOW,
    PRICE_GROUP_ADD_HIDE,
    MEMBER_SET_DEL,
    USER_PAGE_LOADEDA,
    USER_PAGE_LOADEDB,
    AMEND_TIME_ADD,
    AMEND_TIME_HIDE,
    MEMMBER_UPDATE
} from "constants/actionTypes";
import ReLogin from "components/ReLogin";
import PriceAddModal from "./PriceAddModals";

const { RangePicker } = DatePicker;
const mapStateToProps = state => ({
    ...state.memberset,
});

const mapDispatchToProps = dispatch => ({
    onLoad: (pager, payload) =>
        dispatch({ type: MEMBER_SET_FONT, pager, payload }),
    del: newsid => {
        return dispatch({
            type: MEMBER_SET_DEL,
            payload: agent.Memberset.delete(newsid),
        });
    },
    update: send => {
        dispatch({
            type: MEMMBER_UPDATE,
            payload: agent.Memberset.update(send),
        });
    },
    openAddPriceGroup: () =>
        dispatch({
            type: PRICE_GROUP_ADD_SHOW,

        }),
    onHideAddModal: () =>
        dispatch({
            type: PRICE_GROUP_ADD_HIDE,
        }),
    usershow: () => dispatch({
        type: USER_PAGE_LOADEDA
    }),
    userhide: () => dispatch({
        type: USER_PAGE_LOADEDB
    }),
    timeshow: () => dispatch({
        type: AMEND_TIME_ADD
    }),
    timehide: () => dispatch({
        type: AMEND_TIME_HIDE
    })
});

class List extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            proshow: false,
            id: '',
            startTime: '',
            endTime: '',
            remark: '',
            price: 1,
            se: [],
        }
    }
    static propTypes = {
        pagination: PropTypes.object.isRequired,
        results: PropTypes.object.isRequired,
        loading: PropTypes.bool.isRequired,
        onLoad: PropTypes.func.isRequired,
        update: PropTypes.func.isRequired,
        onUnload: PropTypes.func.isRequired,
        fetchError: PropTypes.bool.isRequired,
        onShowModal: PropTypes.func.isRequired,
        onHideModal: PropTypes.func.isRequired,
        openAddPriceGroup: PropTypes.func.isRequired,
        usershow: PropTypes.func.isRequired,
        userhide: PropTypes.func.isRequired,
        timeshow: PropTypes.func.isRequired,
        timehide: PropTypes.func.isRequired,
        onHideAddModal: PropTypes.func.isRequired,
        addPriceGroup: PropTypes.func.isRequired,
        onUpdatePriceGroup: PropTypes.func.isRequired,
        onUpdateGroupStatus: PropTypes.func.isRequired,
        del: PropTypes.func.isRequired,
        proshow: PropTypes.bool,
    };

    componentDidMount() {
        this.onLoad();
    }
    //加载价格组信息
    onLoad = () => {
        const { onLoad, pagination } = this.props;
        const pager = { ...pagination };
        pager.current = 1;
        const params = {
        };
        onLoad(pager, agent.Memberset.get(params));
    };

    //翻页
    handleTableChange = (pagination, filters, sorter) => {
        const pager = { ...this.props.pagination };
        pager.current = pagination.current;
        let orderby = "-id";
        if (sorter && sorter.field) {
            orderby = sorter.field;
            if (sorter.order === "descend") {
                orderby = "-" + sorter.field;
            }
        }

        const params = {
            page: pager.current,
            size: pagination.pageSize,
            orderby,
            ...filters,
        };

        this.props.onLoad(pager, agent.Memberset.get(params));
    };

    //修改价格
    handleUpdateModalOk = send => {
        const { onUpdatePriceGroup, onHideModal } = this.props;
        const data = {
            info: send
        }
        const callback = () => {
            const { onLoad, pagination } = this.props;
            const pager = { ...pagination };
            const params = {
                page: pager.current,
                size: pagination.pageSize,
                orderby: "-id",
            };
            onHideModal();
            onLoad(pager, agent.Memberset.get(params));
        };
        onUpdatePriceGroup(data, callback);
    };

    //增加价格组
    handleAddModalOk = send => {
        setTimeout(() => {
            this.onLoad();
        }, 300);

    };
    handleAddModalOks = send => {
        const { update, timehide } = this.props;
        const params = {
            id: this.state.id,
            startTime: this.state.startTime,
            endTime: this.state.endTime,
            price: this.state.price,
            remark: this.state.remark,
        };
        update(params)
        timehide()
        let _this = this
        setTimeout(function () {
            _this.onLoad();
        }, 300)
    };
    onChanges(date, dateString) {
        this.setState({ startTime: new Date(dateString[0].replace(/-/g, "/")).getTime() });
        this.setState({ endTime: new Date(dateString[1].replace(/-/g, "/")).getTime() });
    }
    openmember = e => {
        const { del } = this.props;
        del(e);
        let _this = this
        setTimeout(function () {
            _this.onLoad()
        }, 300)
    };
    handleAddModalxg = (e, data) => {
        this.setState({
            id: e.id,
            remark: e.remark,
            startTime: e.startTime,
            endTime: e.endTime,
            price: e.price,
            se: [helper.showDate(new Date(e.startTime || '')), helper.showDate(new Date(e.endTime || ''))]
        })
        this.props.timeshow();
    }
    handleorderidChange = e => {
        this.setState({ remark: e.target.value });
    }
    onModalCancel = e => {
        this.props.timehide();
    }
    onChangePriceType = value => {
        // console.log(`selected ${value}`);
        this.setState({ price: value*100 });
    }
    //弹出添加页
    handleAddPriceGroup = e => {
        this.props.openAddPriceGroup();
        this.props.userhide()
    }
    render() {
        const columns = [

            {
                title: "用户ID",
                key: "title",
                render: (record) => {
                    return (
                        <span>
                            {record.userId}
                        </span>
                    );
                },
            },
            {
                title: "用户账号",
                key: "create_by",
                render: (record) => {
                    return (
                        <span>
                            {record.phone}
                        </span>
                    );
                },
            },
            {
                title: "用户邮箱",
                key: "emi",
                render: (record) => {
                    return (
                        <span>
                            {record.email}
                        </span>
                    );
                },
            },
            {
                title: "开始时间",
                key: "create_at",
                render: (record) => {
                    return (
                        <span>
                            {helper.showDate(new Date(record.startTime))}
                        </span>
                    );
                },
            },
            {
                title: "结束时间",
                key: "update_by",
                render: (record) => {
                    return (
                        <span>
                            {helper.showDate(new Date(record.endTime))}
                        </span>
                    );
                },
            },
            {
                title: "价格",
                key: "price",
                render: (record) => {
                    return (
                        <span>
                            {record.price / 100}元
                        </span>
                    );
                },
            },
            {
                title: "备注",
                key: "remark",
                render: (record) => {
                    return (
                        <span>
                            {record.remark}
                        </span>
                    );
                },
            },
            {
                title: "操作",
                key: "do",
                render: (record) => {
                    let colors = {
                        color: 'blue',
                        // textDecoration: 'underline',
                        cursor: 'pointer'
                    }
                    return (
                        <div>
                            <p style={colors} onClick={this.handleAddModalxg.bind(this, record)}> 修改</p>
                            <Popconfirm title="确认删除该用户?" onConfirm={() => this.openmember(record.id)}>
                                <p style={colors}> 删除</p>
                            </Popconfirm>
                        </div>
                    );
                },
            },
        ];
        const {
            results,
            pagination,
            loading,
            history,
            fetchError,
            usershow,
            // onUpdateGroupStatus,
            // onShowModal,
            addShow,
            onHideAddModal,
            show,
            timeShow,
        } = this.props;
        if (fetchError) {
            return <ReLogin history={history} />;
        }
        pagination.total = results.total;
        pagination.showQuickJumper = true;
        pagination.hideOnSinglePage = true;
        const data = results.result;
        const tltle = '修改优惠日期';
        // for (const record of data) {
        //     // record.loading = true;
        //     record.handleStatusChange = value => {
        //         const send = {
        //             id: record.id,
        //             flag: value,
        //         };
        //         onUpdateGroupStatus(send);
        //     };
        //     record.handleEditClick = e => {
        //         e.preventDefault();
        //         e.stopPropagation();
        //         const send = {
        //             ...record,
        //         };
        //         console.info(send);
        //         onShowModal(send);
        //     };
        // }
        const addModel = {
            addShow,
            onModalCancel: onHideAddModal,
            usershow: usershow,
            onModalOk: this.handleAddModalOk.bind(this),
            confirmLoading: false,
            show,
        }
        const remark = this.state.remark
        const price = this.state.price
        const inputStyles = {

            width: '500px'
        }
        const inputStyle = {

            margin: "20px 150px",
        }
        const spanStyle = {
            display: 'inline-block',
            width: '60px'
        }
        return (
            <div>
                <Button
                    onClick={this.handleAddPriceGroup}
                    type="primary"
                    style={{ marginBottom: 16 }}
                >
                    添加
                </Button>
                <Table
                    columns={columns}
                    rowKey={record => record.userid}
                    dataSource={data}
                    pagination={false}
                    loading={loading}
                    onChange={this.handleTableChange}
                />
                <PriceAddModal {...addModel} />
                <Modal
                    title={tltle}
                    centered
                    destroyOnClose
                    visible={timeShow}
                    onOk={this.handleAddModalOks}
                    onCancel={this.onModalCancel}
                    maskClosable={false}
                >
                    <div style={inputStyle}>
                        <span style={spanStyle}>日期:</span>
                        <RangePicker onChange={this.onChanges.bind(this)} locale={locale} allowClear showTime={{
                            hideDisabledOptions: true,
                            defaultValue: [moment('00:00:00', 'HH:mm:ss'), moment('00:00:00', 'HH:mm:ss')],
                        }}
                        format="YYYY-MM-DD HH:mm:ss"
                        style={inputStyles}
                        placeholder={this.state.se}
                        />
                    </div>
                    <div style={inputStyle}>
                        <span style={spanStyle}>价格(元): </span>
                        {/* <Select
                            size="large"
                            placeholder="选择价格"
                            onChange={this.onChangePriceType}
                            style={inputStyles}
                            value={price/100 + '元'}
                        >
                            <Option value={10}>0.1元</Option>
                            <Option value={100}>1元</Option>
                        </Select> */}
                        <InputNumber
                            style={inputStyles}
                            defaultValue={price/100}
                            min="0.01"
                            step="0.01"
                            size="large"
                            precision={2}
                            onChange={this.onChangePriceType}
                        />
                    </div>
                    <div style={inputStyle}>
                        <span style={spanStyle}>备注:</span>
                        <Input size="large" placeholder="备注" style={inputStyles} value={remark} onChange={this.handleorderidChange} />
                    </div>

                </Modal>
            </div>
        );
    }
}
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(List);
