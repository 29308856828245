import React from "react";
import { Table ,Button ,Divider ,Modal } from "antd";
import agent from "agent";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import {
    USER_PAGE_UNLOADED,
    COOPERATION_LIST_LOADED,
    COOPERATION_ADD_SHOW,
    COOPERATION_ADD_HIDE,
    COOPERATION_ADD_LIST,
    COOPERATION_LIST_DELETE,
    COOPERATION_LIST_ENABLE,
    COOPERATION_LIST_DOWNLOAD,
} from "constants/actionTypes";
import ReLogin from "components/ReLogin";
import OfflineAddModel from "./offlineAddModel";

const columns = [
    {
        title: "用户标识",
        key: "title_id",
        render: (record) => {
            return (
                <span>
                    {record.username}
                </span>
            );
        },

    },
    {
        title: "合作商名称",
        key: "title",
        render: (record) => {
            return (
                <span>
                    {record.name}
                </span>
            );
        },
    },
    {
        title: "曲库规模",
        key: "create_by",
        render: (record) => {
            return (
                <span>
                    {record.count}
                </span>
            );
        },
    },
    {
        title: "状态",
        key: "create_at",
        render: (record) => {
            return (
                <span>
                    {record.status == "open"?"有效":'失效'}
                </span>
            );
        },
    },
    {
        title: "下载密钥",
        key: "update_by",
        render: (record) => {
            return (
                <span>
                    {/* <Link to="#" onClick={record.onDownloadVisaDoc}> */}
                    {/* <Link to="#" href>
                        下载
                    </Link> */}
                    <a href = {agent.getApiUrl()+'/partner/offline/private?username='+record.username}>下载</a>
                </span>
            );
        },
    },
    {
        title: "操作",
        key: "flag",
        render: (record) => {
            return (
                <span>
                    <Link to="#" onClick={record.handleDeleteRowChange}>
                        删除
                    </Link>
                    <Divider type="vertical" />
                    <Link to="#" onClick={record.onDisableOrEnable}>
                        {record.status == "open"?"禁用":'启用'}
                    </Link>
                </span>

            );
        },
    },
];

const mapStateToProps = state => ({
    ...state.cooperationer,
});

const mapDispatchToProps = dispatch => ({
    onLoad: (payload) =>
        dispatch({ type: COOPERATION_LIST_LOADED, payload }),
    onUnload: () => dispatch({ type: USER_PAGE_UNLOADED }),
    openAddPriceGroup: () =>
        dispatch({
            type: COOPERATION_ADD_SHOW,
        }),
    onHideAddModal: () =>
        dispatch({
            type: COOPERATION_ADD_HIDE,
        }),
    addPriceGroup: (send,callback) =>
        dispatch({
            type: COOPERATION_ADD_LIST,
            send,
            payload: agent.Cooperation.createOff(send),
            callback,
        }), 
    onDeleteList: (send,callback) => 
        dispatch({
            type: COOPERATION_LIST_DELETE,
            send,
            payload: agent.Cooperation.deleteOff(send),
            callback,
        }),
    onDisableOrEnableList: (send, params,callback) =>
        dispatch({
            type: COOPERATION_LIST_ENABLE,
            send,
            payload: agent.Cooperation.enableOff(send, params),
            callback,
        }),
    onDownloadVisaDocList: (params) =>
        dispatch({
            type: COOPERATION_LIST_DOWNLOAD,
            payload: agent.Cooperation.downloadOffLine(params),
        }),
});

class List extends React.Component {
    static propTypes = {
        results: PropTypes.object.isRequired,
        loading: PropTypes.bool.isRequired,
        onLoad: PropTypes.func.isRequired,
        onUnload: PropTypes.func.isRequired,
        fetchError: PropTypes.bool.isRequired,
        openAddPriceGroup:PropTypes.func.isRequired,
        onHideAddModal:PropTypes.func.isRequired,
        addPriceGroup:PropTypes.func.isRequired,
        onDeleteList:PropTypes.func.isRequired,
    };

    componentDidMount() {
        this.onLoad();
    }
    //加载价格组信息
    onLoad = () => {
        const { onLoad } = this.props;
        const params = {
        };
        onLoad(agent.Cooperation.getOffline(params));
    };


    //增加价格组  下面进行了绑定，点击新增页面的 OK ， 就会执行此方法
    handleAddModalOk= send => {
        console.log(send)
        const { onHideAddModal,addPriceGroup }=this.props;
        const callback = () => {
            const { onLoad } = this.props;
            onHideAddModal();
            // // 刷新页面列表数据
            onLoad(agent.Cooperation.getOffline());  
        };
        // 添加按钮 网络请求
        addPriceGroup(send,callback); 
    };

    //弹出添加页
    handleAddPriceGroup = e => {
        this.props.openAddPriceGroup();
    }
    render() {
        const {
            results,
            loading,
            history,
            fetchError,
            addShow,
            onHideAddModal,
            onDeleteList,
            onDisableOrEnableList,
            onDownloadVisaDocList,
        } = this.props;
        if (fetchError) {
            return <ReLogin history={history} />;
        }
        const data = results.result;
        for (const record of data) {
           
            // 删除按钮 绑定的事件
            record.handleDeleteRowChange = (e) => {
                e.preventDefault();
                const callback = () => {
                    const { onLoad } = this.props;
                    // 刷新页面列表数据
                    onLoad(agent.Cooperation.getOffline());  
                };

                Modal.confirm({
                    title: "确认删除吗？操作不可恢复！",
                    content: `将要删除： ${record.username}`,
                    okText: "确认删除",
                    okType: "danger",
                    cancelText: "No",
                    okButtonProps: {
                        disabled: false,
                    },
                    onOk: () => {
                        onDeleteList(record.username, callback);
                    },
                });
            };

            // 禁用/启用按钮 绑定的事件
            record.onDisableOrEnable = (e) => {
                e.preventDefault();
                const callback = () => {
                    const { onLoad } = this.props;
                    // 刷新页面列表数据
                    onLoad(agent.Cooperation.getOffline());  
                };
                const params = {
                    status: record.status == "open"?"close":'open',
                };
                onDisableOrEnableList(record.username, params, callback);
            };

            // 下载
            record.onDownloadVisaDoc = (e) => {
                e.preventDefault();
                const params = {
                    username: record.username,
                };
                onDownloadVisaDocList(params);
            };
        }

        // 传递给  PriceAddModal 的参数， 
        // addShow 控制是否显示
        // onModalOk 点击 PriceAddModal OK 执行 handleAddModalOk 方法
        const addModel={
            addShow,
            onModalCancel: onHideAddModal,
            onModalOk: this.handleAddModalOk.bind(this),
            confirmLoading:false,
        }
        
        return (
            <div>
                <Button
                    onClick={this.handleAddPriceGroup}
                    type="primary"
                    style={{ marginBottom: 16 }}
                >
                    添加
                </Button>
                <Table
                    columns={columns}
                    // rowKey={record => record.userid}
                    pagination={false}
                    dataSource={data}
                    loading={loading}
                />
                <OfflineAddModel {...addModel} />
            </div>
        );
    }
}
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(List);
