import React from "react";
import agent from "agent";
import { Divider, Avatar, Tooltip, Icon, List, Button } from "antd";
import helper from "utils/helper";

const columns = [
    {
        title: "Id",
        dataIndex: "id",
        sorter: true,
        defaultSortOrder: "descend",
    },
    {
        title: "订单",
        key: "order",
        render: (order, record) => {
            const attachment=eval('(' + record.attachment + ')')
            return (
                <div>
                    <span>ID: </span>
                    {record.orderid}
                    <Divider />
                    <span>创建时间: </span>
                    {helper.showDate(record.create_at)}
                    <Divider />
                    <List
                        itemLayout="horizontal"
                        dataSource={record.audios}
                        renderItem={item => (
                            <List.Item>
                                <List.Item.Meta
                                    avatar={
                                        <Avatar
                                            src={agent.getApiUrl(item.thumb)}
                                        />
                                    }
                                    title={item.title}
                                    description={item.description}
                                />
                                <div>
                                    单价: {helper.showAudioMoney(item.price)}
                                </div>
                            </List.Item>
                        )}
                    />
                    <span>总价: </span>
                    {helper.showAudioMoney(record.price)}
                    <Divider />
                    {attachment&&attachment.payType&&attachment.payType==2?(
                        <span>
                            <span>支付方式:</span>
                                银行卡转账
                            <Divider />
                            <span>汇款账号:</span>
                            {attachment.remittance}
                            <Divider />
                            <span>币种:</span>
                            {attachment.currency}
                            <Divider />
                            <span>联系人电话:</span>
                            {attachment.remittancePhone}
                        </span>
                    ):""}
                    
                </div>
            );
        },
    },
    {
        title: "Author",
        key: "author",
        render: record => {
            const author = record.author || {};
            return (
                <span>
                    <span>ID: </span>
                    {author.userid}
                    <Divider />
                    <span>Avatar: </span>
                    <Avatar
                        key={author.avatar}
                        src={agent.getApiUrl(author.avatar)}
                        size="large"
                        icon="user"
                    />
                    <Divider />
                    <span>Name: </span>
                    {author.family_name} {author.given_name}
                    <Divider />
                    <span>Email: </span>
                    {author.email}
                    <Divider />
                    <span>Phone: </span>
                    {author.phone}
                    <Divider />
                    <span>公司名: </span>
                    {author.company_name}
                    <Divider />
                    <span>公司营业号: </span>
                    {author.company_number}
                    <Divider />
                    <span>描述: </span>
                    {author.p_description}
                </span>
            );
        },
    },
];

const status = {
    title: (
        <span>
            Status&nbsp;
            <Tooltip title="订单状态">
                <Icon type="question-circle-o" />
            </Tooltip>
        </span>
    ),
    dataIndex: "status",
};

const action = {
    title: (
        <span>
            Action&nbsp;
            <Tooltip title="管理员可以手动完成没有付款的订单">
                <Icon type="question-circle-o" />
            </Tooltip>
        </span>
    ),
    key: "action",
    render: (text, record) => (
        <span>
            <Button
                disabled={record.updating}
                onClick={record.handleEditClick}
                loading={record.purchasing}
            >
                修改价格
            </Button>
            <Divider />
            <Button
                disabled={record.updating}
                loading={record.purchasing}
                onClick={record.handlePurchaseClick}
            >
                完成交易
            </Button>
            <Divider />
            <Button
                disabled={record.updating}
                loading={record.deleting}
                onClick={record.handleDeleteClick}
                type="danger"
            >
                删除订单
            </Button>
        </span>
    ),
};

export default history => {
    const data = [];
    for (const record of columns) {
        data.push({ ...record });
    }
    if (history) {
        data.push(status);
    } else {
        data.push(action);
    }
    return data;
};
