import React from "react";
import WaveSurfer from "wavesurfer.js";
import PropTypes from "prop-types";
import agent from "agent";
//import { addPeaks } from "dispatch";
import "./Player.less";
export default class Waveform extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            playing: false,
        };
        this.wavesurfer = null;
    }
    static propTypes = {
        audio: PropTypes.shape({
            id: PropTypes.number.isRequired,
            audioid: PropTypes.string.isRequired,
            audio: PropTypes.string.isRequired,
            playing: PropTypes.bool.isRequired,
            merged_peaks: PropTypes.string,
        }).isRequired,
    };
    static defaultProps = {
        musicUrl: "",
        audio: {
            id: 0,
            audioid: "",
            audio: "",
            playing: true,
            merged_peaks: "",
        },
    };
    componentDidMount() {
        const { playing } = this.props.audio;
        if (!this.wavesurfer) {
            this.wavesurfer = WaveSurfer.create({
                container: "#wave-player",
                waveColor: "#c4c8dc",
                progressColor: "#6c718c",
                normalize: true,
                barWidth: 4,
                height: 30,
                playing: playing,
                pos: 0,
                volume: 0.5,
                audioRate: 1,
                backend:"WebAudio",
                pixelRatio:1,
            });

            this.wavesurfer.on("ready", () => {
                console.info(11111111)
                this.wavesurfer.play();
                // if (this.wavesurfer.backend.mergedPeaks) {
                //     let peaks = this.wavesurfer.backend.mergedPeaks.toString();
                //     addPeaks(audioid, peaks);
                // }
            });
        }
    }
    componentDidUpdate(prevProps) {
        console.info(1111)
        const { audio } = this.props.audio;
        if (!this.wavesurfer) {
            return;
        }
        if (!prevProps.audio || audio != prevProps.audio.audio) {
            // if (merged_peaks != null) {
            //     const arr = merged_peaks.split(",");
            //     let arrs = [];
            //     for (let i = 0; i < arr.length; i++) {
            //         arrs.push(parseFloat(arr[i]));
            //     }
            //     this.wavesurfer.load(agent.getApiUrl(audio), arrs);
            // } else {
            //     this.wavesurfer.load(agent.getApiUrl(audio));
            // }
            this.wavesurfer.load(agent.getApiUrl(audio));
        }
        if (this.props.audio.playing) {
            if (!prevProps.audio.playing) {
                this.wavesurfer.playPause();
            } else {
                this.wavesurfer.play();
            }
        } else {
            this.wavesurfer.pause();
        }
    }
    render() {
        return (
            <div className="fixedPlayer__wave__img" id="wave-player" />
            
        );
    }
}
