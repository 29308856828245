import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
//import { Link } from "react-router-dom";
import { Form, Input, Modal, Select, } from "antd";

//import agent from "agent";

import ListErrors from "components/ListErrors";

import "./Order.less";
const mapStateToProps = state => ({
    errors: state.home.errors,
});

let totolPrice = 0;

const mapDispatchToProps = dispatch => ({});

class OrderModal extends React.Component {
    static propTypes = {
        visible: PropTypes.bool,
        modalData: PropTypes.object,
        onModalOk: PropTypes.func,
        onModalCancel: PropTypes.func,
        condition: PropTypes.object,
    };
    state = {
        fileList: [],
    };

    static getDerivedStateFromProps(nextProps, prevState) {
        if (!nextProps.visible) {
            return { fileList: [] };
        }
        return null;
    }

    handleSubmit = e => {
        e.preventDefault();
    };
    /**弹出框后点击ok键 */
    handlePartnerModalOk = e => {
        const {modalData: data,form,onModalOk,onModalCancel} = this.props;
        const mata = data || {};
        form.validateFieldsAndScroll((err,values) => {
            /**如果有值，获取输入的值 */
            if (!err) {
                /**send：输入的价格 */
                let send = {};
                let sendArr = [];
                send.price=totolPrice;
                send.id=mata.id;
                
                const audios=data.audios;
                for (let i = 0; i < audios.length; i++) {
                    const key="price_"+audios[i].id;
                    let objSend = {
                        price:parseInt(values[key]*100),
                        id:audios[i].id,
                    };
                    sendArr.push(objSend);
                }
                send.subPrice = sendArr;

                // if(Object.keys(send).length>0){
                //     onModalOk(send);
                // }else {
                //     onModalCancel();
                // }
                onModalOk(send);
            }else {
                onModalCancel();
            }
        })
    };
    // form.validateFieldsAndScroll((err,values) => {
    //     /**如果有值，获取输入的值 */
    //     if (!err) {
    //         /**send：输入的价格 */
    //         let send = {};
    //         send.price=values.price;
    //         send.id=mata.id;
    //         if(Object.keys(send).length>0){
    //             onModalOk(send);
    //         }else {
    //             onModalCancel();
    //         }
    //     }
    // })
    handleDeleteAvatarClick = e => {
        e.preventDefault();
        e.stopPropagation();
        this.props.form.setFields({
            thumb: {
                value: "",
            },
        });
    };
    createOptions(list) {
        
        return [
            <Select.Option value={0} key={-1}>
                {'请选择'}
            </Select.Option>,
            list.map((x, i) => {
                const { id, title } = x;
                return (
                    <Select.Option value={id} key={i}>
                        {title}
                    </Select.Option>
                );
            }),
        ];
    }
    createOptionss(list) {
        return [
            <Select.Option value={0} key={-1}>
                {'请选择'}
            </Select.Option>,
            list.map((x, i) => {
                const { id, title,father_id } = x;
                let til="";
                if (father_id!=0) {
                    for (let j = 0; j < list.length; j++) {
                        const element = list[j];
                        if (element.id==father_id) {
                            til=element.title
                        }
                        
                    }
                    return (
                        <Select.Option value={id} key={i}>
                            {til}-{title}
                        </Select.Option>
                    );
                }
            }),
        ];
    }

    // input 焦点离开
    inputOnBlur = ()=>{
        const { form ,modalData} = this.props;
        totolPrice = 0;
        form.validateFieldsAndScroll((err, values) => {
            const audios=modalData.audios;
            for (let i = 0; i < audios.length; i++) {
                const key="price_"+audios[i].id;
                totolPrice += parseInt(values[key]*100); 
            }
        })
        // 更改 modalData 中 totolPrice
        modalData.price = totolPrice;
        this.forceUpdate();
    }
    
    // input 焦点聚焦
    inputOnFocus = ()=> {

    }

    //循环数据
    renderCell(eleObj,i) {
        // const {modalData} = this.props;
        const { getFieldDecorator } = this.props.form;
        const formItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 8 },
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 16 },
            },
        };

        // return [
        // modalData.audios.map((eleObj, i) => {
        const { title,price,id, } = eleObj;
        return (
            <div key= {i}>
                <div className= "title_style">歌曲名称:{title}</div>
                <div style={{height:"50px"}}>

                    <Form.Item {...formItemLayout} label= "修改前价格(元)">
                        <p className="ant-form-text" id="userName" name="userName">{price/100}</p>
                    </Form.Item>

                    <Form.Item {...formItemLayout} label= "修改后价格(元)">
                        {getFieldDecorator("price_"+id, {
                            rules: [
                                {
                                    pattern:new RegExp(/(^[1-9]\d*(\.\d{1,2})?$)|(^0(\.\d{1,2})?$)/),
                                    required: true,
                                    message: "请输入正确价格!",
                                },
                            ],
                            initialValue: price/100,
                        })(<Input onBlur={this.inputOnBlur} onFocus={this.inputOnFocus}/>)}
                    </Form.Item>
                </div>    
            </div>
        );
        // }),
        // ]
    }

    render() {
        const {
            visible,
            confirmLoading,
            modalData,
            onModalCancel,
        } = this.props;

        if (!visible) {
            return null;
        }
        const data = modalData || {};
        // const { getFieldDecorator } = this.props.form;
        // const formItemLayout = {
        //     labelCol: {
        //         xs: { span: 24 },
        //         sm: { span: 8 },
        //     },
        //     wrapperCol: {
        //         xs: { span: 24 },
        //         sm: { span: 16 },
        //     },
        // };
        const tltle =  `编辑: ${data.id}`;
        const no =false;
        totolPrice = data.price;
        return (
            <Modal
                title={tltle}
                centered
                destroyOnClose
                confirmLoading={confirmLoading}
                visible={visible}
                onOk={this.handlePartnerModalOk}
                onCancel={onModalCancel}
                maskClosable={no}
                className="order_price_modal"
            >
                <ListErrors errors={this.props.errors} />
                {/**弹出的表单框 */}
                <Form onSubmit={this.handleSubmit}>
                    {/* <Form.Item {...formItemLayout} label="请修改总价：">
                        {getFieldDecorator("price", {
                            rules: [
                                {
                                    required: false,
                                    message: "权重值只能是数字!",
                                },
                            ],
                            initialValue: data.price/100,
                        })(<Input />)}
                    </Form.Item> */}
                    
                    {data.audios.map(this.renderCell.bind(this))}
                    <div className= "totol_price">总价格：{totolPrice/100}元</div>
                </Form>
            </Modal>
        );
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Form.create({ name: "OrderModal" })(OrderModal));
