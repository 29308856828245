import {
    MEMBER_SET_FONT,
    MEMBER_SET_ADD,
    MEMBER_SET_DEL,
    COUPONCODE_FONT,
    COUPONCODE_DEL,
    PRICE_GROUP_ADD_SHOW,
    PRICE_GROUP_ADD_HIDE,
    USER_PAGE_LOADEDA,
    USER_PAGE_LOADEDB,
    AMEND_TIME_ADD,
    AMEND_TIME_HIDE,
    MEMMBER_UPDATE,
} from "../constants/actionTypes";

const defaultState = {
    results: {},
    pagination: { pageSize: 10, current: 1 },
    loading: false,
    fetchError: false,
    updateStatus: { userid: "", status: "", loading: false },
    userEdit: {},
    userEditModal: false,
    confirmLoading: false,
    addShow:false,
    show:false,
    timeShow:false,
};
export default (state = { ...defaultState }, action) => {
    switch (action.type) {
        case MEMBER_SET_FONT: {
            const pagination = action.pager;
            console.log('action',action.payload)
            // if (!action.error) {
            //     pagination.total = action.payload.total;
            // }
            console.log('actions',action.payload)
            return {
                ...state,
                fetchError: !!action.error,
                results: action.payload,
                pagination,
                loading: false,
            };
        }
        case MEMMBER_UPDATE: {
            return {
                ...state,
                results: action.payload,
            };
        }
        case USER_PAGE_LOADEDA: {
            return {
                ...state,
                show: true,
            };
        }
        case USER_PAGE_LOADEDB: {
            return {
                ...state,
                show: false,
            };
        }
        case MEMBER_SET_ADD: {
            const userEdit = { ...action.payload };
            return { ...state, userEdit, userEditModal: true ,};
        }
        case MEMBER_SET_DEL: {
            const userEdit = { ...action.payload };
            return { ...state, userEdit, userEditModal: true ,};
        }
        case COUPONCODE_FONT: {
            const results = { ...action.payload };
            return { ...state, results, userEditModal: true ,};
        }
        case COUPONCODE_DEL: {
            const results = { ...action.payload };
            return { ...state, results, userEditModal: true ,};
        }
        case PRICE_GROUP_ADD_SHOW: {
            return { 
                ...state,
                addShow:true,
            };
        }
        case PRICE_GROUP_ADD_HIDE: {
            return { 
                ...state,
                addShow:false,
            };
        }
        case AMEND_TIME_ADD: {
            return { 
                ...state,
                timeShow:true,
            };
        }
        case AMEND_TIME_HIDE: {
            return { 
                ...state,
                timeShow:false,
            };
        }
        default:
            return state;
    }
};
