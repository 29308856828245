import React from "react";
import { Button } from "antd";
import audioColumns from "components/Audio/audio-columns";

const columns = [
    {
        title: "Id",
        dataIndex: "id",
        defaultSortOrder: "descend",
    },
    audioColumns.Audio,
    audioColumns.Author,
    {
        title: <span>Action</span>,

        render: record => {
            const disabled = record.loading;
            return (
                <div>
                    <Button
                        type="danger"
                        disabled={disabled}
                        loading={disabled}
                        onClick={record.handleDeleteClick}
                    >
                        从推荐中删除
                    </Button>
                </div>
            );
        },
    },
];

export default () => {
    const data = [];
    for (const record of columns) {
        data.push({ ...record });
    }
    return data;
};
