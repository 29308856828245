import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Form, Input, Modal } from "antd";

import ListErrors from "components/ListErrors";
const { TextArea } = Input;

const mapStateToProps = state => ({
    errors: state.home.errors,
});

const mapDispatchToProps = dispatch => ({});

class PartnerModal extends React.Component {
    static propTypes = {
        visible: PropTypes.bool,
        modalData: PropTypes.object,
        onModalOk: PropTypes.func,
        onModalCancel: PropTypes.func,
    };

    handleSubmit = e => {
        e.preventDefault();
    };
    handlePartnerModalOk = e => {
        const { modalData: data, form, onModalOk, onModalCancel } = this.props;
        const modalData = data || {};
        form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                const send = {};
                const sendKeys = ["a", "q"];
                for (const key of sendKeys) {
                    send[key] = values[key];
                }

                if (Object.keys(send).length) {
                    onModalOk({ id: modalData.id, ...send });
                } else {
                    onModalCancel();
                }
            }
        });
    };

    render() {
        const {
            visible,
            confirmLoading,
            modalData,

            onModalCancel,
        } = this.props;
        if (!visible) {
            return null;
        }
        const data = modalData || {};
        const { getFieldDecorator } = this.props.form;
        const formItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 4 },
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 16 },
            },
        };
        const tltle = data.id ? `编辑: ${data.id}` : `添加`;
        return (
            <Modal
                title={tltle}
                centered
                destroyOnClose
                confirmLoading={confirmLoading}
                visible={visible}
                onOk={this.handlePartnerModalOk}
                onCancel={onModalCancel}
            >
                <ListErrors errors={this.props.errors} />

                <Form onSubmit={this.handleSubmit}>
                    <Form.Item {...formItemLayout} label="Q">
                        {getFieldDecorator("q", {
                            rules: [
                                {
                                    required: true,
                                    message: "请输入问题标题!",
                                },
                            ],
                            initialValue: data.q,
                        })(<Input />)}
                    </Form.Item>

                    <Form.Item {...formItemLayout} label="a">
                        {getFieldDecorator("a", {
                            rules: [
                                {
                                    required: true,
                                    message: "清输入回答!",
                                },
                            ],
                            initialValue: data.a,
                        })(<TextArea style={{ minHeight: 200 }} />)}
                    </Form.Item>
                </Form>
            </Modal>
        );
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Form.create({ name: "PartnerModal" })(PartnerModal));
