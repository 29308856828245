import {
    PRODUCER_PAGE_LOADED,
    PRODUCER_PAGE_UNLOADED,
    PRODUCER_STATUS_UPDATE,
    ASYNC_START,
} from "../constants/actionTypes";

const defaultState = {
    results: { producers: [] ,priceGroup:[]},
    pagination: { pageSize: 10, current: 1 },
    loading: false,
    fetchError: false,
    updateStatus: { user_id: -1, status: "", loading: false },
};
export default (state = { ...defaultState }, action) => {
    switch (action.type) {
        case PRODUCER_PAGE_LOADED: {
            const pagination = action.pager;
            if (!action.error) {
                pagination.total = action.payload.total;
            }
            return {
                ...state,
                fetchError: !!action.error,
                results: action.payload,
                pagination,
                loading: false,
            };
        }
        case PRODUCER_STATUS_UPDATE: {
            const updateStatus = { ...state.updateStatus };
            updateStatus.user_id = action.error ? -1 : action.send.user_id;
            updateStatus.status = action.send.status;
            updateStatus.loading = false;
            return { ...state, updateStatus };
        }
        case ASYNC_START:
            if (action.subtype === PRODUCER_PAGE_LOADED) {
                return { ...state, loading: true };
            }
            if (action.subtype === PRODUCER_STATUS_UPDATE) {
                const updateStatus = { ...state.updateStatus };
                updateStatus.user_id = action.send.user_id;
                updateStatus.status = action.send.status;
                updateStatus.loading = true;
                return { ...state, updateStatus };
            }
            return state;
        case PRODUCER_PAGE_UNLOADED:
            return { ...defaultState };
        default:
            return state;
    }
};
