import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

import { connect } from "react-redux";
import { LOGOUT } from "constants/actionTypes";
import { redirectTo, logout } from "dispatch";

const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => ({
    logout: () => dispatch({ type: LOGOUT }),
});

class ReLogin extends React.Component {
    static propTypes = {
        logout: PropTypes.func.isRequired,
    };

    logout = e => {
        e.preventDefault();
        e.stopPropagation();

        logout();
        redirectTo("/login");
    };

    render() {
        return (
            <div style={{ textAlign: "center", marginBottom: " 30px" }}>
                <Link to="#" onClick={this.logout.bind(this)}>
                    Click me Login Again.
                </Link>
            </div>
        );
    }
}
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ReLogin);
