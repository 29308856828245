import {
    ORDER_PAGE_LOADED,
    ORDER_PAGE_UNLOADED,
    ORDER_DELETE,
    ORDER_PURCHASE,
    ASYNC_START,
    ORDER_MODAL_SHOW,
    ORDER_MODAL_HIDE,
} from "../constants/actionTypes";

const defaultState = {
    results: { orders: [] },
    pagination: { pageSize: 10, current: 1 },
    loading: false,
    fetchError: false,
    modal: {
        data: null,
        visible: false,
    },
};
export default (state = { ...defaultState }, action) => {
    switch (action.type) {
        case ORDER_MODAL_HIDE: {
            const modal = {
                data: null,
                visible: false,
            };
            return {
                ...state,
                modal,
            };
        }
        case ORDER_MODAL_SHOW: {
            const modal = {
                data: action.payload,
                visible: true,
            };
            return {
                ...state,
                modal,
            };
        }
        case ORDER_PAGE_LOADED: {
            const pagination = action.pager;
            if (!action.error) {
                pagination.total = action.payload.total;
            }
            return {
                ...state,
                fetchError: !!action.error,
                results: action.payload,
                pagination,
                loading: false,
            };
        }
        case ASYNC_START:
            if (action.subtype === ORDER_PAGE_LOADED) {
                return { ...state, loading: true };
            }
            if (action.subtype === ORDER_DELETE) {
                const audioid = action.send.orderid;
                const results = { ...state.results };
                const orders = results.orders;
                for (const record of orders) {
                    if (record.orderid == audioid) {
                        record.deleting = true;
                        record.updating = true;
                    }
                }
                return { ...state, results };
            }
            if (action.subtype === ORDER_PURCHASE) {
                const audioid = action.send.orderid;
                const results = { ...state.results };
                const orders = results.orders;
                for (const record of orders) {
                    if (record.orderid == audioid) {
                        record.purchasing = true;
                        record.updating = true;
                    }
                }
                return { ...state, results };
            }
            return state;
        case ORDER_PAGE_UNLOADED:
            return { ...defaultState };
        default:
            return state;
    }
};
