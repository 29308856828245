import React from "react";
import { Table,Layout,DatePicker,Button } from "antd";
import agent from "agent"; 
import { connect } from "react-redux";
import moment from "moment"
const { Content } = Layout;
//import PropTypes from "prop-types";
const columns = [
    {
        title: "搜索日期",
        dataIndex: "searchdate",
        sorter: true,
        defaultSortOrder: "descend",
    }, 
    {
        title: "关键词",
        dataIndex: "keyword", 
    }, 
];
const mapStateToProps = state => ({});
const mapDispatchToProps = dispatch => ({}); 
const devStyle = {marginBottom: "20px",}
const spanStyle = {fontSize: "16px",color: "#666"}
const dateFormat = 'YYYY-MM-DD';
let startdate=moment().format('YYYY-MM-DD');
let enddate=moment().format('YYYY-MM-DD');
const onStartChange=(value,dateString)=>{startdate=dateString;};
const onEndChange=(value,dateString)=>{enddate=dateString};
class List extends React.Component
{ 
    constructor(props) {
        super(props);
        this.state = {
            pageNum:1,  /*翻页查询*/
            pageSize:10,  /*分页查询*/
            activePage: 1,  /*默认显示一页*/
            selectedRowKeys: [],  // 这里配置默认勾选列
            loading: false,  /*antd*/
            selectedRow:[],
            data:[]
        };
     
        agent.CustomerData.getSearchkeyword({start:startdate,end:enddate},(retdata)=>{
            this.setState({data:retdata}); 
        }); 
    }
    
    searchSource=()=>
    {
        agent.CustomerData.getSearchkeyword({start:startdate,end:enddate},(retdata)=>{
            this.setState({data:retdata}); 
        }); 
    }
    
    render(){   
        return (
            <Content>
                <div style={devStyle}>
                    <span style={spanStyle}>开始时间 :</span>
                    <DatePicker
                        id="startdate"
                        defaultValue={moment()}
                        onChange={onStartChange}
                        format={dateFormat} />
                    <span style={spanStyle}>截至时间 :</span>
                    <DatePicker
                        id="enddate"
                        defaultValue={moment()}
                        onChange={onEndChange}
                        format={dateFormat} />
                    <Button
                        onClick={this.searchSource}
                        type="primary"
                        style={{ marginBottom: 16 }}
                    >搜索</Button>
                </div>
                <Table
                    className="c382-custdata-list"
                    columns={columns}
                    dataSource={this.state.data}
                /> 
            </Content>
        );
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(List);