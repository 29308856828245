import React from "react";
import { connect } from "react-redux";
import { Route, Switch } from "react-router-dom";
// import NotFound from "components/NotFound";
import Info from "./Info";
import OneList from "./OneList";

const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => ({});

class Playlist extends React.Component {
    render() {
        const { match } = this.props;
        const basepath = match.path === "/" ? "" : match.path;

        return (
            <Switch>
                <Route exact path={`${basepath}/info`} component={Info} />
                <Route path={`${basepath}/:playlist_id`} component={OneList} />
            </Switch>
        );
    }
}
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Playlist);
