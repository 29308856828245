import React from "react";
import agent from "agent";
import { Divider, Button, Select } from "antd";
import helper from "utils/helper";
import ImageZoom from "react-medium-image-zoom";

const Option = Select.Option;

const columns = {
    id: {
        title: "Id",
        dataIndex: "id",
        sorter: true,
        defaultSortOrder: "descend",
    },

    contactus: {
        title: "Info",
        key: "Info",
        render: record => {
            return (
                <div>
                    <span>name: </span>
                    {record.name}
                    <Divider />
                    <span>phone: </span>
                    {record.email}
                    <Divider />
                    <span>email: </span>
                    {record.title}
                    <Divider />
                    <span>content: </span>
                    {record.content}
                    <Divider />
                    <span>Update at: </span>
                    {helper.showDate(record.update_at)}
                    <Divider />
                    <span>Create at: </span>
                    {helper.showDate(record.create_at)}
                    <Divider />
                </div>
            );
        },
    },

    // qa
    qa_info: {
        title: "Info",
        render: record => {
            return (
                <div style={{  width: '400px', height: 'auto',wordWrap:'break-word',wordBreak:'break-all',
                    overflow: 'hidden'}}>
                    <span>Q: </span>
                    {record.q}
                    <Divider />
                    <span>A: </span>
                    <p>{record.a}</p>
                    <Divider />
                    <span>Update at: </span>
                    {helper.showDate(record.update_at)}
                    <Divider />
                </div>
            );
        },
    },

    contactusAction: {
        title: <span>Action</span>,
        filters: [
            { text: "Unreviewed", value: "unreviewed" },
            { text: "Reviewed", value: "reviewed" },
        ],
        render: record => {
            const disabled = record.loading;
            return (
                <div>
                    <div style={{ marginBottom: 20 }}>
                        <Select
                            defaultValue={record.status}
                            style={{ width: 150 }}
                            onChange={record.handleStatusChange}
                            loading={record.loading}
                            disabled={disabled}
                        >
                            <Option value="unreviewed">Unreviewed</Option>
                            <Option value="reviewed">Reviewed</Option>
                        </Select>
                    </div>
                    <div>
                        <Button
                            type="danger"
                            disabled={disabled}
                            loading={disabled}
                            onClick={record.handleDeleteClick}
                        >
                            删除
                        </Button>
                    </div>
                </div>
            );
        },
    },

    // partner
    partner: {
        title: "partner",
        key: "partner",
        render: record => {
            return (
                <div>
                    <ImageZoom
                        key={record.thumb}
                        image={{
                            src: agent.getApiUrl(record.thumb),
                            className: "thumb-big",
                        }}
                    />
                    <Divider />
                    <span>Title: </span>
                    {record.title}
                    <Divider />
                    <span>Weight: </span>
                    {record.weight}
                    <Divider />
                    <span>Status: </span>
                    {record.status}
                    <Divider />
                    <span>Update at: </span>
                    {helper.showDate(record.update_at)}
                    <Divider />
                </div>
            );
        },
    },

    action: {
        title: <span>Action</span>,

        render: record => {
            const disabled = record.loading;
            return (
                <div>
                    <div style={{ marginBottom: 20 }}>
                        <Button type="dashed" onClick={record.handleEditClick}>
                            编辑
                        </Button>
                    </div>
                    <Button
                        type="danger"
                        disabled={disabled}
                        loading={disabled}
                        onClick={record.handleDeleteClick}
                    >
                        删除
                    </Button>
                </div>
            );
        },
    },
};

export default columns;
