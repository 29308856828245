import React from "react";
import { Button, Table, Select, Layout, Input, DatePicker, Popconfirm, Modal } from "antd";
// , 
import agent from "agent";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import locale from 'antd/es/date-picker/locale/zh_CN';
import ExportJsonExcel from 'js-export-excel';
import {
    MEM_BER,
    OPEN_MEMBER,
    MEMBER_DERIVE,
    MEMBER_DERIVE_FALSE,
} from "constants/actionTypes";
import ReLogin from "components/ReLogin";
const Option = Select.Option;
const { Content } = Layout;
const { RangePicker } = DatePicker;

const mapStateToProps = state => ({
    ...state.member,
});

const mapDispatchToProps = dispatch => ({
    onLoad: (pager, payload) =>
        dispatch({ type: MEM_BER, pager, payload }),
    openmember: (payload) =>
        dispatch({ type: OPEN_MEMBER, payload }),
    onDerive: payload => {
        dispatch({
            type: MEMBER_DERIVE,
            payload,
        })
    },
    faDerive: payload => {
        dispatch({
            type: MEMBER_DERIVE_FALSE,
            payload,
        })
    },

});

class List extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            orderid: "",
            username: "",
            start_time: "",
            end_time: "",
            type: '',
            status: '',
            receipt: '',
            modal2Visible: false,
            price: '',
            orderids: '',
            isdisabled: false,
            types: 0,
        };
    }
    static propTypes = {
        pagination: PropTypes.object.isRequired,
        results: PropTypes.object.isRequired,
        loading: PropTypes.bool.isRequired,
        onLoad: PropTypes.func.isRequired,
        openmember: PropTypes.func.isRequired,
        onUnload: PropTypes.func.isRequired,
        fetchError: PropTypes.bool.isRequired,
        // onShowModal: PropTypes.func.isRequired,
        onDerive: PropTypes.func.isRequired,
        onHideModal: PropTypes.func.isRequired,
        openAddPriceGroup: PropTypes.func.isRequired,
        onHideAddModal: PropTypes.func.isRequired,
        addPriceGroup: PropTypes.func.isRequired,
        onUpdatePriceGroup: PropTypes.func.isRequired,
        // onUpdateGroupStatus: PropTypes.func.isRequired,
        subscribe: PropTypes.func.isRequired,
        faDerive: PropTypes.func.isRequired,
        memberDerive: PropTypes.bool,
    };

    componentDidMount() {
        this.onLoad();
    }
    componentDidUpdate(prevProps) {
        console.log(prevProps,this.props)
        if (prevProps.memberDerive === true ) {
            this.saveExcel()
            return
        }
    }
    //加载价格组信息
    onLoad = () => {
        const { onLoad, pagination } = this.props;
        const pager = { ...pagination };
        const params = {
            page: pager.current || 1,
            size: pagination.pageSize,
            orderby: "-id",
            orderid: this.state.orderid,
            username: this.state.username,
            start_time: this.state.start_time,
            end_time: this.state.end_time,
            type: this.state.type,
            status: this.state.status,
            receipt: this.state.receipt
        };
        onLoad(pager, agent.Member.post(params));
    };
    handleorderidChange = e => {
        this.setState({ orderid: e.target.value });
    }
    handleusernameChangea = e => {
        this.setState({ username: e.target.value });
    }
    handletypeChange = e => {
        this.setState({ type: Number(e) });
    }
    handlestatusChange = e => {
        if (e == 0) {
            this.setState({
                isdisabled: true,
                receipt: '',
                status: e
            })
        } else {
            this.setState({
                isdisabled: false,
                status: Number(e)
            })
        }
    }
    handlereceiptChange = e => {
        this.setState({ receipt: Number(e) });
    }
    bindreceiptkp = (orderid) => {
        const { openmember } = this.props;
        const params = {
            orderid: orderid,
            receipt: 2,
        };
        openmember(agent.Member.postStatus(params));
        let _this = this
        setTimeout(function () {
            _this.onLoad()
        }, 300)
    }
    setModal2Visible(modal2Visible, price, orderid) {
        this.setState({ modal2Visible });
        this.setState({ price });
        this.setState({ orderids: orderid });
    }
    bindstatusjg = (modal2Visible, t) => {
        this.setState({ modal2Visible });
        const { openmember } = this.props;
        const params = {
            orderid: this.state.orderids,
            price: Number(this.state.price),
        };
        if (t) {
            openmember(agent.Member.postStatus(params));
            let _this = this
            setTimeout(function () {
                _this.onLoad()
            }, 300)
        }

    }
    handleupChangea = e => {
        this.setState({ price: e.target.value });
    }
    openmember = (orderid) => {
        const { openmember } = this.props;
        const params = {
            orderid: orderid,
            status: 1,
        };
        openmember(agent.Member.postStatus(params));
        let _this = this
        setTimeout(function () {
            _this.onLoad()
        }, 300)
    }
    //翻页
    handleTableChange = (pagination, filters, sorter) => {
        const pager = { ...this.props.pagination };
        pager.current = pagination.current;
        const params = {
            page: pager.current || 1,
            size: pagination.pageSize || 10,
            orderby: "-id",
            orderid: this.state.orderid || '',
            username: this.state.username || '',
            start_time: this.state.start_time || '',
            end_time: this.state.end_time || '',
            type: this.state.type || '',
            status: this.state.status || '',
            receipt: this.state.receipt || ''
        };
        this.props.onLoad(pager, agent.Member.post(params));
    };
    downloadExl = (e, d) => {
        const { pagination, subscribe, onDerive } = this.props;
        const pager = { ...pagination };
        let t;
        pager.current = 1;
        if (subscribe.total < 10) {
            t = 10
        } else {
            t = subscribe.total
        }
        const params = {
            page: pager.current,
            size: t,
            orderby: "-id",
        };
        if (this.state.orderid) {
            params.orderid = this.state.orderid
        }
        if (this.state.username) {
            params.username = this.state.username
        }
        if (this.state.type) {
            params.type = this.state.type
        }
        if (this.state.status) {
            params.status = this.state.status
        }
        if (this.state.start_time && this.state.end_time) {
            params.start_time = this.state.start_time
            params.end_time = this.state.end_time
        }
        if (this.state.receipt || this.state.receipt === 0) {
            params.receipt = this.state.receipt
        }
        onDerive()
        this.props.onLoad(pager, agent.Member.post(params));

    };
    saveExcel() {
        const { subscribe } = this.props;  //从props中获取数据源
        let option = {};  //option代表的就是excel文件
        let dataTable = [];  //excel文件中的数据内容
        let result = subscribe.subscribe
        if (result && result.length > 0) {
            for (let i in result) {  //循环获取excel中每一行的数据
                if (result[i].receipt == 0 || result[i].receipt == '未申请') {
                    result[i].receipt = '未申请'
                } else if (result[i].receipt == 1 || result[i].receipt == '待开票') {
                    result[i].receipt = '待开票'
                } else if (result[i].receipt == 2 || result[i].receipt == '已开票') {
                    result[i].receipt = '已开票'
                }

                let attachment = JSON.parse(result[i].attachment || '') || {}
                if (attachment.payType == 1) {
                    attachment.payType = "支付宝"
                } else if (attachment.payType == 2) {
                    attachment.payType = "银行卡转账"
                } else if (attachment.payType == 3) {
                    attachment.payType = "微信支付"
                } else if (attachment.payType == 4) {
                    attachment.payType = "优惠码"
                } else {
                    attachment.payType = "未知"
                }
                let str = ''
                if (result[i].receipt == 1 || result[i].receipt == "待开票") {
                    str += `名称:${attachment.nashuiName},\n纳税人识别号:${attachment.nashui},\n邮箱:${attachment.nashuiMail || ''},\n地址:${attachment.nashuiAddress || ''},\n电话:${attachment.nashuiPhone || ''},\n开户行:${attachment.bankInfo || ''},\n银行账号:${attachment.bankNumber || ''}
                    `
                }
                let text = ''
                if (attachment.payType == 2 || attachment.payType == "银行卡转账") {
                    text += `汇款账号:${attachment.remittance || ''},\n币种:${attachment.currency || ''},\n联系电话:${attachment.remittancePhone || ''}
                        `
                }

                let obj = {
                    '订单ID': result[i].order_id || '',
                    '用户名': result[i].userphone || result[i].username || result[i].useremail || '',
                    '订单日期': result[i].start_time,
                    '订阅类型': result[i].type_name,
                    '订单金额(元)': String(result[i].price),
                    '会员有效期': result[i].create_at || '',
                    '支付方式': attachment.payType,
                    '优惠码' :attachment.code,
                    '转账信息': text,
                    '订单状态': result[i].status == 1 || result[i].status == '已支付' ? '已支付' : '未支付',
                    '开票信息': str,
                    '开票状态': result[i].receipt, 
                }
                dataTable.push(obj);  //设置excel中每列所获取的数据源
            }
        }
        option.fileName = '订阅会员表';  //excel文件名称
        option.datas = [
            {
                sheetData: dataTable,  //excel文件中的数据源
                sheetName: '订阅会员表',  //excel文件中sheet页名称
                sheetFilter: ['订单ID', '用户名', '订单日期', '订阅类型', '订单金额(元)', '会员有效期', '支付方式', '转账信息', '订单状态', '开票信息', '开票状态'],  //excel文件中需显示的列数据
                sheetHeader: ['订单ID', '用户名', '订单日期', '订阅类型', '订单金额(元)', '会员有效期', '支付方式', '转账信息', '订单状态', '开票信息', '开票状态'],  //excel文件中每列的表头名称
                columnWidths: ['13', '9', '7', '7', '5', '11', '5', '10', '5', '10', '5',]
            }
        ]
        let toExcel = new ExportJsonExcel(option);  //生成excel文件
        toExcel.saveExcel();  //下载excel文件
        this.props.faDerive()
    }
    //修改价格
    handleUpdateModalOk = send => {
        const { onUpdatePriceGroup, onHideModal } = this.props;
        const data = {
            info: send
        }
        const callback = () => {
            const { onLoad, pagination } = this.props;
            const pager = { ...pagination };
            const params = {
                page: pager.current,
                size: pagination.pageSize,
                orderby: "-id",
            };
            onHideModal();
            onLoad(pager, agent.PriceGroup.get(params));
        };
        onUpdatePriceGroup(data, callback);
    };
    onChange(date, dateString) {
        this.setState({ start_time: dateString[0] });
        this.setState({ end_time: dateString[1] });
    }
    //增加价格组
    handleAddModalOk = send => {
        const { onHideAddModal, addPriceGroup } = this.props;
        const callback = () => {
            const { onLoad, pagination } = this.props;
            const pager = { ...pagination };
            const params = {
                page: 1,
                size: pagination.pageSize,
                orderby: "-id",
            };
            onHideAddModal();
            onLoad(pager, agent.PriceGroup.get(params));
        };
        addPriceGroup(send, callback);
    };


    handleAddPriceGroup = e => {
        const { onLoad, pagination } = this.props;
        const pager = { ...pagination };
        pager.current = 1;
        if (this.state.isdisabled == true) {
            this.setState({
                receipt: ''
            })
        }
        const params = {
            page: 1,
            size: pagination.pageSize,
            orderby: "-id",
            orderid: this.state.orderid || '',
            username: this.state.username || '',
            start_time: this.state.start_time || '',
            end_time: this.state.end_time || '',
            type: this.state.type || '',
            status: this.state.status || '',
            receipt: this.state.receipt || ''
        };
        onLoad(pager, agent.Member.post(params));
    }
    render() {
        const columns = [
            {
                title: "订单ID",
                dataIndex: "",
                sorter: false,
                defaultSortOrder: "descend",
                render: (record) => {
                    return (
                        <span>
                            {record.order_id}
                        </span>
                    )
                }
            },
            {
                title: "用户名",
                key: "title",
                render: (record) => {
                    return (
                        <div>
                            <p>{record.useremail}</p>
                            <p>{record.userphone}</p>
                            <p>{record.username}</p>
                        </div>
                    )
                },
            },
            {
                title: "订单日期",
                key: "create_by",
                render: (record) => {
                    return (
                        <span>
                            {record.start_time}
                        </span>
                    );
                },
            },
            {
                title: "订阅类型",
                key: "create_at",
                render: (record) => {
                    return (
                        <span>
                            {record.type_name}
                        </span>
                    );
                },
            },
            {
                title: "订单金额",
                key: "update_by",
                render: (record) => {
                    return (
                        <span>
                            ￥{record.price}
                        </span>
                    );
                },
            },
            {
                title: "会员有效期",
                key: "update_at",
                render: (record) => {
                    return (
                        <span>
                            {record.update_at}
                        </span>
                    );
                },
            },
            {
                title: "支付方式",
                key: "flag",
                render: (record) => {
                    let attachment = JSON.parse(record.attachment)
                    if (attachment.payType == 1) {
                        attachment.payType = "支付宝"
                    } else if (attachment.payType == 2) {
                        attachment.payType = "银行卡转账"
                    } else if (attachment.payType == 3) {
                        attachment.payType = "微信支付"
                    } else if (attachment.payType == 4) {
                        attachment.payType = "优惠码"
                    } else {
                        attachment.payType = "未知"
                    }
                    return (
                        <span>
                            {attachment.payType}
                        </span>
                    );
                },
            },
            {
                title: "优惠码",
                key: "flag",
                render: (record) => {
                    let attachment = JSON.parse(record.attachment)
                     
                    return (
                        <span>
                            {attachment.code}
                        </span>
                    );
                },
            },
            {
                title: "转账信息",
                key: "do",
                render: (record) => {
                    let attachment = JSON.parse(record.attachment || {}) || {};
                    let str;
                    if (attachment.payType == 2 || attachment.payType == "银行卡转账") {
                        str = (
                            <div>
                                <p> <span>汇款账号:</span><span>{attachment.remittance}</span>  </p>
                                <p> <span>币种:</span><span>{attachment.currency}</span>  </p>
                                <p><span>联系电话:</span><span>{attachment.remittancePhone}</span>  </p>
                            </div>
                        )
                    }
                    return (
                        <div>
                            {str}
                        </div>
                    );
                },
            },
            {
                title: "订单状态",
                key: "ddzt",
                render: (record) => {
                    let colors = {
                        color: 'blue',
                        textDecoration: 'underline',
                        cursor: 'pointer'
                    }
                    let userMessage
                    if (record.status == 0) {
                        record.status = '待支付'
                        userMessage = (<div>
                            <p> {record.status}</p>
                            <p style={colors} onClick={() => this.setModal2Visible(true, record.price, record.order_id)}>修改价格</p>
                            <Popconfirm title="确认完成交易?" onConfirm={() => this.openmember(record.order_id)}>
                                <p style={colors}>完成交易</p>
                            </Popconfirm>
                        </div>)
                    } else if (record.status == 1) {
                        record.status = '已支付'
                        userMessage = (
                            <span> {record.status} </span>)
                    } else {
                        if (record.status == '待支付') {
                            userMessage = (<div>
                                <p> {record.status}</p>
                                <p style={colors} onClick={() => this.setModal2Visible(true, record.price, record.order_id)}>修改价格</p>
                                <Popconfirm title="确认完成交易?" onConfirm={() => this.openmember(record.order_id)}>
                                    <p style={colors}>完成交易</p>
                                </Popconfirm>
                            </div>)
                        } else if (record.status == '已支付') {
                            userMessage = (
                                <span> {record.status}</span>)
                        }
                    }

                    return (
                        <div>
                            {userMessage}
                        </div>
                    );
                },
            },
            {
                title: "开票信息",
                key: "kpxx",
                render: (record) => {
                    let attachment = JSON.parse(record.attachment || {}) || {};
                    let str;
                    if (record.receipt == 1 || record.receipt == "待开票") {
                        str = (
                            <div>
                                <p><span>名称:</span> <span>{attachment.nashuiName}</span></p>
                                <p><span>纳税人识别号:</span> <span>{attachment.nashui}</span> </p>
                                <p><span>邮箱:</span> <span>{attachment.nashuiMail}</span> </p>
                                <p><span>地址:</span> <span>{attachment.nashuiAddress}</span> </p>
                                <p><span>电话:</span> <span>{attachment.nashuiPhone}</span> </p>
                                <p><span>开户行:</span> <span>{attachment.bankInfo}</span> </p>
                                <p><span>银行账号:</span> <span>{attachment.bankNumber}</span> </p>
                            </div>
                        )
                    }
                    return (
                        <div>
                            {str}
                        </div>

                    )
                },
            },
            {
                title: "开票状态",
                key: "kpzt",
                render: (record) => {
                    let colors = {
                        color: 'blue',
                        textDecoration: 'underline',
                        cursor: 'pointer'
                    }
                    let userMessage = null;

                    if (record.receipt == 1) {
                        record.receipt = '待开票'
                        userMessage = (<div>
                            <p> {record.receipt}</p>
                            <Popconfirm title="确认完成开票?" onConfirm={() => this.bindreceiptkp(record.order_id)}>
                                <p style={colors}>完成开票</p>
                            </Popconfirm>

                        </div>)
                    } else if (record.receipt == 0) {
                        record.receipt = '未申请'
                        userMessage = (
                            <span> {record.receipt} </span>)
                    } else if (record.receipt == 2) {
                        record.receipt = '已开票'
                        userMessage = (
                            <span> {record.receipt} </span>)
                    } else {
                        if (record.receipt == "待开票") {
                            userMessage = (<div>
                                <p> {record.receipt}</p>
                                <Popconfirm title="确认完成开票?" onConfirm={() => this.bindreceiptkp(record.order_id)}>
                                    <p style={colors}>完成开票</p>
                                </Popconfirm>

                            </div>)
                        } else {
                            userMessage = (
                                <span> {record.receipt} </span>)
                        }

                    }

                    return (
                        <div>
                            {userMessage}
                        </div>
                    );
                },
            },
        ];
        const {
            pagination,
            loading,
            history,
            fetchError,
            subscribe
        } = this.props;
        if (fetchError) {
            return <ReLogin history={history} />;
        }

        pagination.total = subscribe.total;
        pagination.showQuickJumper = true;
        pagination.hideOnSinglePage = true;
        let data = subscribe.subscribe
        let orderid = this.state.orderid
        let username = this.state.username
        const inputStyle = {
            marginRight: "20px",
            marginLeft: "5px",
            width: "150px"
        }
        const devStyle = {
            marginBottom: "20px",
        }

        const spanStyle = {
            fontSize: "16px",
            color: "#666"
        }
        const fontStyle = {
            marginLeft: "15px",
            fontSize: "16px",
            color: "#666"
        }
        return (
            <div>
                <Content>
                    <div style={devStyle}>
                        <span style={spanStyle}>订单ID :</span>
                        <Input size="large" placeholder="订单ID" style={inputStyle} value={orderid} onChange={this.handleorderidChange} />
                        <span style={spanStyle}>用户名 :</span>
                        <Input size="large" placeholder="手机号/邮箱" style={inputStyle} value={username} onChange={this.handleusernameChangea} />
                        <span style={spanStyle}>订单日期:</span>
                        <RangePicker onChange={this.onChange.bind(this)} allowClear locale={locale} style={{ marginRight: "20px", marginLeft: "5px" }} />
                        <span style={fontStyle}>订阅类型:</span>
                        <Select
                            style={inputStyle}
                            onChange={this.handletypeChange}
                            loading={subscribe.loading}
                            disabled={subscribe.loading}
                            allowClear
                            placeholder="请选择"
                        >
                            <Option value="1">青铜会员(月)</Option>
                            <Option value="2">青铜会员(季)</Option>
                            <Option value="3">青铜会员(年)</Option>
                            <Option value="4">黄金会员(月)</Option>
                            <Option value="5">黄金会员(季)</Option>
                            <Option value="6">黄金会员(年)</Option>
                        </Select>
                    </div>
                    <div>
                        <span style={spanStyle}>订单状态:</span>
                        <Select
                            style={inputStyle}
                            onChange={this.handlestatusChange}
                            loading={subscribe.loading}
                            allowClear
                            placeholder="请选择"
                        >
                            <Option value="0">待支付</Option>
                            <Option value="1">已支付</Option>
                        </Select>
                        <span style={spanStyle}>开票状态:</span>
                        <Select
                            style={inputStyle}
                            onChange={this.handlereceiptChange}
                            loading={subscribe.loading}
                            placeholder="请选择"
                            allowClear
                            disabled={this.state.isdisabled}
                        >
                            <Option value="0">未申请</Option>
                            <Option value="1">待开票</Option>
                            <Option value="2">已开票</Option>
                        </Select>
                        <Button
                            onClick={this.handleAddPriceGroup}
                            type="primary"
                            style={{ marginBottom: 16 }}
                        >
                            搜索
                        </Button>
                        <Button
                            onClick={this.downloadExl.bind(this)}
                            type="primary"
                            style={{ marginBottom: 16, marginLeft: 20, }}
                        >
                            导出Excel
                        </Button>
                    </div>

                    <Modal
                        title="请输入修改价格"
                        centered
                        visible={this.state.modal2Visible}
                        onOk={() => this.bindstatusjg(false, true)}
                        onCancel={() => this.bindstatusjg(false)}
                    >
                        <Input placeholder="请输入价格" value={this.state.price} onChange={this.handleupChangea}></Input>
                    </Modal>
                    <Table
                        columns={columns}
                        rowKey={record => record.userid}
                        dataSource={data}
                        pagination={pagination}
                        loading={loading}
                        onChange={this.handleTableChange}
                        bordered={true}
                    />
                </Content>

            </div>
        );
    }
}
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(List);
