import { Link } from "react-router-dom";
import ListErrors from "./ListErrors";
import React from "react";
import PropTypes from "prop-types";
import agent from "../agent";
import { connect } from "react-redux";
import {
    // UPDATE_FIELD_AUTH,
    LOGIN,
    LOGIN_PAGE_UNLOADED,
} from "../constants/actionTypes";
import { Form, Icon, Input, Button, Checkbox } from "antd";
import { Row, Col } from "antd";
import "./Login.less";

const FormItem = Form.Item;

const mapStateToProps = state => ({ ...state.auth });

const mapDispatchToProps = dispatch => ({
    onSubmit: (email, password) =>
        dispatch({ type: LOGIN, payload: agent.Auth.login(email, password) }),
    onUnload: () => dispatch({ type: LOGIN_PAGE_UNLOADED }),
});

class Login extends React.Component {
    static propTypes = {
        errors: PropTypes.array,
        form: PropTypes.object.isRequired,
        inProgress: PropTypes.bool,
        loginSuccess: PropTypes.bool,

        onSubmit: PropTypes.func.isRequired,
        onUnload: PropTypes.func.isRequired,
        history: PropTypes.object.isRequired,
    };
    static defaultProps = {
        loginSuccess: false,
    };

    handleSubmit = e => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (!err) {
                sessionStorage.setItem('userName', values.userName)
                this.props.onSubmit(values.userName, values.password);
            }
        });
    };
    shouldComponentUpdate(nextProps, nextState) {
        if (nextProps.loginSuccess && agent.getToken()) {
            if (sessionStorage.getItem('userName')) {
                let usna = sessionStorage.getItem('userName')
                if (usna.indexOf('admin') != -1) {
                    this.props.history.push("/");
                } else if (usna.indexOf('music') != -1) {
                    this.props.history.push("/audio/list");
                } else if (usna.indexOf('marketing') != -1) {
                    this.props.history.push("/page/contactus/list");
                } else {
                    this.props.history.push("/");
                }
            } else {
                this.props.history.push("/");
            }
            return false;
        }
        return true;
    }
    // constructor() {
    //     super();
    //     this.changeEmail = ev => this.props.onChangeEmail(ev.target.value);
    //     this.changePassword = ev =>
    //         this.props.onChangePassword(ev.target.value);
    //     this.submitForm = (email, password) => ev => {
    //         ev.preventDefault();
    //         this.props.onSubmit(email, password);
    //     };
    // }

    componentWillUnmount() {
        this.props.onUnload();
    }

    render() {
        const { getFieldDecorator } = this.props.form;

        // const email = this.props.email;
        // const password = this.props.password;
        return (
            <div className="auth-page">
                <div className="container page background">
                    <Row type="flex" justify="space-around" align="middle" style={{ marginTop: "10%", paddingBottom: "10%" }}>
                        <Col span={6} push={5}>
                            <h1 className="text-xs-center" style={{ color: "deepskyblue" }}>MUSINESS管理系统</h1>
                            <p className="hidden text-xs-center">
                                <Link to="/register">Need an account?</Link>
                            </p>
                            <ListErrors errors={this.props.errors} />
                            <Form
                                onSubmit={this.handleSubmit}
                                className="login-form"
                            >
                                <FormItem>
                                    {getFieldDecorator("userName", {
                                        rules: [
                                            {
                                                required: true,
                                                message:
                                                    "请输入您的账号!",
                                            },
                                        ],
                                    })(
                                        <Input
                                            prefix={
                                                <Icon
                                                    type="user"
                                                    style={{
                                                        color:
                                                            "rgba(0,0,0,.25)",
                                                    }}
                                                />
                                            }
                                            placeholder="账号"
                                        />
                                    )}
                                </FormItem>
                                <FormItem>
                                    {getFieldDecorator("password", {
                                        rules: [
                                            {
                                                required: true,
                                                message:
                                                    "请输入您的密码!",
                                            },
                                        ],
                                    })(
                                        <Input
                                            prefix={
                                                <Icon
                                                    type="lock"
                                                    style={{
                                                        color:
                                                            "rgba(0,0,0,.25)",
                                                    }}
                                                />
                                            }
                                            type="password"
                                            placeholder="密码"
                                        />
                                    )}
                                </FormItem>
                                <FormItem>
                                    {getFieldDecorator("remember", {
                                        valuePropName: "checked",
                                        initialValue: true,
                                    })(
                                        <Checkbox className="hidden">
                                            Remember me
                                        </Checkbox>
                                    )}
                                    <a
                                        className="hidden login-form-forgot"
                                        href="/"
                                    >
                                        Forgot password
                                    </a>
                                    <Button
                                        type="primary"
                                        htmlType="submit"
                                        className="login-form-button"
                                        disabled={this.props.inProgress}
                                    >
                                        登入
                                    </Button>
                                </FormItem>
                            </Form>
                        </Col>
                    </Row>
                </div>
            </div>
        );
    }
}

const LoginForm = Form.create()(Login);
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(LoginForm);
