import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Form, Input, Modal } from "antd";
import helper from "utils/helper";
import ListErrors from "components/ListErrors";
import {Editor} from 'react-draft-wysiwyg';
import {EditorState,convertToRaw,ContentState} from 'draft-js';
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
//const { TextArea } = Input;
import './NewsCss.less';
import { HtmlUtil } from "dispatch";

const mapStateToProps = state => ({
    errors: state.home.errors,
});

const mapDispatchToProps = dispatch => ({});

class NewsModal extends React.Component {
    constructor(props){
        super(props);
        this.state={
            editorState:EditorState.createEmpty(),
            editorSta:EditorState.createEmpty(),
        }
    }
    static propTypes = {
        visible: PropTypes.bool,
        modalData: PropTypes.object,
        onModalOk: PropTypes.func,
        onModalCancel: PropTypes.func,
    };
    componentDidMount() {
    }
    onEditorStateChange = editorState =>{
        const {modalData}=this.props;
        if (modalData) {
            this.setState({
                editorState,
            })
        }else{
            this.setState({
                editorSta:editorState,
            })
        }
        
    }
    UNSAFE_componentWillReceiveProps(nextProps) {
        if (this.props.modalData!==nextProps.modalData && nextProps.modalData&&nextProps.modalData.news_content) {
            const contentBlock = htmlToDraft(HtmlUtil.htmlDecode(nextProps.modalData.news_content));
            if (contentBlock) {
                const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
                const editorState = EditorState.createWithContent(contentState);
                this.setState({ editorState })
            }
        }
    }
    handleSubmit = e => {
        e.preventDefault();
    };
    handlePartnerModalOk = e => {
        const { modalData: data, form, onModalOk, onModalCancel } = this.props;
        const modalData = data || {};
        form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                const send = {};
                const sendKeys = ["title", "creator","newsTime","newsContent","weight"];
                // values["newsContent"]=JSON.stringify(values.newsContent);
                console.info(modalData);
                if (data) {
                    values["newsContent"] = draftToHtml(convertToRaw(this.state.editorState.getCurrentContent()))
                }else{
                    values["newsContent"] = draftToHtml(convertToRaw(this.state.editorSta.getCurrentContent()))
                }
                for (const key of sendKeys) {
                    send[key] = values[key];
                }
                if (Object.keys(send).length) {
                    onModalOk({ id: modalData.id, ...send });
                } else {
                    onModalCancel();
                }
            }
        });
    };
    imageUploadCallBack = file => new Promise(
        (resolve, reject) => {
            let reader = new FileReader();
            reader.readAsDataURL(file);
            let img = new Image();
            // let url = ''
            reader.onload = function (e) {
                img.src = this.result
            };

            img.onload = function () {
                //console.log(img); // 获取图片
                // console.log(img.src.length)
                // 缩放图片需要的canvas（也可以在DOM中直接定义canvas标签，这样就能把压缩完的图片不转base64也能直接显示出来）
                let canvas = document.createElement('canvas');
                let context = canvas.getContext('2d');

                // 图片原始尺寸
                let originWidth = this.width;
                let originHeight = this.height;

                // 最大尺寸限制，可通过设置宽高来实现图片压缩程度
                let maxWidth = 10000,
                    maxHeight = 10000;
                // 目标尺寸
                let targetWidth = originWidth,
                    targetHeight = originHeight;
                // 图片尺寸超过300x300的限制
                if(originWidth > maxWidth || originHeight > maxHeight) {
                    if(originWidth / originHeight > maxWidth / maxHeight) {
                        // 更宽，按照宽度限定尺寸
                        targetWidth = maxWidth;
                        targetHeight = Math.round(maxWidth * (originHeight / originWidth));
                    } else {
                        targetHeight = maxHeight;
                        targetWidth = Math.round(maxHeight * (originWidth / originHeight));
                    }
                }
                // canvas对图片进行缩放
                canvas.width = targetWidth;
                canvas.height = targetHeight;
                // 清除画布
                context.clearRect(0, 0, targetWidth, targetHeight);
                // 图片压缩
                context.drawImage(img, 0, 0, targetWidth, targetHeight);
                /*第一个参数是创建的img对象；第二三个参数是左上角坐标，后面两个是画布区域宽高*/

                //压缩后的图片转base64 url
                /*canvas.toDataURL(mimeType, qualityArgument),mimeType 默认值是'image/png';
                  * qualityArgument表示导出的图片质量，只有导出为jpeg和webp格式的时候此参数才有效，默认值是0.92*/
                let newUrl = canvas.toDataURL('image/jpeg', 0.92);//base64 格式

                resolve({
                    data: {
                        link: newUrl
                    }
                })
            }
        }
    );
    handleCancel= e => {
        const { onModalCancel } = this.props; 
        this.setState({
            editorSta:EditorState.createEmpty(),
        })
        onModalCancel();
    }
    render() {
        const {
            visible,
            confirmLoading,
            modalData,
        } = this.props;
        if (!visible) {
            return null;
        }
        const data = modalData || {};
        const { getFieldDecorator } = this.props.form;
        const formItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 4 },
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 16 },
            },
        };
        const title = data.id ? `编辑: ${data.id}` : `添加`;
        const no =false;
        return (
            <Modal
                title={title}
                centered
                destroyOnClose
                confirmLoading={confirmLoading}
                visible={visible}
                onOk={this.handlePartnerModalOk}
                onCancel={this.handleCancel}
                maskClosable={no}
            >
                <ListErrors errors={this.props.errors} />

                <Form onSubmit={this.handleSubmit}>
                    <Form.Item {...formItemLayout} label="标题">
                        {getFieldDecorator("title", {
                            rules: [
                                {
                                    required: true,
                                    message: "请输入新闻标题!",
                                },
                            ],
                            initialValue: data.title,
                        })(<Input />)}
                    </Form.Item>
                    <Form.Item {...formItemLayout} label="来源">
                        {getFieldDecorator("creator", {
                            rules: [
                                {
                                    required: true,
                                    message: "请输入新闻来源!",
                                },
                            ],
                            initialValue: data.creator,
                        })(<Input />)}
                    </Form.Item>
                    <Form.Item {...formItemLayout} label="排序">
                        {getFieldDecorator("weight", {
                            rules: [
                                {
                                    required: true,
                                    message: "排序",
                                },
                            ],
                            initialValue: data.weight,
                        })(<Input />)}
                    </Form.Item>
                    <Form.Item {...formItemLayout} label="发布时间">
                        {getFieldDecorator("newsTime", {
                            rules: [
                                {
                                    required: true,
                                    message: "请输入新闻发布时间!",
                                },
                            ],
                            initialValue:  helper.showDate(data.news_time),
                        })(<Input />)}
                    </Form.Item>
                    <Form.Item {...formItemLayout} label="新闻内容">
                        {getFieldDecorator("newsContent", {
                            rules: [
                                {
                                    required: true,
                                    message: "请输入新闻内容!",
                                },
                            ],
                            initialValue: data.news_content,
                        })(<Editor 
                            editorState={data.id?this.state.editorState:this.state.editorSta}
                            wrapperClassName="contract-template-add-wrapper" 
                            editorClassName="contract-template-editor" 
                            onEditorStateChange={this.onEditorStateChange}
                            toolbar={{ //Editor富文本组件功能控制
                                history: { inDropdown: true },
                                inline: { inDropdown: false },
                                list: { inDropdown: true },
                                textAlign: { inDropdown: true },
                                image: {
                                    urlEnabled: true,
                                    uploadEnabled: true,
                                    alignmentEnabled: true,   // 是否显示排列按钮 相当于text-align
                                    uploadCallback: this.imageUploadCallBack,  //图片的处理 （但是仅限于本地上传的，url方式不经过此函数）
                                    previewImage: true,
                                    inputAccept: 'image/*',
                                    alt: {present: false, mandatory: false}
                                }
                            }} />
                        )}
                    </Form.Item>
                </Form>
            </Modal>
        );
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Form.create({ name: "NewsModal" })(NewsModal));
