import React from "react";
import { Table, Select } from "antd";
import agent from "agent";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
    USER_PAGE_UNLOADED,
    USER_PAGE_LOADED,
    USER_CUSTOMIZED_UPDATE
} from "constants/actionTypes";
import ReLogin from "components/ReLogin";
const Option = Select.Option;
const columns = [
    {
        title: "Id",
        dataIndex: "id",
        sorter: false,
        defaultSortOrder: "descend",
    },
    {
        title: "订单号",
        key: "order_id",
        render: (avatar, record) => {
            return (
                <span>
                    {record.order_id}
                </span>
            );
        },
    },
    {
        title: "用户账号",
        key: "user_id",
        render: (avatar, record) => {
            return (
                <span>
                    {record.user.email ? record.user.email : record.user.phone}
                </span>
            );
        },
    },
    {
        title: "用户名",
        key: "user",
        render: (avatar, record) => {
            return (
                <span>
                    {record.user.nickname}
                </span>
            );
        },
    },
    {
        title: "数量",
        key: "music_number",
        render: (avatar, record) => {
            return (
                <span>
                    {record.music_number}
                </span>
            );
        },
    },
    {
        title: "金额(¥)",
        key: "total",
        render: (avatar, record) => {
            return (
                <span>
                    {record.total / 100}
                </span>
            );
        },
    },
    {
        title: "订单状态",
        render: (status, record) => {
            let sta = "未支付";
            if (record.status == "1") {
                sta = "已支付";
            }
            return (
                <span>{sta} </span>
            );
        },
    },
    {
        title: "操作状态",
        dataIndex: "operation",
        render: (operation, record) => {
            return (
                <Select
                    defaultValue={operation.toString()}
                    style={{ width: 120 }}
                    onChange={record.handleStatusChange}
                    loading={record.loading}
                    disabled={record.loading}
                >
                    <Option value="1">已操作</Option>
                    <Option value="0">未操作</Option>
                </Select>
            );
        },
    },
];

const mapStateToProps = state => ({
    ...state.user,
});

const mapDispatchToProps = dispatch => ({
    onLoad: (pager, payload) =>
        dispatch({ type: USER_PAGE_LOADED, pager, payload }),
    onUnload: () => dispatch({ type: USER_PAGE_UNLOADED }),
    onUpdateStatus: send =>
        dispatch({
            type: USER_CUSTOMIZED_UPDATE,
            send,
            payload: agent.Customized.put(send),
        }),
});

class List extends React.Component {
    static propTypes = {
        pagination: PropTypes.object.isRequired,
        results: PropTypes.object.isRequired,
        loading: PropTypes.bool.isRequired,
        onLoad: PropTypes.func.isRequired,
        onUnload: PropTypes.func.isRequired,
        fetchError: PropTypes.bool.isRequired,
        onUpdateStatus: PropTypes.func.isRequired,
    };

    componentDidMount() {
        this.onLoad();
    }
    onLoad = () => {
        const { onLoad, pagination } = this.props;
        const pager = { ...pagination };
        pager.current = 1;
        const params = {
            page: pager.current,
            size: pagination.pageSize,
            orderby: "-id",
        };
        onLoad(pager, agent.Customized.get(params));
    };

    handleTableChange = (pagination, filters, sorter) => {
        const pager = { ...this.props.pagination };
        pager.current = pagination.current;
        let orderby = "-id";
        if (sorter && sorter.field) {
            orderby = sorter.field;
            if (sorter.order === "descend") {
                orderby = "-" + sorter.field;
            }
        }

        const params = {
            page: pager.current,
            size: pagination.pageSize,
            orderby,
            ...filters,
        };

        this.props.onLoad(pager, agent.Customized.get(params));
    };


    render() {
        const {
            results,
            pagination,
            loading,
            history,
            fetchError,
            onUpdateStatus,
        } = this.props;
        if (fetchError) {
            return <ReLogin history={history} />;
        }
        pagination.total = results.total;
        const data = results.users;
        for (const record of data) {
            // record.loading = true;
            record.handleStatusChange = value => {
                const send = {
                    id: record.id,
                    operation: value,
                };
                onUpdateStatus(send);
            };
        }
        return (
            <div>
                <Table
                    columns={columns}
                    rowKey={record => record.userid}
                    dataSource={data}
                    pagination={pagination}
                    loading={loading}
                    onChange={this.handleTableChange}
                />
            </div>
        );
    }
}
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(List);
