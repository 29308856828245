export const producerStatus = {
    NO: 0,
    APPLY: 1,
    YES: 9,
    isProducer: status => {
        return status == producerStatus.YES;
    },
    show: status => {
        if (producerStatus.YES == status) {
            return "YES";
        }
        if (producerStatus.APPLY == status) {
            return "APPLY";
        }
        return "NO";
    },
};
