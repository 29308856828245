import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Avatar, Modal, Button, Select } from "antd";
import Loading from "components/Loading";

import agent from "agent";

import ListErrors from "components/ListErrors";
//import Download from "components/common/Download";
import helper from "utils/helper";
import {
    hideAudioDetailModal,
    onUpdateAudioStatus,
    onDeleteSusumeAudio,
    onAddSusumeAudio,
    onReplacePlaylistAudio,
    onOnePlaylistPageLoad,
    onSusumePageLoad,
} from "dispatch";
import "./Audio.less";
const Option = Select.Option;

const mapStateToProps = state => ({
    ...state.audioDetail,
    errors: state.home.errors,
    playlistInfo: state.app.playlistInfo,
    audioCondition: state.app.audioCondition,
    pathname: state.router.location.pathname,
});

const mapDispatchToProps = dispatch => ({});

class AudioDetailModal extends React.Component {
    static propTypes = {
        errors: PropTypes.array,
        visible: PropTypes.bool,
        loading: PropTypes.bool,
        modalData: PropTypes.object,
        playlistInfo: PropTypes.object,
        audioCondition: PropTypes.object,
        pathname: PropTypes.string.isRequired,
    };

    handleSubmit = e => {
        e.preventDefault();
    };
    handleAudioDetailModalOk = e => {
        hideAudioDetailModal();
    };

    getLastKey = key => {
        return key.split("/").pop();
    };
    setPreKey = (key, pre = "") => {
        if (!pre) {
            return key;
        }
        return pre + "/" + key;
    };
    showTitle = key => {
        const data = {
            speed: "速度", //速度
            area: "地区", //地区
            vocal: "人声", //人声
            "inverted_condition/style": "风格", //风格
            "inverted_condition/emotion": "情绪", //情绪
            "inverted_condition/instrument": "乐器", //乐器
            "inverted_condition/scene": "场景", //场景
            tag: "自定义标签1", //自定义
            tag_two: "自定义标签2", //自定义
            inverted_condition: "条件",
            composer: "作曲人",
            creator: "创作人",
            "author/company_name": "公司名",
            "author/company_number": "公司营业号",
            inverted_duration: "长度条件",
            _audioInfo: "附加信息",
            "_audioInfo/audition": "试听版下载次数",
            "_audioInfo/original": "原始版下载次数",
            _susume: "推荐",
            _playlist: "所属分类",
            title: "音乐名称",
            duration: "音乐长度",
            status: "状态",
            update_at: "修改时间",
            create_at: "创建时间",
            author: "上传者",
            scope: "授权区域",
            description: "音乐描述",
            audio: "音乐文件",
            thumb: "音乐封面",
            id: "音乐ID",
            audioid: "音乐加密",
            examine_time:"审核时间",

        };
        if (data[key]) {
            return data[key];
        }
        return this.getLastKey(key);
    };
    showAvatar = val => {
        return (
            <Avatar
                key={val}
                size={128}
                shape="square"
                icon="picture"
                src={agent.getApiUrl(val)}
            />
        );
    };
    showCondition = (key, val) => {
        if (!val) {
            return "";
        }
        const { audioCondition } = this.props;
        if (!audioCondition || !audioCondition[key]) {
            return val;
        }
        const condition = audioCondition[key];
        val = (val + "").split(",").map(elt => {
            if (condition[elt] && condition[elt].title) {
                return condition[elt].title;
            }
            return "-";
        });

        val = val.join(",");
        return val;
    };
    handleStatusChange = value => {
        const { modalData } = this.props;
        const data = modalData || {};
        const audio = data.audio || {};
        const send = {
            audioid: audio.audioid,
            status: value,
        };
        onUpdateAudioStatus(send);
    };
    showValue = (key, val) => {
        // 时间显示：
        if (key.match(/_at$/)) {
            return helper.showDate(val);
        }
        // 条件显示
        const data = {
            "inverted_condition/style": () => {
                return this.showCondition(this.getLastKey(key), val);
            },
            "inverted_condition/emotion": () => {
                return this.showCondition(this.getLastKey(key), val);
            },
            "inverted_condition/instrument": () => {
                return this.showCondition(this.getLastKey(key), val);
            },
            "inverted_condition/scene": () => {
                return this.showCondition(this.getLastKey(key), val);
            },
            inverted_duration: () => {
                return this.showCondition("duration", val);
            },
            area: () => {
                return this.showCondition(key, val);
            },
            vocal: () => {
                return this.showCondition(key, val);
            },
            speed: () => {
                return this.showCondition(key, val);
            },
            inverted_condition: () => {
                return this.list(val, key);
            },
            author: () => {
                return this.list(val, key);
            },
            _audioInfo: () => {
                return this.list(val, key);
            },
            "author/avatar": () => {
                return this.showAvatar(val);
            },
            thumb: () => {
                return this.showAvatar(val);
            },
            duration: () => {
                return helper.showAudioDurationTime(val);
            },
            examine_time: () => {
                if (!val) {
                    return "-"
                }
                return val;
            },
            // status: () => {
            //     const { loading } = this.props;
            //     const disabled = loading;
            //     return (
            //         <Select
            //             defaultValue={val}
            //             style={{ width: 120 }}
            //             onChange={this.handleStatusChange}
            //             loading={loading}
            //             disabled={disabled}
            //         >
            //             <Option value="normal">Normal</Option>
            //             <Option value="reject">Reject</Option>
            //             <Option value="deleted">Deleted</Option>
            //             <Option value="unreviewed">Unreview</Option>
            //         </Select>
            //     );
            // },
            composer: () => {
                return val || "";
            },
            creator: () => {
                const { modalData } = this.props;
                const data = modalData || {};
                const audio = data.audio || {};
                const creator = audio._creator || {};
                return creator.nickname || "";
            },
            scope: () => {
                if(val=='1'){
                    return "中国";
                }else if(val=='0'){
                    return "全球";
                }else{
                    return "-";
                }
                    
            },
            _playlist: () => {
                const { playlistInfo } = this.props;
                console.info(playlistInfo)
                if (!playlistInfo) {
                    return [];
                }
                val = val || [];
                const defaultValue = [];
                for (const v of val) {
                    const key = v.playlist_id + "";
                    defaultValue.push(key);
                }
                const children = [];
                for (const key in playlistInfo) {
                    children.push(
                        <Option key={key + ""}>
                            {playlistInfo[key].title}
                        </Option>
                    );
                }
                const { modalData } = this.props;
                const audio = modalData.audio || {};

                const handleChange = value => {
                    if (!value) {
                        return;
                    }
                    value += "";
                    value = value.split(",");
                    for (const v of value) {
                        onReplacePlaylistAudio(
                            {
                                playlist_id: v,
                                audio_id: audio.id,
                            },
                            () => {
                                // flush parent page
                                const { pathname } = this.props;
                                if (
                                    pathname.match(/^\/audio\/playlist\/\d+/i)
                                ) {
                                    // flush playlist page
                                    onOnePlaylistPageLoad();
                                }
                            }
                        );
                    }
                };
                return (
                    <Select
                        mode="multiple"
                        style={{ width: "100%", minWidth: 200 }}
                        placeholder="Please select"
                        defaultValue={defaultValue}
                        onChange={handleChange}
                    >
                        {children}
                    </Select>
                );
            },
            _susume: () => {
                const { modalData } = this.props;
                const audio = modalData.audio || {};
                if (audio.status !== "normal") {
                    return "当前状态不能推荐";
                }
                const flushSusumePage = () => {
                    // flush parent page
                    const { pathname } = this.props;
                    if (pathname.match(/^\/audio\/susume/i)) {
                        // flush susume page
                        onSusumePageLoad();
                    }
                };
                if (val) {
                    // delete button
                    return (
                        <Button
                            onClick={() => {
                                onDeleteSusumeAudio(val, flushSusumePage);
                            }}
                            type="danger"
                            size="small"
                        >
                            删除推荐
                        </Button>
                    );
                }
                return (
                    <Button
                        type="primary"
                        onClick={() => {
                            onAddSusumeAudio(
                                { audio_id: audio.id },
                                flushSusumePage
                            );
                        }}
                        size="small"
                    >
                        推荐
                    </Button>
                );
            },
        };
        const f = data[key];
        if (f) {
            return f(val);
        }
        if (!["string", "number"].includes(typeof val)) {
            return JSON.stringify(val);
        }
        return val;
    };

    list(data, pre_key = "") {
        const output = [];
        for (let [key, value] of Object.entries(data)) {
            key = this.setPreKey(key, pre_key);
            if (
                [
                    "audio_md5",
                    "original_price",
                    "price",
                    "author/producer",
                    "merged_peaks",
                    "author/company",
                    "author/username",
                    "inverted_speed",
                    "_creator",
                    "_audioInfo/id",
                ].includes(key)
            ) {
                continue;
            }
            const val = this.showValue(key, value);
            const title = this.showTitle(key);

            output.push(
                <div className="item-row" key={key}>
                    <div className="item-key">{title}: </div>
                    <div className="item-val">{val}</div>
                </div>
            );
        }
        return output;
    }

    render() {
        const { visible, modalData, loading} = this.props;
        console.info(this.props)
        if (!visible) {
            return null;
        }
        const data = modalData || {};

        const audio = data.audio || {};
        const tltle = `音乐详细: ${audio.title || ""}`;
        const no=false;
        return (
            <div>
                <Modal
                    title={tltle}
                    centered
                    destroyOnClose
                    visible={visible}
                    onOk={this.handleAudioDetailModalOk}
                    onCancel={hideAudioDetailModal}
                    maskClosable={no}
                    footer={[
                        <Button key="back" onClick={hideAudioDetailModal}>
                            关闭
                        </Button>,
                    ]}
                >
                    <div className="audio-detail-modal">
                        <Loading loading={loading} />
                        <ListErrors errors={this.props.errors} />
                        {this.list(audio)}
                    </div>
                </Modal>
            </div>
        );
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AudioDetailModal);
