import React from "react";
import { Table,Layout,Button, Input,Popconfirm } from "antd";
import agent from "agent"; 
import { connect } from "react-redux"; 
const { Content } = Layout;
var phone="";
var email="";
const onPhoneChange=(v)=>{phone=v.target.value};
const onEmailChange=(v)=>{email=v.target.value};
//import PropTypes from "prop-types";
const columns = [
    {
        title: "ID",
        dataIndex: "id", 
        key: "id", 
    },     
    {
        title: "邮箱",
        dataIndex: "email",  /*1.在目前的页面基础上，将用于搜索的“客户名称”改为“卖家账号”，“公司名”改为“备注”
        2.去除“用户状态”、“昵称” */
    },
    {
        title: "电话",
        dataIndex: "phone", 
    },
     
    
    {
        title: "备注",
        dataIndex: "company_name", 
    }, 
    {
        title: "操作",
        key: "isdiscount",
        render: (record) => {  
            return (
                <div>
                    <Popconfirm title="确认此用户是否打折/不打折" onConfirm={()=>{UpdateCandiscount(record)}} okText="确定" 
                        placement="bottom" onCancel="Cancle" icon={true}>
                        <a href="#">{Candiscount(record.isdiscount)}</a>
                    </Popconfirm>             
                </div>
            );
        },
    },
];
const mapStateToProps = state => ({});
const mapDispatchToProps = dispatch => ({}); 
const devStyle = {marginBottom: "20px",}
const spanStyle = {fontSize: "16px",color: "#666"} 
const inputStyles = {
    marginRight: "20px",
    marginLeft: "5px",
    width: "320px"
}

function Candiscount(isdiscount)
{ 
    if(isdiscount.data==1) 
    {return "不打折" }
    else     
        return "打折"
}
function UpdateCandiscount(record)
{     
    let candiscount=record.isdiscount.data[0];
    if(record.isdiscount.data[0]==0)
        candiscount=1
    else
        candiscount=0;
    agent.SpecialSeller.canDiscountUser({id: record.id,candiscount: candiscount}); 
    reload();
}

function reload(){
    location.reload();
}

  
class List extends React.Component
{ 
    constructor(props) {
        super(props);
        this.state = {
            pageNum:1,  /*翻页查询*/
            pageSize:10,  /*分页查询*/
            activePage: 1,  /*默认显示一页*/
            selectedRowKeys: [],  // 这里配置默认勾选列
            loading: false,  /*antd*/
            selectedRow:[],
            data:[]
        };
     
        agent.SpecialSeller.getUserList({startpage:1,size:100},(retdata)=>{ 
            this.setState({data:retdata.result}); 
        }); 
    } 
    
    searchUsers=()=>
    { 
        agent.SpecialSeller.searchUsers({phone:phone,email:email},(retdata)=>{
            this.setState({data:retdata.result}); 
        }); 
    }
    
    render(){   
        return (
            <Content>
                <div style={devStyle}>
                    <span style={spanStyle}>手机号 :</span>
                    <Input 
                        id="phone" 
                        size="large" 
                        placeholder="手机号" 
                        onChange={onPhoneChange}
                        style={inputStyles} />
                    <span style={spanStyle}>卖家账号:</span>
                    <Input 
                        id="email" 
                        size="large" 
                        placeholder="卖家账号" 
                        onChange={onEmailChange}
                        style={inputStyles} />
                    <Button
                        onClick={this.searchUsers}
                        type="primary"
                        style={{ marginBottom: 16 }}
                    >搜索</Button>
                </div>
                <Table
                    className="c382-custdata-list"
                    columns={columns}
                    dataSource={this.state.data}
                /> 
            </Content>
        );
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(List); 