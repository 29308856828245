import React from "react";
import { Table,Button,Layout,DatePicker } from "antd";
import agent from "agent";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import moment from "moment";
import {
    WITHDRAW_PAGE_UNLOADED,
    WITHDRAW_PAGE_LOADED,
    WITHDRAW_STATUS_UPDATE,
    WITHDRAW_PAGE,
} from "constants/actionTypes";

import ReLogin from "components/ReLogin";
import columnsCreate from "./columns";
import ExportJsonExcel from 'js-export-excel';
const columns = columnsCreate();
const { Content } = Layout;
const {RangePicker } = DatePicker;
const mapStateToProps = state => ({
    ...state.withdraw,
    exports: state.withdraw.exports,
});

const mapDispatchToProps = dispatch => ({
    onLoad: (pager, payload) =>
        dispatch({ type: WITHDRAW_PAGE_LOADED, pager, payload }),
    onUnload: () => dispatch({ type: WITHDRAW_PAGE_UNLOADED }),
    onUpdateStatus: send =>
        dispatch({
            type: WITHDRAW_STATUS_UPDATE,
            send,
            payload: agent.Withdraw.putStatus(send),
        }),
    onExports: params =>
        dispatch({
            type: WITHDRAW_PAGE,
            params,
            payload: agent.Withdraw.out(params),
        }),
});

class Withdraw extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            keyword: "",
            begin:"",
            end:"",
            sta:"",
            type:0,
        };
    }
    static propTypes = {
        pagination: PropTypes.object.isRequired,
        results: PropTypes.object.isRequired,
        loading: PropTypes.bool.isRequired,
        onLoad: PropTypes.func.isRequired,
        onExports: PropTypes.func.isRequired,
        onUnload: PropTypes.func.isRequired,
        fetchError: PropTypes.bool.isRequired,
        onUpdateStatus: PropTypes.func.isRequired,
        exports: PropTypes.string,
        updateStatus: PropTypes.shape({
            id: PropTypes.number.isRequired,
            paid: PropTypes.string.isRequired,
            loading: PropTypes.bool.isRequired,
        }).isRequired,
    };
    componentDidUpdate(prevProps) {
        console.log(prevProps.exports)
        console.log(this.props.exports)
        console.log(this.state.type)
        console.log(window.type)
        console.log(this.props.exports.length)
        console.log(this.props.exports!=null&&this.props.exports.length>0&&this.state.type==0)
        console.log(this.props.exports!=null&&this.props.exports.length>0)
        console.log(this.props.exports!=null)
        let data = this.props.exports
        // if(data!=null&&data.length>0&&this.state.type===0){
        if(this.props.exports!=prevProps.exports){
            console.log(222)
            this.downloadExcel(data)
            return
        }  
        // }
    }
    downloadExcel(order){
        console.log(111)
        console.log(order)
        const data = order ? order : '';//表格数据
        let date = new Date();
        const tradeDate = moment(date).format("YYYY.MM.DD HH:mm:ss");
        var option={};
        let dataTable = [
            {one:'序号',two:'分销商ID',three:'用户ID',four:'订单ID',five:'订单日期',six:'歌曲名称',seven:'创作人',eight:'授权信息',nine:'销售金额',ten:'分成金额'},
        ];
        if (data) {
            for (let i in data) {
                if(data){
                    const id=parseInt(i)+1
                    let obj = {
                        '序号': id,
                        '分销商ID': data[i].inviter,
                        '用户ID': data[i].id,
                        '订单ID': data[i].orderid,
                        '订单日期': moment(data[i].create_at).format("YYYY.MM.DD HH:mm:ss"),
                        '歌曲名称': data[i].title,
                        '创作人': data[i].nickname,
                        '授权信息': "",
                        '销售金额': data[i].price/100+"元",
                        '分成金额': data[i].price/100*0.04+"元",
                        
                        //'买家': data[i].attachment?HtmlUtil.htmlDecode(JSON.parse(data[i].attachment).username):"",
                    }
                    dataTable.push(obj);
                }
            }
        }
        option.fileName = tradeDate+"_分成数据报表"
        option.datas=[
            {
                sheetData:dataTable,
                sheetName:'sheet',
                columnWidths : [4, 5,5,20,10,10,4,5,5,5],
                //sheetFilter:['ID','音乐名称','创作人','项目名称','交易时间','税前收入','提现时间','提现金额',],
                //sheetHeader:['ID','音乐名称','创作人','项目名称','交易时间','税前收入'],
            }
        ];
        
        var toExcel = new ExportJsonExcel(option); 
        toExcel.saveExcel();   
        this.setState({
            type:1,
        })
        window.type = 1
    }
    componentDidMount() {
        window.type = 0
        const { onLoad, pagination } = this.props;
        const pager = { ...pagination };
        pager.current = 1;
        const params = {
            page: pager.current,
            size: pagination.pageSize,
            orderby: "-id",
        };
        onLoad(pager, agent.Withdraw.get(params));
    }

    componentWillUnmount() {
        this.props.onUnload();
    }

    handleTableChange = (pagination, filters, sorter) => {
        const pager = { ...this.props.pagination };
        pager.current = pagination.current;
        let orderby = "-id";
        this.setState({
            sta:filters,
        })
        if (sorter && sorter.field) {
            orderby = sorter.field;
            if (sorter.order === "descend") {
                orderby = "-" + sorter.field;
            }
        }

        const params = {
            page: pager.current,
            size: pagination.pageSize,
            orderby,
            ...filters,
        };

        this.props.onLoad(pager, agent.Withdraw.get(params));
    };
    onChange(date, dateString) {
        this.setState({ begin:dateString[0] });
        this.setState({ end:dateString[1] });
    }
    handleKeywordChange = e => {
        this.setState({ keyword: e.target.value });
    };
    handlSearchWithdraw = e =>{
        const {pagination,onLoad } = this.props;
        const pager = { ...pagination };
        pager.current = 1;
        const key=this.state.keyword;
        const begin=this.state.begin;
        const end=this.state.end;
        const filter=this.state.sta;
        const params = {
            page: pager.current,
            size: pagination.pageSize,
            orderby: "-id",
            keyword:key,
            begin:begin,
            end:end,
            ...filter,
        };
        console.info(params)
        onLoad(pager, agent.Withdraw.get(params));
    }
    handlSearchWithdraws = e =>{
        const {onExports } = this.props;
        const key=this.state.keyword;
        const begin=this.state.begin;
        const end=this.state.end;
        const params = {
            keyword:key,
            begin:begin,
            end:end,
        };
        console.info(params)
        onExports(params);
        this.setState({
            type:0,
        })
        // const {exports} = this.props;
        // if(exports!=null&&exports.length>0){
        //     this.downloadExcel(exports)
        // }
    }
    render() {
        const {
            results,
            pagination,
            loading,
            history,
            fetchError,
            updateStatus,
            onUpdateStatus,
        } = this.props;
        if (fetchError) {
            return <ReLogin history={history} />;
        }
        pagination.total = results.total;
        const data = results.withdraw || [];
        for (const record of data) {
            if (updateStatus.id === record.id) {
                record.loading = updateStatus.loading;
                record.paid = updateStatus.paid;
            }

            record.handleStatusChange = value => {
                const send = {
                    id: record.id,
                    payid: record.payid,
                    paid: value,
                    userid: record.author.userid,
                };
                onUpdateStatus(send);
            };
        }
        const keyword=this.state.keyword;
        return (
            <Content>
                <span>用户名</span>
                <input
                    type="text"
                    name=""
                    value={keyword}
                    onChange={this.handleKeywordChange}
                    className="searchSkin"
                    placeholder="邮箱/手机号"
                />
                <span>日期范围:</span>
                <RangePicker style={{marginLeft:"20px"}} onChange={this.onChange.bind(this)} />
                <Button
                    type="primary"
                    onClick={this.handlSearchWithdraw}
                    style={{ marginBottom: 16,marginLeft:"20px" }}
                >
                    搜索
                </Button>
                {/* <Button
                    type="primary"
                    onClick={this.handlSearchWithdraws}
                    style={{ marginBottom: 16,marginLeft:"20px" }}
                >
                    导出Excel
                </Button> */}
                <Table
                    className="c382-withdraw-list"
                    columns={columns}
                    rowKey={record => record.id}
                    dataSource={data}
                    pagination={pagination}
                    loading={loading}
                    onChange={this.handleTableChange}
                />
            </Content>
        );
    }
}
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Withdraw);
