import {
    USER_PAGE_LOADED,
    USER_PAGE_UNLOADED,
    ASYNC_START,
    USER_STATUS_UPDATE,
    USER_EDIT,
    USER_EDIT_CANCEL,
    USER_EDIT_CANCELS,
    USER_QRCODE,
    USER_EDIT_OK,
    USER_CRATE,
    USUR_DERIVE_FALSE,
    USUR_DERIVE,
} from "../constants/actionTypes";

const defaultState = {
    results: { users: [] },
    pagination: { pageSize: 10, current: 1 },
    loading: false,
    fetchError: false,
    updateStatus: { userid: "", status: "", loading: false },
    userEdit: {},
    userEditModal: false,
    userDerive:false,
    confirmLoading: false,
};
export default (state = { ...defaultState }, action) => {
    switch (action.type) {
        case USER_PAGE_LOADED: {
            const pagination = action.pager;
            if (!action.error) {
                pagination.total = action.payload.total;
            }
            return {
                ...state,
                fetchError: !!action.error,
                results: action.payload,
                pagination,
                loading: false,
            };
        }
        case USUR_DERIVE: {
            return {
                ...state,
                userDerive:true,
            };
        }
        case USUR_DERIVE_FALSE: {
            return {
                ...state,
                userDerive:false,
            };
        }
        case USER_EDIT: {
            const userEdit = { ...action.payload };

            return { ...state, userEdit, userEditModal: true };
        }
        case USER_QRCODE: {
            const userEdit = { ...action.payload };

            return { ...state, userEdit, userEditModals: true };
        }
        case USER_CRATE: {
            const userEdit = {};

            return { ...state, userEdit, userEditModal: true };
        }
        case USER_EDIT_CANCEL: {
            return {
                ...state,
                userEditModal: false,
                userEdit: {},
                errors: null,
            };
        }
        case USER_EDIT_CANCELS: {
            return {
                ...state,
                userEditModals: false,
                userEdit: {},
                errors: null,
            };
        }
        case USER_EDIT_OK: {
            const fetchError = !!action.error;
            if (fetchError) {
                return {
                    ...state,
                    confirmLoading: false,
                    errors: action.payload.errors,
                };
            }
            const userEdit = { ...state.userEdit, ...action.payload.user };

            return {
                ...state,
                userEditModal: false,
                confirmLoading: false,
                errors: null,
                userEdit,
            };
        }
        case USER_STATUS_UPDATE: {
            const updateStatus = { ...state.updateStatus };
            updateStatus.userid = action.error ? "" : action.send.userid;
            updateStatus.status = action.send.status;
            updateStatus.loading = false;
            return { ...state, updateStatus };
        }
        case ASYNC_START:
            if (action.subtype === USER_PAGE_LOADED) {
                return { ...state, loading: true };
            }
            if (action.subtype === USER_STATUS_UPDATE) {
                const updateStatus = { ...state.updateStatus };
                updateStatus.userid = action.send.userid;
                updateStatus.status = action.send.status;
                updateStatus.loading = true;
                return { ...state, updateStatus };
            }
            if (action.subtype === USER_EDIT_OK) {
                return { ...state, confirmLoading: true };
            }
            return state;
        case USER_PAGE_UNLOADED:
            return { ...defaultState };
        default:
            return state;
    }
};
