import React from "react";
import { Table, Button, Modal } from "antd";
import agent from "agent";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
    PAGE_LIST_LOADED,
    PAGE_LIST_UNLOADED,
    PAGE_LIST_EDIT,
    PAGE_LIST_CRATE,
    PAGE_LIST_DELETE,
    PAGE_LIST_MODAL_SHOW,
    PAGE_LIST_MODAL_HIDE,
} from "constants/actionTypes";

import ReLogin from "components/ReLogin";
// import CustomerModal from "./CustomerModal";
import PartnerModal from "./PartnerModal";
import QaModal from "./QaModal";

import columnsCreate from "./list-columns";

const mapStateToProps = state => ({
    ...state.page,
});

const staticParams = {};

const mapDispatchToProps = (dispatch, ownProps) => {
    let pathname = "";
    if (ownProps.location) {
        pathname = ownProps.location.pathname || "";
    }
    pathname = pathname.toLowerCase();
    pathname = pathname.split("/");
    const page = pathname[2] || "contactus";
    const review = pathname[3] === "review";
    const req = agent.Page[page] || agent.Page.contactus;

    const props = {
        pathInfo: () => {
            return { page, review };
        },
        columnsCreate: () => {
            return columnsCreate(page, review);
        },
        onLoad: (pager, params) => {
            if (pager) {
                staticParams.onLoad = { pager, params };
            } else if (staticParams.onLoad) {
                pager = staticParams.onLoad.pager;
                params = staticParams.onLoad.params;
            } else {
                return;
            }
            if (review) {
                params = { ...params, status: "unreviewed" };
            }
            dispatch({
                type: PAGE_LIST_LOADED,
                pager,
                payload: req.get(params),
            });
        },
        onResetLoad: pagination => {
            const pager = { ...pagination };
            pager.current = 1;
            const params = {
                page: pager.current,
                size: pagination.pageSize,
                orderby: "-id",
            };
            props.onLoad(pager, params);
        },
        onUnload: () => dispatch({ type: PAGE_LIST_UNLOADED }),

        onEditList: send =>
            dispatch({
                type: PAGE_LIST_EDIT,
                send,
                payload: req.put(send),
            }),
        onCreateList: (send, callback) =>
            dispatch({
                type: PAGE_LIST_CRATE,
                payload: req.post(send),
                callback,
            }),

        onDeleteList: send =>
            dispatch({
                type: PAGE_LIST_DELETE,
                payload: req.delete(send),
                callback: () => {
                    // load current page
                    props.onLoad();
                },
            }),
        onShowModal: payload =>
            dispatch({
                type: PAGE_LIST_MODAL_SHOW,
                payload,
            }),
        onHideModal: () =>
            dispatch({
                type: PAGE_LIST_MODAL_HIDE,
            }),
    };
    return props;
};

class List extends React.Component {
    static propTypes = {
        pagination: PropTypes.object.isRequired,
        results: PropTypes.object.isRequired,
        loading: PropTypes.bool.isRequired,
        onLoad: PropTypes.func.isRequired,
        onUnload: PropTypes.func.isRequired,
        fetchError: PropTypes.bool.isRequired,
        onEditList: PropTypes.func.isRequired,
        onCreateList: PropTypes.func.isRequired,
    };
    state = {
        pathname: "--",
    };
    static getDerivedStateFromProps(nextProps, prevState) {
        const nextPathname = nextProps.location.pathname;
        const prevPathname = prevState.pathname;
        if (nextPathname != prevPathname) {
            const { onResetLoad, pagination } = nextProps;
            onResetLoad(pagination);
        }
        return { pathname: nextPathname };
    }

    componentWillUnmount() {
        this.props.onUnload();
    }

    handleTableChange = (pagination, filters, sorter) => {
        const pager = { ...this.props.pagination };
        pager.current = pagination.current;
        let orderby = "-weight";
        if (sorter && sorter.field) {
            orderby = sorter.field;
            if (sorter.order === "descend") {
                orderby = "-" + sorter.field;
            }
        }

        const params = {
            page: pager.current,
            size: pagination.pageSize,
            orderby,
            ...filters,
        };

        this.props.onLoad(pager, params);
    };

    handleCustomerModalOk = send => {
        const { onCreateList, onEditList, onHideModal } = this.props;
        if (typeof send.id !== "undefined") {
            // update
            return onEditList(send);
        }
        // create
        const callback = () => {
            const { onResetLoad, pagination } = this.props;
            onResetLoad(pagination);
            onHideModal();
        };
        onCreateList(send, callback.bind(this));
    };

    handleAdd = e => {
        this.props.onShowModal();
    };
    renderAddButton = () => {
        const { page } = this.props.pathInfo();
        if (page === "contactus") {
            return null;
        }
        return (
            <Button
                onClick={this.handleAdd}
                type="primary"
                style={{ marginBottom: 16 }}
            >
                添加
            </Button>
        );
    };

    render() {
        const {
            results,
            pagination,
            loading,
            confirmLoading,
            fetchError,
            onShowModal,
            onHideModal,
            onDeleteList,
            onEditList,
            modal,
        } = this.props;
        if (fetchError) {
            return <ReLogin />;
        }
        pagination.total = results.total;

        const data = results.data || [];

        for (const record of data) {
            // record.loading = true;
            record.handleDeleteClick = () => {
                Modal.confirm({
                    title: "确认删除吗？操作不可恢复！",
                    content: `将要删除： ${record.id}`,
                    okText: "确认删除",
                    okType: "danger",
                    cancelText: "No",
                    okButtonProps: {
                        disabled: confirmLoading,
                    },
                    onOk: () => {
                        onDeleteList(record);
                    },
                });
            };

            record.handleEditClick = e => {
                e.preventDefault();
                e.stopPropagation();
                const send = {
                    ...record,
                };
                onShowModal(send);
            };

            record.handleStatusChange = value => {
                const send = {
                    id: record.id,
                    status: value,
                };
                return onEditList(send);
            };
        }
        console.info(modal)
        const { data: modalData, visible } = modal;
        const modalProps = {
            onModalOk: this.handleCustomerModalOk.bind(this),
            onModalCancel: onHideModal,
            modalData,
            confirmLoading,
            visible: visible,
        };
        const columns = this.props.columnsCreate();
        const { page } = this.props.pathInfo();
        return (
            <div>
                {this.renderAddButton()}
                <Table
                    className="c382-audio-list"
                    columns={columns}
                    rowKey={record => record.id}
                    dataSource={data}
                    pagination={pagination}
                    loading={loading}
                    onChange={this.handleTableChange}
                />
                {page === "partner" && <PartnerModal {...modalProps} />}
                {page === "qa" && <QaModal {...modalProps} />}
            </div>
        );
    }
}
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(List);
