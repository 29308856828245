import auth from "./reducers/auth";
import { combineReducers } from "redux";
import { routerReducer } from "react-router-redux";
import app from "./reducers/app";
import home from "./reducers/home";
import admin from "./reducers/admin";
import audio from "./reducers/audio";
import captcha from "./reducers/captcha";
import user from "./reducers/user";
import producer from "./reducers/producer";
import order from "./reducers/order";
import purchase from "./reducers/purchase";
import dashboard from "./reducers/dashboard";
import withdraw from "./reducers/withdraw";
import audioDetail from "./reducers/audioDetail";
import page from "./reducers/page";
import news from "./reducers/news";
import player from "./reducers/player";
import priceGroup from "./reducers/priceGroup";
import member from "./reducers/member";
import memberset from "./reducers/memberset";
import cooperationer from './reducers/cooperationer'
export default combineReducers({
    auth,
    app,
    home,
    admin,
    audio,
    producer,
    order,
    purchase,
    captcha,
    user,
    dashboard,
    withdraw,
    audioDetail,
    page,
    news,
    player,
    priceGroup,
    router: routerReducer,
    member,
    memberset,
    cooperationer,
});
