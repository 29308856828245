import _columns from "./columns";

const clone = () => {
    const columns = {};
    for (const [key, val] of Object.entries(_columns)) {
        columns[key] = { ...val };
    }
    return columns;
};

export default (page, review = false) => {
    // const columns = _columns;
    const columns = clone(_columns);
    let data = [];
    switch (page) {
        case "contactus": {
            const { id, contactusAction, contactus } = columns;
            if (review) {
                delete id.sorter;
                delete contactusAction.filters;
            }
            data = [id, contactus, contactusAction];
            break;
        }
        case "qa": {
            const { id, qa_info, action } = columns;
            if (review) {
                delete id.sorter;
                delete action.filters;
            }
            data = [id, qa_info, action];
            break;
        }
        case "partner": {
            const { id, partner, action } = columns;
            if (review) {
                delete id.sorter;
                delete action.filters;
            }
            data = [id, partner, action];
            break;
        }
        default:
            data = [columns.id];
    }
    return data;
};
