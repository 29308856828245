import {
    PLAY_AUDIO,
} from "../constants/actionTypes";

export default (state = {}, action) => {
    switch (action.type) {
        case PLAY_AUDIO:
            return {
                ...state,
                audio: action.audio,
            };
        default:
            return state;
    }
};
