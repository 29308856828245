import {
    DASHBOARD_PAGE_LOADED,
    DASHBOARD_PAGE_UNLOADED,
    ASYNC_START,
} from "../constants/actionTypes";

const defaultState = {
    results: {},
    loading: true,
    fetchError: false,
};
export default (state = { ...defaultState }, action) => {
    switch (action.type) {
        case DASHBOARD_PAGE_LOADED: {
            return {
                ...state,
                fetchError: !!action.error,
                results: action.payload,
                loading: false,
            };
        }
        case ASYNC_START:
            if (action.subtype === DASHBOARD_PAGE_LOADED) {
                return { ...state, loading: true };
            }
            return state;
        case DASHBOARD_PAGE_UNLOADED:
            return { ...defaultState };

        default:
            return state;
    }
};
