import agent from "agent";

import {
    LOGOUT,
    PLAYLIST_INFO_APP,
    AUDIO_DETAIL_LOAD,
    AUDIO_DETAIL_SHOW,
    AUDIO_DETAIL_HIDE,
    AUDIO_CONDITION_ALL_APP,
    SUSUME_AUDIO_DELETE,
    AUDIO_STATUS_UPDATE,
    SUSUME_AUDIO_ADD,
    AUDIO_PAGE_LOADED,
    PLAYLIST_AUDIO_DELETE,
    PLAYLIST_AUDIO_REPLACE,
} from "constants/actionTypes";

import { store, history } from "./store";
const dispatch = store.dispatch;

export const redirectTo = (pathname, params = {}) => {
    if (typeof pathname === "string") {
        history.push(pathname);
        return;
    }
    let search = "";
    if (Object.keys(params).length) {
        search = agent.buildURLQuery(params);
    }
    history.push({
        pathname,
        search,
    });
};

// ...
const staticParams = {};
export const logout = () => {
    dispatch({ type: LOGOUT });
};

export const loadPlaylistInfoApp = () => {
    dispatch({
        type: PLAYLIST_INFO_APP,
        payload: agent.Audio.playlistInfo.get({
            page: 1,
            size: 100,
            orderby: "-id",
        }),
    });
};

export const loadConditionAllApp = () => {
    dispatch({
        type: AUDIO_CONDITION_ALL_APP,
        payload: agent.Condition.getAll(),
    });
};

export const loadAudioDetail = audioid => {
    dispatch({
        type: AUDIO_DETAIL_LOAD,
        payload: agent.Audio.get(audioid),
    });
};

export const showAudioDetailModal = audioid => {
    // load data
    loadAudioDetail(audioid);
    // load condition
    loadConditionAllApp();
    // load playlist
    loadPlaylistInfoApp();
    // show
    dispatch({
        type: AUDIO_DETAIL_SHOW,
    });
};

export const hideAudioDetailModal = audioid => {
    dispatch({
        type: AUDIO_DETAIL_HIDE,
    });
};

export const onUpdateAudioStatus = send =>
    dispatch({
        type: AUDIO_STATUS_UPDATE,
        send,
        payload: agent.Audio.putStatus(send),
    });

export const onAddSusumeAudio = (send, callback) =>
    dispatch({
        type: SUSUME_AUDIO_ADD,
        send,
        payload: agent.Audio.susume.post(send),
        callback,
    });

export const onSusumePageLoad = (pager, params) => {
    // check save current page info
    if (pager) {
        staticParams.onSusumePageLoad = { pager, params };
    } else if (staticParams.onSusumePageLoad) {
        pager = staticParams.onSusumePageLoad.pager;
        params = staticParams.onSusumePageLoad.params;
    } else {
        return;
    }
    dispatch({
        type: AUDIO_PAGE_LOADED,
        pager,
        payload: agent.Audio.susume.get(params),
    });
};
export const onOnePlaylistPageLoad = (pager, params) => {
    if (pager) {
        staticParams.onOnePlaylistPageLoad = { pager, params };
    } else if (staticParams.onOnePlaylistPageLoad) {
        pager = staticParams.onOnePlaylistPageLoad.pager;
        params = staticParams.onOnePlaylistPageLoad.params;
    } else {
        return;
    }
    dispatch({
        type: AUDIO_PAGE_LOADED,
        pager,
        payload: agent.Audio.playlist.get(params),
    });
};
export const onDeleteSusumeAudio = (send, callback) =>
    dispatch({
        type: SUSUME_AUDIO_DELETE,
        send,
        payload: agent.Audio.susume.delete(send),
        callback,
    });

export const onDeletePlaylistAudio = (send, callback) =>
    dispatch({
        type: PLAYLIST_AUDIO_DELETE,
        send,
        payload: agent.Audio.playlist.delete(send),
        callback,
    });
export const onReplacePlaylistAudio = (send, callback) =>
    dispatch({
        type: PLAYLIST_AUDIO_REPLACE,
        send,
        payload: agent.Audio.playlist.post(send),
        callback,
    });
export const HtmlUtil = {
    /*1.用浏览器内部转换器实现html转码*/
    htmlEncode:function (html){
        //1.首先动态创建一个容器标签元素，如DIV
        var temp = document.createElement ("div");
        //2.然后将要转换的字符串设置为这个元素的innerText(ie支持)或者textContent(火狐，google支持)
        (temp.textContent != undefined ) ? (temp.textContent = html) : (temp.innerText = html);
        //3.最后返回这个元素的innerHTML，即得到经过HTML编码转换的字符串了
        var output = temp.innerHTML;
        temp = null;
        return output;
    },
    /*2.用浏览器内部转换器实现html解码*/
    htmlDecode:function (text){
        //1.首先动态创建一个容器标签元素，如DIV
        var temp = document.createElement("div");
        //2.然后将要转换的字符串设置为这个元素的innerHTML(ie，火狐，google都支持)
        temp.innerHTML = text;
        //3.最后返回这个元素的innerText(ie支持)或者textContent(火狐，google支持)，即得到经过HTML解码的字符串了。
        var output = temp.innerText || temp.textContent;
        temp = null;
        return output;
    }
};
