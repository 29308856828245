import {
    MEM_BER,
    OPEN_MEMBER,
    MEMBER_DERIVE,
    MEMBER_DERIVE_FALSE,
} from "../constants/actionTypes";

const defaultState = {
    loading: false,
    fetchError: false,
    orderid: '',
    username: '',
    subscribe: [],
    start_time: '',
    end_timeL: '',
    type: '',
    status: '',
    receipt: '',
    orderby: 'id',
    memberDerive: false,
    pagination: { pageSize: 10, current: 1 },
};
export default (state = { ...defaultState }, action) => {
    switch (action.type) {
        case MEM_BER: {
            const pagination = action.pager;
            console.log(action)
            if (!action.error) {
                pagination.total = action.payload.total;
            }
            return {
                ...state,
                fetchError: !!action.error,
                subscribe: action.payload,
                pagination,
                loading: false,
                memberDerive: false,
            };
        }
        case MEMBER_DERIVE: {

            return {
                ...state,
                memberDerive: true,
            };

        }
        case MEMBER_DERIVE_FALSE: {

            return {
                ...state,
                memberDerive: false,
            };

        }
        case OPEN_MEMBER: {
            return {
                ...state,
                fetchError: !!action.error,
                subscribe: state.subscribe,
                loading: false,
            };
        }
        default:
            return state;
    }
};
