import React from "react";
import { Table } from "antd";
import agent from "agent";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
    CAPTCHA_PAGE_UNLOADED,
    CAPTCHA_PAGE_LOADED,
} from "constants/actionTypes";

const columns = [
    {
        title: "Id",
        dataIndex: "id",
        sorter: true,
        defaultSortOrder: "descend",
        width: "20%",
    },
    {
        title: "Key",
        dataIndex: "key",
        width: "20%",
    },
    {
        title: "Captcha",
        dataIndex: "captcha",
    },
];

const mapStateToProps = state => ({
    ...state.captcha,
});

const mapDispatchToProps = dispatch => ({
    onLoad: (pager, payload) =>
        dispatch({ type: CAPTCHA_PAGE_LOADED, pager, payload }),
    onUnload: () => dispatch({ type: CAPTCHA_PAGE_UNLOADED }),
});

class List extends React.Component {
    static propTypes = {
        pagination: PropTypes.object.isRequired,
        results: PropTypes.object.isRequired,
        loading: PropTypes.bool.isRequired,
        onLoad: PropTypes.func.isRequired,
        onUnload: PropTypes.func.isRequired,
    };

    componentDidMount() {
        const { onLoad, pagination } = this.props;
        const pager = { ...pagination };
        pager.current = 1;
        const params = {
            page: pager.current,
            size: pagination.pageSize,
            orderby: "-id",
        };
        onLoad(pager, agent.Captcha.get(params));
    }

    componentWillUnmount() {
        this.props.onUnload();
    }

    handleTableChange = (pagination, filters, sorter) => {
        const pager = { ...this.props.pagination };
        pager.current = pagination.current;
        let orderby = "-id";
        if (sorter && sorter.field) {
            orderby = sorter.field;
            if (sorter.order === "descend") {
                orderby = "-" + sorter.field;
            }
        }

        const params = {
            page: pager.current,
            size: pagination.pageSize,
            orderby,
            ...filters,
        };

        this.props.onLoad(pager, agent.Captcha.get(params));
    };

    render() {
        const { results, pagination, loading } = this.props;
        pagination.total = results.total;
        return (
            <Table
                columns={columns}
                rowKey={record => record.id}
                dataSource={results.captchas}
                pagination={pagination}
                loading={loading}
                onChange={this.handleTableChange}
            />
        );
    }
}
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(List);
