import React from "react";
import { Table, Button, } from "antd";
import agent from "agent";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
    AUDIO_PAGE_UNLOADED,
    AUDIO_PAGE_LOADED,
    AUDIO_STATUS_UPDATE,
    AUDIO_MODAL_HIDE,
    AUDIO_MODAL_SHOW,
    ADMIN_UPDATE_AUDIO,
} from "constants/actionTypes";
import ReLogin from "components/ReLogin";
import "./Audio.less";
import columnsCreate from "./columns";
import nocolumnsCreate from "./nocolumns";
import AudioUpdateModal from "./AudioUpdateModal";
import { loadConditionAllApp } from "dispatch";

let columns = columnsCreate();

const mapStateToProps = state => ({
    ...state.audio,
    ...state.player,
});

const mapDispatchToProps = dispatch => ({
    onLoad: (pager, payload) =>
        dispatch({ type: AUDIO_PAGE_LOADED, pager, payload }),
    onUnload: () => dispatch({ type: AUDIO_PAGE_UNLOADED }),
    onUpdateStatus: send =>
        dispatch({
            type: AUDIO_STATUS_UPDATE,
            send,
            payload: agent.Audio.putStatus(send),
        }),
    onHideModal: () =>
        dispatch({
            type: AUDIO_MODAL_HIDE,
        }),
    onShowModal: payload =>
        dispatch({
            type: AUDIO_MODAL_SHOW,
            payload,
        }),
    updateAudio: (send, callback) => {
        return dispatch({
            type: ADMIN_UPDATE_AUDIO,
            payload: agent.Audio.updateAudio(send),
            callback,
        });
    },
});

class Audio extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            keyword: "",
            audioid: null,
            currentPageNum: 1,
            sta: "",
            // audioPlay: "http://backend-api.musiness.bmi/upload/producer/audio.ZVTT5NFOY9CES5H117A14997B1BE3022.audio.mp3"
        };
        this.wavesurfer = null;
    }
    static propTypes = {
        pagination: PropTypes.object.isRequired,
        results: PropTypes.object.isRequired,
        loading: PropTypes.bool.isRequired,
        onLoad: PropTypes.func.isRequired,
        onUnload: PropTypes.func.isRequired,
        fetchError: PropTypes.bool.isRequired,
        onUpdateStatus: PropTypes.func.isRequired,
        onShowModal: PropTypes.func.isRequired,
        updateStatus: PropTypes.shape({
            audioid: PropTypes.string.isRequired,
            status: PropTypes.string.isRequired,
            loading: PropTypes.bool.isRequired,
        }).isRequired,
        updateAudio: PropTypes.func.isRequired,
        onHideModal: PropTypes.func.isRequired,
        playAudio: PropTypes.func.isRequired,
        audio: PropTypes.object.isRequired,
    };
    static defaultProps = {
        audio: {
            id: 0,
            audioid: "",
            audio: "",
            playing: true,
            merged_peaks: "",

        },
    };
    componentDidMount() {
        const { onLoad, pagination } = this.props;
        agent.Auth.current().then(function(data){  
            const pager = { ...pagination }; 
            let username=data.body.admin.email;
            if(username=="ergeng@music.com"||username=="first@music.com"||
                    username=="longun@music.com")
                columns=nocolumnsCreate(); 
            pager.current = 1;
            const params = {
                page: pager.current,
                size: pagination.pageSize,
                orderby: "-id",
            };
            onLoad(pager, agent.Audio.search(params));
            loadConditionAllApp();
        })
    }

    componentWillUnmount() {
        this.props.onUnload();
    }
    handlSearchAudio = e => {
        this.props.onUnload();
        const { onLoad, pagination } = this.props;
        const pager = { ...pagination };
        pager.current = 1;
        const key = this.state.keyword;
        const params = {
            page: pager.current,
            size: pagination.pageSize,
            orderby: "-id",
            keyword: key,
        };
        onLoad(pager, agent.Audio.search(params));
    }
    handleKeywordChange = e => {
        this.setState({ keyword: e.target.value });
    };
    /**跳页方法 */
    handleTableChange = (pagination, filters, sorter) => {
        const pager = { ...this.props.pagination };
        pager.current = parseInt(pagination.current);
        let orderby = "-id";
        this.setState({
            sta: filters,
        })
        const key = this.state.keyword;
        if (sorter && sorter.field) {
            orderby = sorter.field;
            if (sorter.order === "descend") {
                orderby = "-" + sorter.field;
            }
        }
        const params = {
            page: pager.current,
            size: pagination.pageSize,
            orderby,
            ...filters,
            keyword: key,
        };
        this.props.onLoad(pager, agent.Audio.search(params));
    };
    tryAuditionMusic = send => {
        const { playAudio, audio } = this.props;
        if (audio.audioid == send.audioid) {
            if (send.playing) {
                send.playing = false;
            } else {
                send.playing = true;
            }
        } else {
            send.playing = true;
        }
        playAudio(send);
    }
    handleCustomerModalOk = send => {
        const { updateAudio, onHideModal } = this.props;
        const callback = () => {
            const { onLoad, pagination } = this.props;
            const pager = { ...pagination };
            const key = this.state.keyword;
            const filter = this.state.sta;
            const params = {
                page: pager.current,
                size: pagination.pageSize,
                orderby: "-id",
                ...filter,
                keyword: key,
            };
            onHideModal();
            onLoad(pager, agent.Audio.search(params));
        };
        updateAudio(send, callback.bind(this));
    };
    render() {
        const {
            results,
            pagination,
            loading,
            history,
            fetchError,
            updateStatus,
            onUpdateStatus,
            onHideModal,
            onShowModal,
            modal,
            audio,
        } = this.props;
        if (fetchError) {
            return <ReLogin history={history} />;
        }
        //歌曲总数
        pagination.total = results.total;
        pagination.showQuickJumper = true;
        const { keyword } = this.state;
        const data = results.audios || [];
        for (const record of data) {
            // record.loading = true;
            if (updateStatus.audioid === record.audioid) {
                record.loading = updateStatus.loading;
                record.status = updateStatus.status;
            }

            if (audio.audioid === record.audioid) {
                record.playing = audio.playing;
            } else {
                record.playing = false;
            }
            record.handleEditClick = e => {
                e.preventDefault();
                e.stopPropagation();
                const send = {
                    ...record,
                };
                onShowModal(send);
            };
            record.handleTryClick = e => {
                e.preventDefault();
                e.stopPropagation();
                const send = {
                    ...record,
                };
                window.open(agent.getApiUrl(send.audio))
                // this.tryAuditionMusic(send);

            };
            record.handleDownloadClick = e => {
                e.preventDefault();
                e.stopPropagation();
                const send = {
                    ...record,
                };
                let id = 'audio/download/' + send.id
                window.location.href = agent.getApiUrl(id)

            }
            record.handleStatusChange = value => {
                const send = {
                    audioid: record.audioid,
                    status: value,
                };
                onUpdateStatus(send);
            };
        }
        const { data: modalData, visible } = modal;
        // const audioPlay = agent.getApiUrl(audio.audio)

        const modalProps = {
            onModalOk: this.handleCustomerModalOk.bind(this),
            onModalCancel: onHideModal,
            modalData,
            confirmLoading: false,
            visible,
        };
        return (
            <div>
                <input
                    type="text"
                    name=""
                    value={keyword}
                    className="searchSkin"
                    onChange={this.handleKeywordChange}
                    placeholder="搜索歌曲名"
                />
                <Button
                    onClick={this.handlSearchAudio}
                    type="primary"
                    style={{ marginBottom: 16 }}
                >
                    搜索
                </Button>
                <Table
                    className="c382-audio-list"
                    columns={columns}
                    rowKey={record => record.audioid}
                    dataSource={data}
                    pagination={pagination}
                    loading={loading}
                    onChange={this.handleTableChange}
                />
                {/* 页码输入框 */}
                <AudioUpdateModal {...modalProps} />
                <div id="conid" className="hidden"></div>
                {/* <div> */}
                {/* <audio controls="controls" autoPlay={true}>
                        <source src={audioPlay} />
                        您的浏览器不支持audio
                    </audio> */}
                {/* </div> */}
            </div>
        );
    }
}
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Audio);
