import React from "react";
import agent from "agent";
import { Divider, Button } from "antd";
import { Link } from "react-router-dom";
import ImageZoom from "react-medium-image-zoom";
import helper from "utils/helper";

const columns = [
    {
        title: "Id",
        dataIndex: "id",
        sorter: true,
        defaultSortOrder: "descend",
    },
    {
        title: "Info",
        key: "Info",
        render: record => {
            return (
                <div>
                    <ImageZoom
                        key={record.thumb}
                        image={{
                            src: agent.getApiUrl(record.thumb),
                            className: "thumb-big",
                        }}
                    />
                    <Divider />
                    <span>Title: </span>
                    {record.title}
                    <Divider />
                    <span>Subtitle: </span>
                    {record.subtitle}
                    <Divider />
                    <span>Weight: </span>
                    {record.weight}
                    <Divider />
                    <span>Update at: </span>
                    {helper.showDate(record.update_at)}
                    <Divider />
                </div>
            );
        },
    },
    {
        title: "Description",
        key: "description",
        dataIndex: "description",
        className: "description",
    },

    {
        title: <span>Action</span>,

        render: record => {
            const disabled = record.loading;
            return (
                <div>
                    <div style={{ marginBottom: 20 }}>
                        <Link to={`/audio/playlist/${record.id}`}>
                            音乐列表(
                            {record.audio_count})
                        </Link>
                    </div>
                    <div style={{ marginBottom: 20 }}>
                        <Link to="#" onClick={record.handleEditClick}>
                            分类编辑
                        </Link>
                    </div>
                    <Button
                        type="danger"
                        disabled={disabled}
                        loading={disabled}
                        onClick={record.handleDeleteClick}
                    >
                        删除
                    </Button>
                </div>
            );
        },
    },
];

export default () => {
    const data = [];
    for (const record of columns) {
        data.push({ ...record });
    }
    return data;
};
