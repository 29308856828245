import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Form, Input, Upload, Avatar, Modal } from "antd";

import agent from "agent";

import ListErrors from "components/ListErrors";
import helper from "utils/helper";
const { TextArea } = Input;

const mapStateToProps = state => ({
    errors: state.home.errors,
});

const mapDispatchToProps = dispatch => ({});

class CustomerModal extends React.Component {
    static propTypes = {
        visible: PropTypes.bool,
        modalData: PropTypes.object,
        onModalOk: PropTypes.func,
        onModalCancel: PropTypes.func,
    };
    state = {
        fileList: [],
    };

    static getDerivedStateFromProps(nextProps, prevState) {
        if (!nextProps.visible) {
            return { fileList: [] };
        }
        return null;
    }

    handleSubmit = e => {
        e.preventDefault();
    };
    handleCustomerModalOk = e => {
        const { modalData: data, form, onModalOk, onModalCancel } = this.props;
        const modalData = data || {};
        form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                const send = {};
                const sendKeys = ["description", "title", "subtitle","weight", "thumb"];
                for (const key of sendKeys) {
                    if (values[key] != modalData[key]) {
                        send[key] = values[key];
                    }
                }
                // update thumb
                const { fileList } = this.state;
                if (fileList.length) {
                    send.thumb = fileList[0];
                }
                delete modalData.thumb;

                if (Object.keys(send).length) {
                    onModalOk({ ...modalData, ...send });
                } else {
                    onModalCancel();
                }
            }
        });
    };
    handleDeleteAvatarClick = e => {
        e.preventDefault();
        e.stopPropagation();
        this.props.form.setFields({
            thumb: {
                value: "",
            },
        });
    };

    render() {
        const {
            visible,
            confirmLoading,
            modalData,

            onModalCancel,
        } = this.props;
        if (!visible) {
            return null;
        }
        const data = modalData || {};
        const { getFieldDecorator, getFieldValue } = this.props.form;
        const formItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 8 },
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 16 },
            },
        };
        const tltle = data.playlistid
            ? `编辑分类: ${data.playlistid}`
            : `添加分类`;
        const { fileList } = this.state;
        const uploadProps = {
            onRemove: file => {
                this.setState(state => {
                    return {
                        fileList: [],
                    };
                });
                this.props.form.setFields({
                    thumb: {
                        value: data.thumb,
                    },
                });
            },
            beforeUpload: file => {
                this.setState(state => ({
                    fileList: [file],
                }));
                helper.getBase64(file).then(data => {
                    this.props.form.setFields({
                        thumb: {
                            value: data,
                        },
                    });
                });

                return false;
            },
            accept: "image/*",
            listType: "picture",
            fileList,
        };
        return (
            <Modal
                title={tltle}
                centered
                destroyOnClose
                confirmLoading={confirmLoading}
                visible={visible}
                onOk={this.handleCustomerModalOk}
                onCancel={onModalCancel}
            >
                <ListErrors errors={this.props.errors} />

                <Form onSubmit={this.handleSubmit}>
                    <div style={{ textAlign: "center", marginBottom: " 30px" }}>
                        {getFieldDecorator("thumb", {
                            rules: [
                                {
                                    required: false,
                                },
                            ],
                            initialValue: data.thumb,
                        })(<Input type="hidden" />)}
                        <Upload {...uploadProps}>
                            <Avatar
                                key={getFieldValue("thumb")}
                                size={128}
                                shape="square"
                                icon="picture"
                                src={agent.getApiUrl(getFieldValue("thumb"))}
                            />
                        </Upload>
                        {fileList.length == 0 && (
                            <Link
                                style={{ display: "block" }}
                                to="#"
                                onClick={this.handleDeleteAvatarClick}
                            >
                                Delete
                            </Link>
                        )}
                    </div>
                    <Form.Item {...formItemLayout} label="Title">
                        {getFieldDecorator("title", {
                            rules: [
                                {
                                    required: true,
                                    message: "请输入标题!",
                                },
                            ],
                            initialValue: data.title,
                        })(<Input />)}
                    </Form.Item>
                    <Form.Item {...formItemLayout} label="Subtitle">
                        {getFieldDecorator("subtitle", {
                            rules: [
                                {
                                    required: true,
                                    message: "请输入副标题!",
                                },
                            ],
                            initialValue: data.subtitle,
                        })(<Input />)}
                    </Form.Item>
                    <Form.Item {...formItemLayout} label="Weight">
                        {getFieldDecorator("weight", {
                            rules: [
                                {
                                    required: true,
                                    message: "请输入排序!",
                                },
                            ],
                            initialValue: data.weight,
                        })(<Input />)}
                    </Form.Item>
                    <Form.Item {...formItemLayout} label="Description">
                        {getFieldDecorator("description", {
                            rules: [
                                {
                                    required: true,
                                    message: "请输入描述!",
                                },
                            ],
                            initialValue: data.description,
                        })(<TextArea />)}
                    </Form.Item>
                </Form>
            </Modal>
        );
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Form.create({ name: "CustomerModal" })(CustomerModal));
