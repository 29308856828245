import React from "react";
import { Table, Divider, Avatar, Select, Button } from "antd";
import { Link } from "react-router-dom";
import agent from "agent";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
    CURRENT_USER,
    ADMIN_PAGE_UNLOADED,
    ADMIN_PAGE_LOADED,
    ADMIN_STATUS_UPDATE,
    ADMIN_EDIT,
    ADMIN_EDIT_CANCEL,
    ADMIN_EDIT_OK,
    ADMIN_CRATE,
} from "constants/actionTypes";
import Modal from "./Modal";
import ReLogin from "components/ReLogin";

const Option = Select.Option;
const columns = [
    {
        title: "Avatar",
        dataIndex: "avatar",

        render: (avatar, record) => {
            return (
                <div>
                    <Link to="#" onClick={record.handleEditClick}>
                        <Avatar
                            key={avatar}
                            src={agent.getApiUrl(avatar)}
                            size="large"
                            icon="user"
                        />
                    </Link>
                </div>
            );
        },
    },
    {
        title: "Email/Nickname",
        key: "email/nickname",
        render: (avatar, record) => {
            return (
                <div>
                    <span>ID: </span>
                    <span>{record.adminid}</span>
                    <Divider />
                    <span>Name: </span>
                    {record.nickname}
                    <Divider />
                    <span>Email: </span>
                    <a href={`mailto:${record.email}`}>{record.email}</a>
                </div>
            );
        },
    },
    {
        title: "Status",
        dataIndex: "status",
        filters: [
            { text: "Normal", value: "normal" },
            { text: "Blocked", value: "blocked" },
            // { text: "Deleted", value: "deleted" },
        ],
        render: (status, record) => {
            const disabled = record.currentAdmin || record.loading;
            return (
                <Select
                    defaultValue={status}
                    style={{ width: 120 }}
                    onChange={record.handleStatusChange}
                    loading={record.loading}
                    disabled={disabled}
                >
                    <Option value="normal">Normal</Option>
                    <Option value="blocked">Blocked</Option>
                </Select>
            );
        },
    },

    {
        title: "Action",
        key: "action",
        render: (text, record) => (
            <span>
                <Link to="#" onClick={record.handleEditClick}>
                    Edit
                </Link>
            </span>
        ),
    },
];

const mapStateToProps = state => ({
    ...state.admin,
    currentAdmin: state.home.currentAdmin,
});

const mapDispatchToProps = dispatch => ({
    onLoadCurrentUser: payload =>
        dispatch({ type: CURRENT_USER, payload: agent.Auth.current() }),
    onLoad: (pager, payload) =>
        dispatch({ type: ADMIN_PAGE_LOADED, pager, payload }),
    onUnload: () => dispatch({ type: ADMIN_PAGE_UNLOADED }),
    onUpdateStatus: send =>
        dispatch({
            type: ADMIN_STATUS_UPDATE,
            send,
            payload: agent.Admin.put(send),
        }),
    onEditAdmin: payload =>
        dispatch({
            type: ADMIN_EDIT,
            payload,
        }),
    onCreateAdmin: payload =>
        dispatch({
            type: ADMIN_CRATE,
            payload,
        }),
    onAdminModalOk: (send, callback) => {
        if (send.adminid) {
            return dispatch({
                type: ADMIN_EDIT_OK,
                send,
                payload: agent.Admin.put(send),
                callback,
            });
        }
        return dispatch({
            type: ADMIN_EDIT_OK,
            send,
            payload: agent.Admin.post(send),
            callback,
        });
    },
    onAdminModalCancel: payload =>
        dispatch({
            type: ADMIN_EDIT_CANCEL,
            payload,
        }),
});

class List extends React.Component {
    static propTypes = {
        pagination: PropTypes.object.isRequired,
        results: PropTypes.object.isRequired,
        loading: PropTypes.bool.isRequired,
        onLoad: PropTypes.func.isRequired,
        onUnload: PropTypes.func.isRequired,
    };

    componentDidMount() {
        this.onLoad();
    }

    onLoad = () => {
        const { onLoad, pagination } = this.props;
        const pager = { ...pagination };
        pager.current = 1;
        const params = {
            page: pager.current,
            size: pagination.pageSize,
            orderby: "-id",
        };
        onLoad(pager, agent.Admin.get(params));
    };

    handleTableChange = (pagination, filters, sorter) => {
        const pager = { ...this.props.pagination };
        pager.current = pagination.current;
        let orderby = "-id";
        if (sorter && sorter.field) {
            orderby = sorter.field;
            if (sorter.order === "descend") {
                orderby = "-" + sorter.field;
            }
        }

        const params = {
            page: pager.current,
            size: pagination.pageSize,
            orderby,
            ...filters,
        };

        this.props.onLoad(pager, agent.Admin.get(params));
    };
    handleAdd = e => {
        this.props.onCreateAdmin();
    };
    onAdminModalOk = send => {
        const { onAdminModalOk, currentAdmin, onLoadCurrentUser } = this.props;
        if (typeof send.adminid !== "undefined") {
            // update
            const callback = () => {
                if (send.adminid === currentAdmin.adminid) {
                    onLoadCurrentUser();
                }
            };
            return onAdminModalOk(send, callback);
        }
        // create
        const callback = () => this.onLoad();
        onAdminModalOk(send, callback.bind(this));
    };

    render() {
        const {
            results,
            pagination,
            loading,
            updateStatus,
            onUpdateStatus,
            onEditAdmin,
            onAdminModalCancel,
            userEdit,
            userEditModal,
            confirmLoading,
            errors,
            fetchError,
            history,
            currentAdmin,
        } = this.props;
        if (fetchError) {
            return <ReLogin history={history} />;
        }
        pagination.total = results.total;
        const data = results.admins;
        for (const record of data) {
            // record.loading = true;
            if (currentAdmin.adminid === record.adminid) {
                record.currentAdmin = true;
            } else if (updateStatus.adminid === record.adminid) {
                record.loading = updateStatus.loading;
                record.status = updateStatus.status;
            }
            if (userEdit.adminid === record.adminid) {
                for (const key in userEdit) {
                    record[key] = userEdit[key];
                }
            }

            record.handleStatusChange = value => {
                const send = {
                    adminid: record.adminid,
                    status: value,
                };
                onUpdateStatus(send);
            };
            record.handleEditClick = e => {
                e.preventDefault();
                e.stopPropagation();
                const send = {
                    ...record,
                };
                console.log(send)
                onEditAdmin(send);
            };
        }
        const userModalProps = {
            onAdminModalOk: this.onAdminModalOk.bind(this),
            onAdminModalCancel,
            userEdit,
            confirmLoading,
            visible: userEditModal,
            user: userEdit,
            errors,
        };
        return (
            <div>
                <Button
                    onClick={this.handleAdd}
                    type="primary"
                    style={{ marginBottom: 16 }}
                >
                    Add Admin
                </Button>
                <Table
                    columns={columns}
                    rowKey={record => record.adminid}
                    dataSource={data}
                    pagination={pagination}
                    loading={loading}
                    onChange={this.handleTableChange}
                />
                <Modal {...userModalProps} />
            </div>
        );
    }
}
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(List);
