import React from "react";
import { Table, Button, Popconfirm } from "antd";
import agent from "agent";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
    COUPONCODE_FONT,
    COUPONCODE_DEL,
    PRICE_GROUP_ADD_SHOW,
    PRICE_GROUP_ADD_HIDE,
} from "constants/actionTypes";
import ReLogin from "components/ReLogin";
import PriceAddModal from "./PriceAddModal";


const mapStateToProps = state => ({
    ...state.memberset,
});

const mapDispatchToProps = dispatch => ({
    onLoad: (pager, payload) =>
        dispatch({ type: COUPONCODE_FONT, pager, payload }),
    del: newsid => {
        return dispatch({
            type: COUPONCODE_DEL,
            payload: agent.Couponcode.delete(newsid),
        });
    },
    openAddPriceGroup: () =>
        dispatch({
            type: PRICE_GROUP_ADD_SHOW,
        }),
    onHideAddModal: () =>
        dispatch({
            type: PRICE_GROUP_ADD_HIDE,
        }),
});

class List extends React.Component {
    static propTypes = {
        pagination: PropTypes.object.isRequired,
        results: PropTypes.object.isRequired,
        loading: PropTypes.bool.isRequired,
        onLoad: PropTypes.func.isRequired,
        onUnload: PropTypes.func.isRequired,
        fetchError: PropTypes.bool.isRequired,
        onShowModal: PropTypes.func.isRequired,
        onHideModal: PropTypes.func.isRequired,
        openAddPriceGroup: PropTypes.func.isRequired,
        onHideAddModal: PropTypes.func.isRequired,
        addPriceGroup: PropTypes.func.isRequired,
        onUpdatePriceGroup: PropTypes.func.isRequired,
        onUpdateGroupStatus: PropTypes.func.isRequired,
        del: PropTypes.func.isRequired,
    };

    componentDidMount() {
        this.onLoad();
    }
    //加载价格组信息
    onLoad = () => {
        const { onLoad, pagination } = this.props;
        const pager = { ...pagination };
        const params = {
        };
        onLoad(pager, agent.Couponcode.get(params));
    };

    //翻页
    handleTableChange = (pagination, filters, sorter) => {

    };

    //修改价格
    handleUpdateModalOk = send => {


    };
    openmember = e => {
        const { del } = this.props;
        del(e);
        let _this = this
        setTimeout(function () {
            _this.onLoad()
        }, 300)
    };
    //增加价格组
    handleAddModalOk = send => {
        this.onLoad()
    };

    //弹出添加页
    handleAddPriceGroup = e => {
        this.props.openAddPriceGroup();
    }
    render() {
        const columns = [
            {
                title: "序号",
                dataIndex: "id",
                sorter: false,
                defaultSortOrder: "descend",
            },
            {
                title: "优惠码",
                key: "title",
                render: (record) => {
                    return (
                        <span>
                            {record.code}
                        </span>
                    );
                },
            },
            {
                title: "操作",
                key: "do",
                render: (record) => {
                    let colors = {
                        color: 'blue',
                        // textDecoration: 'underline',
                        cursor: 'pointer'
                    }
                    return (
                        <div>
                            <Popconfirm title="确认删除该用户?" onConfirm={() => this.openmember(record.id)}>
                                <p style={colors}> 删除</p>
                            </Popconfirm>
                        </div>
                    );
                },
            },
        ];
        const {
            results,
            pagination,
            loading,
            history,
            fetchError,
            addShow,
            onHideAddModal,
        } = this.props;
        if (fetchError) {
            return <ReLogin history={history} />;
        }
        pagination.total = results.total;
        pagination.showQuickJumper = true;
        pagination.hideOnSinglePage = true;
        console.log(this.props)
        const data = results.result;

        const addModel = {
            addShow,
            onModalCancel: onHideAddModal,
            onModalOk: this.handleAddModalOk.bind(this),
            confirmLoading: false,
        }

        return (
            <div>
                <Button
                    onClick={this.handleAddPriceGroup}
                    type="primary"
                    style={{ marginBottom: 16 }}
                >
                    添加
                </Button>
                <Table
                    columns={columns}
                    rowKey={record => record.userid}
                    dataSource={data}
                    pagination={false}
                    loading={loading}
                    onChange={this.handleTableChange}
                />
                <PriceAddModal {...addModel} />
            </div>
        );
    }
}
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(List);
