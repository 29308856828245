import React from "react";
import { Table,Layout } from "antd";
import agent from "agent";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Modal } from "antd";

import {
    ORDER_PAGE_UNLOADED,
    ORDER_PAGE_LOADED,
    ORDER_DELETE,
    ORDER_PURCHASE,
    ORDER_MODAL_SHOW,
    ORDER_MODAL_HIDE,
    ORDER_UPDATE_PRICE,
} from "constants/actionTypes";
import OrderPriceModal from "./OrderPriceModal";

import ReLogin from "components/ReLogin";

import columnsCreate from "./columns";
const { Content } = Layout;
const mapStateToProps = state => ({
    ...state.order,
});

const mapDispatchToProps = dispatch => ({
    onLoad: (pager, payload) =>
        dispatch({ type: ORDER_PAGE_LOADED, pager, payload }),
    onUnload: () => dispatch({ type: ORDER_PAGE_UNLOADED }),
    onDeleteOrder: params => dispatch({ type: ORDER_DELETE, ...params }),
    onPurchase: params => dispatch({ type: ORDER_PURCHASE, ...params }),
    /**转发到监听，ORDER_MODAL_SHOW，有返回值 */
    onShowModal: payload =>
        dispatch({
            type: ORDER_MODAL_SHOW,
            payload,
        }), 
    onHideModal: () =>
        dispatch({
            type: ORDER_MODAL_HIDE,
        }),
    /**修改订单总价的方法 */
    updatePrice: (send,callback) => {
        return dispatch({
            type: ORDER_UPDATE_PRICE,
            payload: agent.Price.updatePrice(send),
            callback,
        });
    }, 
});

class Order extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            keyword: "",
            audioid:null,
            
        };
        this.wavesurfer = null;
    }
    static propTypes = {
        pagination: PropTypes.object.isRequired,
        results: PropTypes.object.isRequired,
        loading: PropTypes.bool.isRequired,
        onLoad: PropTypes.func.isRequired,
        onUnload: PropTypes.func.isRequired,
        onDeleteOrder: PropTypes.func.isRequired,
        onPurchase: PropTypes.func.isRequired,
        fetchError: PropTypes.bool.isRequired,
        onShowModal: PropTypes.func.isRequired,
        updatePrice:PropTypes.func.isRequired,
        onTestModal:PropTypes.func.isRequired,
    };

    componentDidMount() {
        this.loadPage();
    }

    componentDidUpdate(prevProps) {
        if (this.props.match.path !== prevProps.match.path) {
            this.loadPage();
        }
    }

    loadPage = () => {
        const { pagination } = this.props;
        const pager = { ...pagination };
        pager.current = 1;
        const params = {
            page: pager.current,
            size: pagination.pageSize,
            orderby: "-id",
        };
        this.onLoad(pager, params);
    };

    isLoadHistory = () => {
        return this.props.match.path === "/order/history";
    };

    onLoad = (pager, params) => {
        if (!pager && !this.loadParams) {
            return;
        }
        if (!pager) {
            pager = this.loadParams.pager;
            params = this.loadParams.params;
        } else {
            this.loadParams = { params, pager };
        }
        if (this.isLoadHistory()) {
            params.history = true;
        }
        this.props.onLoad(pager, agent.Order.get(params));
    };
    onDeleteOrder = orderid => {
        const send = { orderid };
        this.props.onDeleteOrder({
            send,
            payload: agent.Order.delete(send),
            callback: () => {
                this.onLoad();
            },
        });
    };
    onPurchase = orderid => {
        const send = { orderid };
        this.props.onPurchase({
            send,
            payload: agent.Purchase.post(send),
            callback: () => {
                this.onLoad();
            },
        });
    };

    componentWillUnmount() {
        this.props.onUnload();
    }

    handleCustomerModalOk = (send) => {
        const { updatePrice, onHideModal} = this.props;
        const callback = () => {
            const { onLoad, pagination} = this.props;
            const pager = { ...pagination };
            
            const params = {
                page: pager.current,
                size: pagination.pageSize,
                orderby: "-id",

            };
            if (this.isLoadHistory()) {
                params.history = true;
            }
            onLoad(pager, agent.Order.get(params));
            onHideModal();
        };
        updatePrice(send,callback.bind(this));
    };
    handleTableChange = (pagination, filters, sorter) => {
        const pager = { ...this.props.pagination };
        pager.current = pagination.current;
        let orderby = "-id";
        if (sorter && sorter.field) {
            orderby = sorter.field;
            if (sorter.order === "descend") {
                orderby = "-" + sorter.field;
            }
        }
        const params = {
            page: pager.current,
            size: pagination.pageSize,
            orderby,
            ...filters,
        };
        this.onLoad(pager, params);
    };

    render() {
        const {
            results,
            pagination,
            loading,
            history,
            fetchError,
            onShowModal,
            onHideModal,
            modal
        } = this.props;
        if (fetchError) {
            return <ReLogin history={history} />;
        }
        pagination.total = results.total;

        const data = results.orders;

        for (const record of data) {
            record.handleDeleteClick = () => {
                Modal.confirm({
                    title: "确认删除订单吗？操作不可恢复！",
                    content: `将要删除订单： ${record.orderid}`,
                    okText: "确认删除",
                    okType: "danger",
                    cancelText: "No",
                    okButtonProps: {
                        disabled: record.updating,
                    },
                    onOk: () => {
                        this.onDeleteOrder(record.orderid);
                    },
                });
            };

            record.handlePurchaseClick = () => {
                Modal.confirm({
                    title: "确认提交订单吗？操作不可恢复！",
                    content: `将要提交订单： ${record.orderid}`,
                    okText: "完成交易",
                    okType: "danger",
                    cancelText: "No",
                    okButtonProps: {
                        disabled: record.updating,
                    },
                    onOk: () => {
                        this.onPurchase(record.orderid);
                    },
                });
            };
            record.handleEditClick = e => {
                e.preventDefault();
                e.stopPropagation();
                /**将本条数据存为send */
                const send = {
                    ...record,
                };
                /**调用onShowModal方法，传send参数 */
                onShowModal(send);
            };
        }
        const columns = columnsCreate(this.isLoadHistory());
        const { data: modalData, visible } = modal;
        const modalProps = {
            onModalOk: this.handleCustomerModalOk.bind(this),/**给onModalOk变量赋值 */
            onModalCancel: onHideModal,
            modalData,
            confirmLoading:false,
            visible,
        };
        return (
            <Content>
                <Table
                    className="c382-order-list"
                    columns={columns}
                    rowKey={record => record.orderid}
                    dataSource={data}
                    pagination={pagination}
                    loading={loading}
                    onChange={this.handleTableChange}
                />
                <OrderPriceModal {...modalProps} />
            </Content>
        );
    }
}
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Order);
