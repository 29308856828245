import React from "react";
import { connect } from "react-redux";
import { Route, Switch } from "react-router-dom";
import NotFound from "components/NotFound";
import List from "components/Producer/List";
import Review from "components/Producer/Review";
import "./Producer.less";

const mapStateToProps = state => ({
    ...state.producer,
});

const mapDispatchToProps = dispatch => ({});

class Producer extends React.Component {
    render() {
        const { match } = this.props;
        const basepath = match.path === "/" ? "" : match.path;

        return (
            <Switch>
                <Route exact path={`${basepath}/list`} component={List} />
                <Route exact path={`${basepath}/review`} component={Review} />
                <Route exact component={NotFound} />
            </Switch>
        );
    }
}
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Producer);
