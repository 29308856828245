import React from "react";
import { connect } from "react-redux";
import Waveform from "./Waveform";
import PropTypes from "prop-types";
import "./Player.less";
const mapStateToProps = state => ({
    ...state.player,
});

const mapDispatchToProps = dispatch => ({
   
});

class Player extends React.Component {
    static propTypes = {
        audio: PropTypes.object,
    };
    render() {
        const { audio } = this.props;
        const props = {
            audio,
        };
        return (
            <div className="fixedPlayer hidden">
                <Waveform {...props} />
            </div>
        );
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Player);
