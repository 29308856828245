import React from "react";
import { Table ,Button} from "antd";
import agent from "agent";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
    PRODUCER_PAGE_UNLOADED,
    PRODUCER_PAGE_LOADED,
    PRODUCER_STATUS_UPDATE,
    PRODUCER_PRICE_STATUS_UPDATE,
} from "constants/actionTypes";

import ReLogin from "components/ReLogin";

import columnsCreate from "./columns";
const columns = columnsCreate();

const mapStateToProps = state => ({
    ...state.producer,
});

const mapDispatchToProps = dispatch => ({
    onLoad: (pager, payload) =>
        dispatch({ type: PRODUCER_PAGE_LOADED, pager, payload }),
    onUnload: () => dispatch({ type: PRODUCER_PAGE_UNLOADED }),
    onUpdateStatus: send =>
        dispatch({
            type: PRODUCER_STATUS_UPDATE,
            send,
            payload: agent.Producer.putStatus(send),
        }),
    handlePriceChange: send =>
        dispatch({
            type: PRODUCER_PRICE_STATUS_UPDATE,
            send,
            payload: agent.Producer.putPriceStatus(send),
        }),
});

class Producer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            keyword: "",
            begin: "",
            end: "",
            sta: "",
            type: 0,
        };
    }
    static propTypes = {
        pagination: PropTypes.object.isRequired,
        results: PropTypes.object.isRequired,
        loading: PropTypes.bool.isRequired,
        onLoad: PropTypes.func.isRequired,
        onUnload: PropTypes.func.isRequired,
        fetchError: PropTypes.bool.isRequired,
        onUpdateStatus: PropTypes.func.isRequired,
        handlePriceChange: PropTypes.func.isRequired,

        updateStatus: PropTypes.shape({
            user_id: PropTypes.number.isRequired,
            status: PropTypes.string.isRequired,
            loading: PropTypes.bool.isRequired,
        }).isRequired,
    };

    componentDidMount() {
        const { onLoad, pagination } = this.props;
        const pager = { ...pagination };
        pager.current = 1;
        const params = {
            page: pager.current,
            size: pagination.pageSize,
            orderby: "-user_id",
        };
        onLoad(pager, agent.Producer.get(params));
    }

    componentWillUnmount() {
        this.props.onUnload();
    }
    handleKeywordChange = e => {
        this.setState({ keyword: e.target.value });
    };
    handleAdds = e => {
        const { onLoad, pagination } = this.props;
        const pager = { ...pagination };
        const key = this.state.keyword
        pager.current = 1;
        const params = {
            page: pager.current,
            size: pagination.pageSize,
            orderby: "-user_id",
           
        };
        if(key){
            params.username = key
        }
        onLoad(pager, agent.Producer.get(params));
    }
    handleTableChange = (pagination, filters, sorter) => {
        const pager = { ...this.props.pagination };
        pager.current = pagination.current;
        let orderby = "-user_id";
        if (sorter && sorter.field) {
            orderby = sorter.field;
            if (sorter.order === "descend") {
                orderby = "-" + sorter.field;
            }
        }
        const key = this.state.keyword
        const params = {
            page: pager.current,
            size: pagination.pageSize,
            orderby,
            ...filters,
        };
        if(key){
            params.username = key
        }
        this.props.onLoad(pager, agent.Producer.get(params));
    };

    render() {
        const {
            results,
            pagination,
            loading,
            history,
            fetchError,
            updateStatus,
            onUpdateStatus,
            handlePriceChange,
        } = this.props;
        if (fetchError) {
            return <ReLogin history={history} />;
        }
        pagination.total = results.total;

        const data = results.producers;
        for (const record of data) {
            if (updateStatus.user_id === record.user_id) {
                record.loading = updateStatus.loading;
                record.status = updateStatus.status;
            }
            record.priceGroup = results.priceGroup;
            record.handleStatusChange = value => {
                const send = {
                    user_id: record.user_id,
                    status: value,
                    userid: record.author.userid,
                };
                onUpdateStatus(send);
            };
            record.handlePriceChange = value => {
                const send = {
                    user_id: record.user_id,
                    price_group_id: value,
                };
                handlePriceChange(send)
            };
        }
        const keyword = this.state.keyword;
        return (
            <div>
                <input
                    type="text"
                    name=""
                    value={keyword}
                    onChange={this.handleKeywordChange}
                    className="searchSkin"
                    placeholder="邮箱/手机号"
                    style={{width:"200px"}}
                />
                <Button
                    onClick={this.handleAdds}
                    type="primary"
                    style={{ marginBottom: 16, marginLeft: "20px" }}
                >
                    搜索
                </Button>
                <Table
                    className="c382-producer-list"
                    columns={columns}
                    rowKey={record => record.user_id}
                    dataSource={data}
                    pagination={pagination}
                    loading={loading}
                    onChange={this.handleTableChange}
                />
            </div>
        );
    }
}
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Producer);
