import React from "react";
import { Table ,Select ,Button } from "antd";
import agent from "agent";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
    USER_PAGE_UNLOADED,
    PRICE_GROUP_LOADED,
    PRICE_GROUP_MODAL_SHOW,
    PRICE_GROUP_MODAL_HIDE,
    PRICE_GROUP_ADD_SHOW,
    PRICE_GROUP_ADD_HIDE,
    ADD_PRICE_GROUP,
    PRICE_GROUP_UPDATE,
    PRICE_GROUP_UPDATE_STATUS,
} from "constants/actionTypes";
import ReLogin from "components/ReLogin";
import PriceUpdateModal from "./PriceUpdateModal";
import PriceAddModal from "./PriceAddModal";
const Option = Select.Option;
const columns = [
    {
        title: "序号",
        dataIndex: "id",
        sorter: false,
        defaultSortOrder: "descend",
    },
    {
        title: "价格组名称",
        key: "title",
        render: (record) => {
            return (
                <span>
                    {record.title}
                </span>
            );
        },
    },
    {
        title: "创建人",
        key: "create_by",
        render: (record) => {
            return (
                <span>
                    {record.creatBy.email?record.creatBy.email:''}
                </span>
            );
        },
    },
    {
        title: "创建时间",
        key: "create_at",
        render: (record) => {
            return (
                <span>
                    {record.create_at}
                </span>
            );
        },
    },
    {
        title: "修改人",
        key: "update_by",
        render: (record) => {
            return (
                <span>
                    {record.updateBy.email}
                </span>
            );
        },
    },
    {
        title: "修改时间",
        key: "update_at",
        render: (record) => {
            return (
                <span>
                    {record.update_at}
                </span>
            );
        },
    },
    {
        title: "状态",
        key: "flag",
        render: (record) => {
            return (
                <Select
                    defaultValue={record.flag.toString()}
                    style={{ width: 120 }}
                    onChange={record.handleStatusChange}
                    loading={record.loading}
                    disabled={record.loading}
                >
                    <Option value="0">正常</Option>
                    <Option value="1">已删除</Option>
                </Select>
            );
        },
    },
    {
        title: "操作",
        key: "do",
        render: (record) => {
            return (
                <div>
                    <Button type="dashed" onClick={record.handleEditClick}>
                        编辑
                    </Button>
                </div>
            );
        },
    },
];

const mapStateToProps = state => ({
    ...state.priceGroup,
});

const mapDispatchToProps = dispatch => ({
    onLoad: (pager, payload) =>
        dispatch({ type: PRICE_GROUP_LOADED, pager, payload }),
    onUnload: () => dispatch({ type: USER_PAGE_UNLOADED }),
    onUpdateGroupStatus: send =>
        dispatch({
            type: PRICE_GROUP_UPDATE_STATUS,
            send,
            payload: agent.PriceGroup.updateStatus(send),
        }),
    onShowModal: payload =>
        dispatch({
            type: PRICE_GROUP_MODAL_SHOW,
            payload,
        }), 
    onHideModal: () =>
        dispatch({
            type: PRICE_GROUP_MODAL_HIDE,
        }),
    openAddPriceGroup: () =>
        dispatch({
            type: PRICE_GROUP_ADD_SHOW,
        }),
    onHideAddModal: () =>
        dispatch({
            type: PRICE_GROUP_ADD_HIDE,
        }),
    addPriceGroup: (send,callback) =>
        dispatch({
            type: ADD_PRICE_GROUP,
            send,
            payload: agent.PriceGroup.create(send),
            callback,
        }), 
    onUpdatePriceGroup: (send,callback) =>
        dispatch({
            type: PRICE_GROUP_UPDATE,
            send,
            payload: agent.PriceGroup.put(send),
            callback,
        }), 
});

class List extends React.Component {
    static propTypes = {
        pagination: PropTypes.object.isRequired,
        results: PropTypes.object.isRequired,
        loading: PropTypes.bool.isRequired,
        onLoad: PropTypes.func.isRequired,
        onUnload: PropTypes.func.isRequired,
        fetchError: PropTypes.bool.isRequired,
        onShowModal: PropTypes.func.isRequired,
        onHideModal:PropTypes.func.isRequired,
        openAddPriceGroup:PropTypes.func.isRequired,
        onHideAddModal:PropTypes.func.isRequired,
        addPriceGroup:PropTypes.func.isRequired,
        onUpdatePriceGroup:PropTypes.func.isRequired,
        onUpdateGroupStatus:PropTypes.func.isRequired,
    };

    componentDidMount() {
        this.onLoad();
    }
    //加载价格组信息
    onLoad = () => {
        const { onLoad, pagination } = this.props;
        const pager = { ...pagination };
        pager.current = 1;
        const params = {
            page: pager.current,
            size: pagination.pageSize,
            orderby: "-id",
        };
        onLoad(pager, agent.PriceGroup.get(params));
    };

    //翻页
    handleTableChange = (pagination, filters, sorter) => {
        const pager = { ...this.props.pagination };
        pager.current = pagination.current;
        let orderby = "-id";
        if (sorter && sorter.field) {
            orderby = sorter.field;
            if (sorter.order === "descend") {
                orderby = "-" + sorter.field;
            }
        }

        const params = {
            page: pager.current,
            size: pagination.pageSize,
            orderby,
            ...filters,
        };

        this.props.onLoad(pager, agent.PriceGroup.get(params));
    };

    //修改价格
    handleUpdateModalOk = send => {
        const { onUpdatePriceGroup,onHideModal }=this.props;
        const data={
            info:send
        }
        const callback = () => {
            const { onLoad, pagination } = this.props;
            const pager = { ...pagination };
            const params = {
                page: pager.current,
                size: pagination.pageSize,
                orderby: "-id",
            };
            onHideModal();
            onLoad(pager, agent.PriceGroup.get(params));  
        };
        onUpdatePriceGroup(data,callback);
       

    };

    //增加价格组
    handleAddModalOk= send => {
        console.log(send)
        const { onHideAddModal,addPriceGroup }=this.props;
        const callback = () => {
            const { onLoad, pagination } = this.props;
            const pager = { ...pagination };
            const params = {
                page: 1,
                size: pagination.pageSize,
                orderby: "-id",
            };
            onHideAddModal();
            onLoad(pager, agent.PriceGroup.get(params));  
        };
        addPriceGroup(send,callback);
       
    };

    //弹出添加页
    handleAddPriceGroup = e => {
        this.props.openAddPriceGroup();
    }
    render() {
        const {
            results,
            pagination,
            loading,
            history,
            fetchError,
            modal,
            onUpdateGroupStatus,
            onShowModal,
            onHideModal,
            addShow,
            onHideAddModal,
        } = this.props;
        if (fetchError) {
            return <ReLogin history={history} />;
        }
        pagination.total = results.total;
        pagination.showQuickJumper = true;
        pagination.hideOnSinglePage=true;
        const data = results.priceGroup;
        for (const record of data) {
            // record.loading = true;
            record.handleStatusChange = value => {
                const send = {
                    id: record.id,
                    flag: value,
                };
                onUpdateGroupStatus(send);
            };
            record.handleEditClick = e => {
                e.preventDefault();
                e.stopPropagation();
                const send = {
                    ...record,
                };
                console.info(send);
                onShowModal(send);
            };
        }
        const { data: modalData, visible } = modal;
        const modalProps = {
            onModalOk: this.handleUpdateModalOk.bind(this),
            onModalCancel: onHideModal,
            modalData,
            confirmLoading:false,
            visible,
        };
        const addModel={
            addShow,
            onModalCancel: onHideAddModal,
            onModalOk: this.handleAddModalOk.bind(this),
            confirmLoading:false,
        }
        
        return (
            <div>
                <Button
                    onClick={this.handleAddPriceGroup}
                    type="primary"
                    style={{ marginBottom: 16 }}
                >
                    添加
                </Button>
                <Table
                    columns={columns}
                    rowKey={record => record.userid}
                    dataSource={data}
                    pagination={pagination}
                    loading={loading}
                    onChange={this.handleTableChange}
                />
                <PriceUpdateModal {...modalProps} />
                <PriceAddModal {...addModel} />
            </div>
        );
    }
}
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(List);
