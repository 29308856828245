import React from "react";
import { Table, Button } from "antd";
import agent from "agent";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
    WITHDRAW_PAGE_UNLOADED,
    WITHDRAW_PAGE_LOADED,
    WITHDRAW_STATUS_UPDATE,
} from "constants/actionTypes";

import ReLogin from "components/ReLogin";

import columnsCreate from "./columns";
const columns = columnsCreate();

for (const record of columns) {
    if (record.dataIndex === "id") {
        delete record.sorter;
    } else if (record.dataIndex === "paid") {
        delete record.filters;
    }
}

const mapStateToProps = state => ({
    ...state.withdraw,
});

const mapDispatchToProps = dispatch => ({
    onLoad: (pager, payload) =>
        dispatch({ type: WITHDRAW_PAGE_LOADED, pager, payload }),
    onUnload: () => dispatch({ type: WITHDRAW_PAGE_UNLOADED }),
    onUpdateStatus: send =>
        dispatch({
            type: WITHDRAW_STATUS_UPDATE,
            send,
            payload: agent.Withdraw.putStatus(send),
        }),
});

class Withdraw extends React.Component {
    static propTypes = {
        pagination: PropTypes.object.isRequired,
        results: PropTypes.object.isRequired,
        loading: PropTypes.bool.isRequired,
        onLoad: PropTypes.func.isRequired,
        onUnload: PropTypes.func.isRequired,
        fetchError: PropTypes.bool.isRequired,
        onUpdateStatus: PropTypes.func.isRequired,

        updateStatus: PropTypes.shape({
            id: PropTypes.number.isRequired,
            paid: PropTypes.string.isRequired,
            loading: PropTypes.bool.isRequired,
        }).isRequired,
    };

    componentDidMount() {
        this.handleRefreashPage();
    }

    componentWillUnmount() {
        this.props.onUnload();
    }

    handleRefreashPage = e => {
        const { onLoad, pagination } = this.props;
        const pager = { ...pagination };
        pager.current = 1;
        const params = {
            page: pager.current,
            size: pagination.pageSize,
            orderby: "-id",
            paid: "no",
        };
        onLoad(pager, agent.Withdraw.get(params));
    };

    handleTableChange = (pagination, filters, sorter) => {
        const pager = { ...this.props.pagination };
        pager.current = pagination.current;
        const params = {
            page: pager.current,
            size: pagination.pageSize,
            orderby: "-id",
            paid: "no",
        };

        this.props.onLoad(pager, agent.Withdraw.get(params));
    };

    render() {
        const {
            results,
            pagination,
            loading,
            history,
            fetchError,
            updateStatus,
            onUpdateStatus,
        } = this.props;
        if (fetchError) {
            return <ReLogin history={history} />;
        }
        pagination.total = results.total;

        const data = results.withdraw || [];

        for (const record of data) {
            if (updateStatus.id === record.id) {
                record.loading = updateStatus.loading;
                record.paid = updateStatus.paid;
            }

            record.handleStatusChange = value => {
                const send = {
                    id: record.id,
                    payid: record.payid,
                    paid: value,
                    userid: record.author.userid,
                };
                onUpdateStatus(send);
            };
        }
        return (
            <div>
                <Button
                    onClick={this.handleRefreashPage}
                    type="primary"
                    style={{ marginBottom: 16 }}
                >
                    获取新的数据
                </Button>
                <Table
                    className="c382-withdraw-list"
                    columns={columns}
                    rowKey={record => record.id}
                    dataSource={data}
                    pagination={pagination}
                    loading={loading}
                    onChange={this.handleTableChange}
                />
            </div>
        );
    }
}
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Withdraw);
