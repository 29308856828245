import React from "react";
import PropTypes from "prop-types";
import {Alert} from "antd";

const divStyle = {
    marginBottom: "20px"
};

class ListErrors extends React.Component {
    static propTypes = {
        errors: PropTypes.array
    };
    render() {
        const errors = this.props.errors;
        if (errors) {
            return (
                <div>
                    {Object.keys(errors).map(key => {
                        let message = errors[key];
                        if (typeof message !== "string") {
                            if(message.message=="user not fount."){
                                message="没有找到符合条件的用户."
                            }else if(message.message=="audio not found."){
                                message="没有找到符合条件的音乐."
                            }else if(message.status!=0){
                                message = JSON.stringify(message);
                            }
                        }
                        return (
                            <Alert
                                style={divStyle}
                                key={key}
                                message={`${message}`}
                                type="error"
                            />
                        );
                    })}
                </div>
            );
        } else {
            return null;
        }
    }
}

export default ListErrors;
