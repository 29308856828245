import {
    PLAYLIST_INFO_APP,
    AUDIO_CONDITION_ALL_APP,
} from "../constants/actionTypes";

const arrayToObject = data => {
    if (!Array.isArray(data)) {
        return data;
    }
    const output = {};
    for (const record of data) {
        output[record.id] = record;
    }
    return output;
};

export default (state = {}, action) => {
    switch (action.type) {
        case PLAYLIST_INFO_APP: {
            if (action.error) {
                return state;
            }
            const infos = arrayToObject(action.payload.infos);

            return {
                ...state,
                playlistInfo: infos,
            };
        }
        case AUDIO_CONDITION_ALL_APP: {
            if (action.error) {
                return state;
            }
            const condition = action.payload.condition || {};
            const audioCondition = {};
            for (const key in condition) {
                audioCondition[key] = arrayToObject(condition[key]);
            }
            return {
                ...state,
                condition,
                audioCondition,
            };
        }
        default:
            return state;
    }
};
