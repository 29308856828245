import {
    ADMIN_PAGE_LOADED,
    ADMIN_PAGE_UNLOADED,
    ASYNC_START,
    ADMIN_STATUS_UPDATE,
    ADMIN_EDIT,
    ADMIN_EDIT_CANCEL,
    ADMIN_EDIT_OK,
    ADMIN_CRATE,
} from "../constants/actionTypes";

const defaultState = {
    results: { admins: [] },
    pagination: { pageSize: 10, current: 1 },
    loading: false,
    fetchError: false,
    updateStatus: { adminid: "", status: "", loading: false },
    userEdit: {},
    userEditModal: false,
    confirmLoading: false,
};
export default (state = { ...defaultState }, action) => {
    switch (action.type) {
        case ADMIN_PAGE_LOADED: {
            const pagination = action.pager;
            if (!action.error) {
                pagination.total = action.payload.total;
            }
            return {
                ...state,
                fetchError: !!action.error,
                results: action.payload,
                pagination,
                loading: false,
            };
        }
        case ADMIN_EDIT: {
            const userEdit = { ...action.payload };

            return { ...state, userEdit, userEditModal: true };
        }
        case ADMIN_CRATE: {
            const userEdit = {};

            return { ...state, userEdit, userEditModal: true };
        }
        case ADMIN_EDIT_CANCEL: {
            return {
                ...state,
                userEditModal: false,
                userEdit: {},
                errors: null,
            };
        }
        case ADMIN_EDIT_OK: {
            const fetchError = !!action.error;
            if (fetchError) {
                return {
                    ...state,
                    confirmLoading: false,
                    errors: action.payload.errors,
                };
            }
            const userEdit = { ...state.userEdit, ...action.payload.admin };

            return {
                ...state,
                userEditModal: false,
                confirmLoading: false,
                errors: null,
                userEdit,
            };
        }
        case ADMIN_STATUS_UPDATE: {
            const updateStatus = { ...state.updateStatus };
            updateStatus.adminid = action.error ? "" : action.send.adminid;
            updateStatus.status = action.send.status;
            updateStatus.loading = false;
            return { ...state, updateStatus };
        }
        case ASYNC_START:
            if (action.subtype === ADMIN_PAGE_LOADED) {
                return { ...state, loading: true };
            }
            if (action.subtype === ADMIN_STATUS_UPDATE) {
                const updateStatus = { ...state.updateStatus };
                updateStatus.adminid = action.send.adminid;
                updateStatus.status = action.send.status;
                updateStatus.loading = true;
                return { ...state, updateStatus };
            }
            if (action.subtype === ADMIN_EDIT_OK) {
                return { ...state, confirmLoading: true };
            }
            return state;
        case ADMIN_PAGE_UNLOADED:
            return { ...defaultState };
        default:
            return state;
    }
};
