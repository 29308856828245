import React from "react";
import agent from "agent";
import { Divider, Avatar, Select } from "antd";
import helper from "utils/helper";
//const { Content } = Layout;
const Option = Select.Option;

const columns = [
    {
        title: "Id",
        dataIndex: "id",
        sorter: true,
        defaultSortOrder: "descend",
    },
    {
        title: "结算信息",
        key: "withdrawInfo",
        render: record => {
            return (
                <div>
                    {/* <span>Payid: </span>
                    <span>{record.payid}</span>
                    <Divider /> */}
                    <span>提现时间: </span>
                    <span>{helper.showDate(record.create_at)}</span>
                    <Divider />
                    <span>金额: </span>
                    <span>{helper.showWithDrawMoney(record.price)}</span>
                    <Divider />
                    <span>纳税方式: </span>
                    <span>{record.tax == 1 ? "MUSINESS代扣代缴税费" : "卖家自行缴纳"}</span>
                    <Divider />
                    <span>提现方式: </span>
                    <span>{record.withdraw == 1 ? "支付宝" : "银行卡转账"}</span>
                    <Divider />
                    {record.withdraw == 1 ?
                        (<span>
                            <span>支付宝: </span>
                            {record.alipay}
                            <Divider />
                            <span>姓名: </span>
                            {record.realname}
                            <Divider />
                        </span>) : (
                            <span>
                                <span>银行名称: </span>
                                {record.bankname}
                                <Divider />
                                <span>支行名称: </span>
                                {record.branchname}
                                <Divider />
                                <span>银行卡号: </span>
                                {record.banknumber}
                                <Divider />
                                <span>账号名: </span>
                                {record.accountname}
                                <Divider />
                            </span>
                        )
                    }
                </div>
            );
        },
    },
    {
        title: "Author",
        key: "author",
        render: record => {
            const author = record.author || {};
            return (
                <span>
                    <span>ID: </span>
                    {author.userid}
                    <Divider />
                    <span>头像: </span>
                    <Avatar
                        key={author.avatar}
                        src={agent.getApiUrl(author.avatar)}
                        size="large"
                        icon="user"
                    />
                    <Divider />
                    <span>用户账号: </span>
                    {author.phone || author.email}
                    <Divider />
                    <span>姓名: </span>
                    {author.company ? author.family_name : author.family_name + author.given_name}
                    <Divider />
                    {
                        author.company_number && author.company_name ? (
                            <span>
                                <span>公司名称: </span>
                                {author.company_name}
                                <Divider />
                                <span>营业执照: </span>
                                {author.company_number}
                                <Divider />
                            </span>
                        ) :
                            (
                                <span>
                                    <span>身份证号: </span>
                                    {author.identify_number}
                                    <Divider />
                                </span>
                            )
                    }
                    <span>联系电话: </span>
                    {author.contact_number}
                    <Divider />
                </span>
            );
        },
    },
    {
        title: <span>Status</span>,
        dataIndex: "paid",
        filters: [
            { text: "未支付", value: "no" },
            { text: "已支付", value: "yes" },
            { text: "拒绝", value: "reject" },
        ],
        render: (status, record) => {
            const disabled = record.loading;
            return (
                <Select
                    defaultValue={status}
                    style={{ width: 120 }}
                    onChange={record.handleStatusChange}
                    loading={record.loading}
                    disabled={disabled}
                >
                    <Option value="no">未支付</Option>
                    <Option value="yes">已支付</Option>
                    <Option value="reject">拒绝</Option>
                </Select>
            );
        },
    },
];

export default () => {
    const data = [];
    for (const record of columns) {
        data.push({ ...record });
    }
    return data;
};
