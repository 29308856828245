import React from "react";
import agent from "agent";
import { Divider, Avatar, List, Popconfirm } from "antd";
import helper from "utils/helper";
var HtmlUtil = {
    /*1.用浏览器内部转换器实现html转码*/
    htmlEncode: function (html) {
        //1.首先动态创建一个容器标签元素，如DIV
        var temp = document.createElement("div");
        //2.然后将要转换的字符串设置为这个元素的innerText(ie支持)或者textContent(火狐，google支持)
        (temp.textContent != undefined) ? (temp.textContent = html) : (temp.innerText = html);
        //3.最后返回这个元素的innerHTML，即得到经过HTML编码转换的字符串了
        var output = temp.innerHTML;
        temp = null;
        return output;
    },
    /*2.用浏览器内部转换器实现html解码*/
    htmlDecode: function (text) {
        //1.首先动态创建一个容器标签元素，如DIV
        var temp = document.createElement("div");
        //2.然后将要转换的字符串设置为这个元素的innerHTML(ie，火狐，google都支持)
        temp.innerHTML = text;
        //3.最后返回这个元素的innerText(ie支持)或者textContent(火狐，google支持)，即得到经过HTML解码的字符串了。
        var output = temp.innerText || temp.textContent;
        temp = null;
        return output;
    }
}
const columns = [
    {
        title: "Id",
        dataIndex: "id",
        sorter: true,
        defaultSortOrder: "descend",
    },
    {
        title: "订单",
        key: "order",
        render: record => {
            const remark = (() => {
                try {
                    const output = JSON.parse(record.data);
                    return output || {};
                } catch (e) {
                    return {};
                }
            })();
            const beizhu = () => {
                if (remark.admin) {
                    return <div>Email: {remark.admin.email}</div>;
                }
                return null;
            };
            const fujiaxinxi = () => {
                const attachment = (() => {
                    try {
                        const output = JSON.parse(record.attachment);
                        return output || {};
                    } catch (e) {
                        return {};
                    }
                })();
                // 必定存在的一些字段补全,或对某些字段的特殊类型值翻译
                // attachment.purchasePurpose = attachment.purchasePurpose || '';
                // attachment.channel = attachment.channel || '';
                // attachment.selectCountry = attachment.selectCountry || '';
                // attachment.term = attachment.term || '';//term字段为猜测字段

                // 支付类型在前面已经显示过了,而且值需要特殊转换,懒得了,因此就移除掉不显示在这里了
                if (attachment.payType) {
                    delete attachment.payType
                }
                // 键翻译
                function translateKey(key) {
                    return {
                        // "term": "授权期限",
                        "address": '联系人地址', // 地址
                        "area": '授权项目', // 授权项目
                        // "channel": '投放渠道', // 项目投放渠道
                        "companyAddress": '公司地址', // 公司地址
                        "companyEmail": '公司邮箱', // 公司email
                        "companyName": '公司名称', // 公司名称
                        "companyPhoneNumber": '联系电话', // 联系电话
                        "description": '项目描述', // 项目描述
                        "email": '授权邮箱', // 个人email
                        "linkMan": '联系人名称', // 联系名称
                        "phoneNumber": '联系人电话', // 个人电话
                        "payType": '支付类型', // 支付类型
                        // "selectCountry": '授权地区', // 授权区域
                        "username": '用户名', // 姓名
                        // "purchasePurpose": '购买用途', // 购买用途
                        "bankInfo": '银行信息',
                        "nashui": '纳税',
                        "nashuiName": '纳税人名称',
                        "nashuiMail": '开票邮箱',
                        "nashuiAddress": '纳税人地址',
                        "nashuiPhone": '纳税人电话',
                        "bankNumber": '银行卡号'
                    }[key] || key;
                }
                // 键排序
                let keysSort = [
                    // "term",
                    // "purchasePurpose",
                    // "channel",
                    // "selectCountry",
                    "area",
                    "description",
                    "username",
                    "linkMan",
                    "email",
                    "phoneNumber",
                    "address",
                    "companyName",
                    "companyEmail",
                    "companyPhoneNumber",
                    "companyAddress",
                    "nashui",
                    "nashuiName",
                    "nashuiPhone",
                    "nashuiAddress",
                    "bankInfo",
                    "bankNumber",
                    "payType",
                ];
                function sortKeys(keys) {
                    return (keys || []).sort(
                        (key1, key2) =>
                            keysSort.indexOf(key1) - keysSort.indexOf(key2)
                    );
                }
                // 渲染
                const indents = [];

                for (const [key, value] of sortKeys(Object.entries(attachment))) {
                    indents.push(
                        <div key={key}>
                            <span style={{ width: '100px' }}>{translateKey(key)}</span> {HtmlUtil.htmlDecode(value)}
                        </div>
                    );
                }
                return indents;
            };
            const attachment = eval('(' + record.attachment + ')')
            return (
                <div>
                    <span>ID: </span>
                    {record.orderid}
                    <Divider />
                    <span>创建时间: </span>
                    {helper.showDate(record.create_at)}
                    <Divider />
                    <List
                        itemLayout="horizontal"
                        dataSource={record.audios}
                        renderItem={item => (
                            <List.Item>
                                <List.Item.Meta
                                    avatar={
                                        <Avatar
                                            src={agent.getApiUrl(item.thumb)}
                                        />
                                    }
                                    title={item.title}
                                    description={item.description}
                                />
                                <div>
                                    购买用途: {item.purpose}
                                </div>   <div>
                                    投放渠道: {item.channel}
                                </div>   <div>
                                    授权地区: {item.scope==0?'全球':'中国大陆'}
                                </div>   <div>
                                    授权期限: {item.term==0?'随片永久':'一年'}
                                </div>
                                <div>
                                    单价: {helper.showAudioMoney(item.price)}
                                </div>

                            </List.Item>

                        )}
                    />
                    <Divider />
                    <span>总价: </span>
                    {helper.showAudioMoney(record.price)}
                    <Divider />
                    <span>交易时间: </span>
                    {helper.showDate(record.create_at)}
                    <Divider />
                    <span>交易方式: </span>
                    {{ alipay: '阿里支付', 'wechat': '微信', manual: '手动', }[record.type] || record.type},{attachment && attachment.payType ? ({ 1: '支付宝', 2: "银行卡转账", '3': '3', 4: '微信支付', 5: '微信支付2' }[attachment.payType] || attachment.payType) : ""}
                    <Divider />
                    <span>附加信息: </span>
                    <div className="description" style={{ paddingLeft: 50 }}>
                        {fujiaxinxi()}
                    </div>
                    <Divider />
                    <span>备注: </span>
                    <div className="description" style={{ paddingLeft: 50 }}>
                        {beizhu()}
                    </div>
                </div>
            );
        },
    },
    {
        title: "Author",
        key: "author",
        render: record => {
            const author = record.author || {}
            return (
                <span>
                    <span>ID: </span>
                    {author.userid}
                    <Divider />
                    <span>Avatar: </span>
                    <Avatar
                        key={author.avatar}
                        src={agent.getApiUrl(author.avatar)}
                        size="large"
                        icon="user"
                    />
                    <Divider />
                    <span>Name: </span>
                    {record.family_name} {record.given_name}
                    <Divider />
                    <span>Email: </span>
                    {author.email}
                    <Divider />
                    <span>Phone: </span>
                    {author.phone}
                    <Divider />
                    <span>公司名: </span>
                    {record.company_name}
                    <Divider />
                    <span>公司营业号: </span>
                    {record.company_number}
                    <Divider />
                    <span>描述: </span>
                    {record.p_description}
                </span>
            );
        },
    },

    {
        title: "Receipt",
        key: "receipt",
        render: (record) => {
            const attachment = (() => {
                try {
                    const output = JSON.parse(record.attachment);
                    return output || {};
                } catch (e) {
                    return {};
                }
            })();
            let str;
            if (record.receipt == 1 || record.receipt == "待开票") {
                str = (
                    <div>
                        <p><span>名称:</span> <span>{attachment.nashuiName}</span></p>
                        <p><span>纳税人识别号:</span> <span>{attachment.nashui}</span> </p>
                        <p><span>开票邮箱:</span> <span>{attachment.nashuiMail}</span> </p>
                        <p><span>地址:</span> <span>{attachment.nashuiAddress}</span> </p>
                        <p><span>电话:</span> <span>{attachment.nashuiPhone}</span> </p>
                        <p><span>开户行:</span> <span>{attachment.bankInfo}</span> </p>
                        <p><span>银行账号:</span> <span>{attachment.bankNumber}</span> </p>
                    </div>
                )
            }
            return (
                <div>
                    {str}
                </div>

            )
        },
    },
    {
        title: "ReceiptStatus",
        key: "ReceiptStatus",
        render: (record) => {
            let colors = {
                color: 'blue',
                textDecoration: 'underline',
                cursor: 'pointer'
            }
            let userMessage = null;

            if (record.receipt == 1) {
                record.receipt = '待开票'
                userMessage = (<div>
                    <p> {record.receipt}</p>
                    <Popconfirm title="确认完成开票?" onConfirm={() => record.bindreceiptkp(record.orderid)}>
                        <p style={colors}>完成开票</p>
                    </Popconfirm>

                </div>)
            } else if (record.receipt == 0) {
                record.receipt = '未申请'
                userMessage = (
                    <span> {record.receipt} </span>)
            } else if (record.receipt == 2) {
                record.receipt = '已开票'
                userMessage = (
                    <span> {record.receipt} </span>)
            } else {
                if (record.receipt == "待开票") {
                    userMessage = (<div>
                        <p> {record.receipt}</p>
                        <Popconfirm title="确认完成开票?" onConfirm={() => record.bindreceiptkp(record.orderid)}>
                            <p style={colors}>完成开票</p>
                        </Popconfirm>

                    </div>)
                } else {
                    userMessage = (
                        <span> {record.receipt} </span>)
                }

            }

            return (
                <div>
                    {userMessage}
                </div>
            );
        },
    },
];

export default () => {
    const data = [];
    for (const record of columns) {
        data.push({ ...record });
    }

    return data;
};
