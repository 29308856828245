import React from "react";
import { connect } from "react-redux";
import { Route, Switch } from "react-router-dom";
import List from "./List";

const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => ({});

class Playlist extends React.Component {
    render() {
        return (
            <Switch>
                <Route exact component={List} />
            </Switch>
        );
    }
}
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Playlist);
