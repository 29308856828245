import React from "react";
import { Table, Modal } from "antd";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { AUDIO_PAGE_UNLOADED } from "constants/actionTypes";
import ReLogin from "components/ReLogin";
import { onDeleteSusumeAudio, onSusumePageLoad } from "dispatch";

import columnsCreate from "./susume-columns";
const columns = columnsCreate();

const mapStateToProps = state => ({
    ...state.audio,
});

const mapDispatchToProps = dispatch => {
    const props = {
        onUnload: () => dispatch({ type: AUDIO_PAGE_UNLOADED }),
    };
    return props;
};

class Audio extends React.Component {
    static propTypes = {
        pagination: PropTypes.object.isRequired,
        results: PropTypes.object.isRequired,
        loading: PropTypes.bool.isRequired,
        onUnload: PropTypes.func.isRequired,
        fetchError: PropTypes.bool.isRequired,
    };

    componentDidMount() {
        const { pagination } = this.props;
        const pager = { ...pagination };
        pager.current = 1;
        const params = {
            page: pager.current,
            size: pagination.pageSize,
            orderby: "-id",
        };
        onSusumePageLoad(pager, params);
    }

    componentWillUnmount() {
        this.props.onUnload();
    }

    handleTableChange = (pagination, filters, sorter) => {
        const pager = { ...this.props.pagination };
        pager.current = pagination.current;
        let orderby = "-id";
        if (sorter && sorter.field) {
            orderby = sorter.field;
            if (sorter.order === "descend") {
                orderby = "-" + sorter.field;
            }
        }

        const params = {
            page: pager.current,
            size: pagination.pageSize,
            orderby,
            ...filters,
        };

        onSusumePageLoad(pager, params);
    };

    render() {
        const {
            results,
            pagination,
            loading,
            history,
            fetchError,
        } = this.props;
        if (fetchError) {
            return <ReLogin history={history} />;
        }
        pagination.total = results.total;

        const data = results.audios || [];

        for (const record of data) {
            // record.loading = true;
            record.handleDeleteClick = () => {
                Modal.confirm({
                    title: "确认推荐中移除音乐吗？操作不可恢复！",
                    content: `将要从推荐中移除音乐： ${record.title}`,
                    okText: "确认删除",
                    okType: "danger",
                    cancelText: "No",
                    okButtonProps: {
                        disabled: record.updating,
                    },
                    onOk: () => {
                        onDeleteSusumeAudio(record.susume, () => {
                            // load current page
                            onSusumePageLoad();
                        });
                    },
                });
            };
        }
        return (
            <Table
                className="c382-audio-list"
                columns={columns}
                rowKey={record => record.audioid}
                dataSource={data}
                pagination={pagination}
                loading={loading}
                onChange={this.handleTableChange}
            />
        );
    }
}
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Audio);
