import {
    PRICE_GROUP_LOADED,
    PRICE_GROUP_MODAL_SHOW,
    PRICE_GROUP_MODAL_HIDE,
    PRICE_GROUP_ADD_SHOW,
    PRICE_GROUP_ADD_HIDE,
} from "../constants/actionTypes";

const defaultState = {
    results: { priceGroup: [] },
    pagination: { pageSize: 10, current: 1 },
    loading: false,
    fetchError: false,
    updateStatus: { userid: "", status: "", loading: false },
    userEdit: {},
    userEditModal: false,
    confirmLoading: false,
    modal : {
        data: null,
        visible: false,
    },
    addShow:false,
};
export default (state = { ...defaultState }, action) => {
    switch (action.type) {
        case PRICE_GROUP_LOADED: {
            const pagination = action.pager;
            if (!action.error) {
                pagination.total = action.payload.total;
            }
            return {
                ...state,
                fetchError: !!action.error,
                results: action.payload,
                pagination,
                loading: false,
            };
        }
        case PRICE_GROUP_MODAL_SHOW: {
            const modal = {
                data: action.payload,
                visible: true,
            };
            return { 
                ...state,
                modal,
            };
        }
        case PRICE_GROUP_MODAL_HIDE: {
            const modal = {
                data: null,
                visible: false,
            };
            return { 
                ...state,
                modal
            };
        }
        case PRICE_GROUP_ADD_SHOW: {
            return { 
                ...state,
                addShow:true,
            };
        }
        case PRICE_GROUP_ADD_HIDE: {
            return { 
                ...state,
                addShow:false,
            };
        }
        default:
            return state;
    }
};
