import moment from "moment";
const helper = {};
helper.parseInt = i => {
    i = parseInt(i);
    return i || 0;
};
helper.parseFloat = i => {
    i = parseFloat(i);
    return i || 0;
};
helper.showAudioMoney = moeny => {
    moeny = helper.parseInt(moeny);
    return moeny / 100 + "元";
};
helper.showWithDrawMoney = moeny => {
    moeny = helper.parseFloat(moeny)
    return moeny+ "元";
};

helper.showAudioDurationTime = time => {
    return parseInt(parseInt(time) / 60) + ":" + (parseInt(time) % 60);
};
helper.getRandomInt = (min, max) => {
    return Math.floor(Math.random() * (max - min + 1)) + min;
};

helper.getBase64 = async file => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });
};

helper.showDate = val => {
    const vs = Date.parse(val);
    const va = moment(vs).format('YYYY-MM-DD HH:mm:ss');
    return va.toLocaleString();
};

export default helper;
