// import agent from "../agent";
import React from "react";
import { connect } from "react-redux";
import { Route, Switch } from "react-router-dom";
import Home from "../components/Home";
import Login from "../components/Login";

import "./App.less";

const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => ({});

class App extends React.Component {
    render() {
        return (
            <Switch>
                <Route path="/login" component={Login} />
                <Route path="/" component={Home} />
            </Switch>
        );
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(App);
