import React from "react";
import {connect} from "react-redux";
import {Route, Switch} from "react-router-dom";
import NotFound from "components/NotFound";

import List from "components/User/List";

const mapStateToProps = state => ({
    ...state.user
});

const mapDispatchToProps = dispatch => ({});

class User extends React.Component {
    render() {
        const {match} = this.props;
        const basepath = match.path === "/" ? "" : match.path;

        return (
            <Switch>
                <Route
                    path={`${basepath}/profile/:userid`}
                    component={NotFound}
                />
                <Route exact path={`${basepath}/`} component={List} />
                <Route exact component={NotFound} />
            </Switch>
        );
    }
}
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(User);
