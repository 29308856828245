import React from "react";
import PropTypes from "prop-types";
import agent from "../../agent";
import { connect } from "react-redux";
import {
    CURRENT_USER,
    HOME_PAGE_UNLOADED,
    LOGOUT,
    ADMIN_EDIT,
} from "../../constants/actionTypes";

import { Route, Switch, Link } from "react-router-dom";
// import {Link} from "react-router";
import { Layout, Icon, Avatar, Menu, Dropdown } from "antd";
import ListErrors from "components/ListErrors";
import Loading from "components/Loading";
import Sidebar from "components/Sidebar";
import Dashboard from "components/Dashboard";
import NotFound from "components/NotFound";
import User from "components/User";
import Admin from "components/Admin";
import Audio from "components/Audio";
import Producer from "components/Producer";
import Order from "components/Order";
import Captcha from "components/Captcha";
import Purchase from "components/Purchase";
import Withdraw from "components/Withdraw";
import Page from "components/Page";
import NewsManage from "components/NewsManage";
import AudioDetailModal from "components/Audio/AudioDetailModal";
import Player from "components/Player";
import Customized from "components/Customized";
import PriceGroup from "components/PriceGroup";
import Member from "components/Member";
import Memberset from "components/Memberset";
import Cooperation from "components/Cooperation";
import CustomerData from "components/CustomerData";
import CustomerKeyword from "components/CustomerKeyword";
import UnMemberset from "components/UnMemberset";
import SpecialSeller from "components/SpecialSeller";

// import custom css
import "./home.less";
const { Header, Content } = Layout;

// const Promise = global.Promise;

const mapStateToProps = state => ({
    ...state.home,
});

const mapDispatchToProps = dispatch => ({
    onLoadCurrentUser: payload =>
        dispatch({ type: CURRENT_USER, payload: agent.Auth.current() }),
    onUnload: () => dispatch({ type: HOME_PAGE_UNLOADED }),
    logout: () => dispatch({ type: LOGOUT }),
    onEditAdmin: payload =>
        dispatch({
            type: ADMIN_EDIT,
            payload,
        }),
});

class Home extends React.Component {
    static propTypes = {
        onUnload: PropTypes.func.isRequired,
        onEditAdmin: PropTypes.func.isRequired,
        currentAdmin: PropTypes.object,
        errors: PropTypes.array,
        currentError: PropTypes.bool.isRequired,
        logout: PropTypes.func.isRequired,
    };

    componentWillUnmount() {
        this.props.onUnload();


    }
    static getDerivedStateFromProps(nextProps, prevState) {
        const token = agent.getToken();
        const output = { currentAdmin: null };
        if (!token || nextProps.currentError) {
            nextProps.history.push("/login");
            return output;
        }
        if (!nextProps.currentAdmin) {
            nextProps.onLoadCurrentUser();
            return output;
        }

        output.currentAdmin = nextProps.currentAdmin;
        // if (output.currentAdmin && output.currentAdmin.email ) {
        //     if (output.currentAdmin.email.indexOf('marketing') != -1 ) {
        //         nextProps.history.push("/page/contactus/list");
        
        //     } else if (output.currentAdmin.email.indexOf('musiness') != -1 ) {
        //         nextProps.history.push("/audio/list");

        //     }
        // }

        return output;
    }

    state = {
        collapsed: false,
    };

    toggle = () => {
        this.setState({
            collapsed: !this.state.collapsed,
        });
    };
    logout = () => {
        this.props.logout();
        this.props.history.push("/login");
    };

    menuLinkTo = item => {
        this.props.history.push(item.key);
    };
    handleCurrentAdmin = e => {
        const { history, currentAdmin, onEditAdmin } = this.props;
        e.preventDefault();
        history.push("/admin");
        const send = {
            ...currentAdmin,
        };
        onEditAdmin(send);
    };

    render() {
        if (!this.state.currentAdmin) {
            return <Loading />;
        }
        const { match, currentAdmin, location } = this.props;
        const basepath = match.path === "/" ? "" : match.path;
        const menu = (
            <Menu>
                <Menu.Item key="0">
                    <Link to="#" onClick={this.handleCurrentAdmin}>
                        {currentAdmin.email}
                    </Link>
                </Menu.Item>

                <Menu.Divider />
                <Menu.Item onClick={this.logout} key="3">
                    Logout
                </Menu.Item>
            </Menu>
        );
        return (
            <Layout>
                <Sidebar
                    state={this.state}
                    location={location}
                    linkTo={this.menuLinkTo}
                    currentAdmin={currentAdmin}
                />
                <Layout>
                    <Header className="header">
                        <Icon
                            className="trigger"
                            type={
                                this.state.collapsed
                                    ? "menu-unfold"
                                    : "menu-fold"
                            }
                            onClick={this.toggle}
                        />
                        <div className="currentAdmin">
                            <Dropdown
                                key={currentAdmin.avatar}
                                overlay={menu}
                                trigger={["click"]}
                            >
                                <Avatar
                                    key={currentAdmin.avatar}
                                    size="small"
                                    icon="user"
                                    src={agent.getApiUrl(currentAdmin.avatar)}
                                />
                            </Dropdown>
                            <div className="currentEmail">
                                {currentAdmin.email}
                            </div>
                        </div>
                    </Header>

                    <Content style={{ minHeight: 800 }}>
                        <ListErrors errors={this.props.errors} />
                        <Switch>
                            <Route path={`${basepath}/user`} component={User} />
                            <Route path={`${basepath}/newsmanage`} component={NewsManage} />
                            <Route
                                path={`${basepath}/admin`}
                                component={Admin}
                            />
                            <Route
                                path={`${basepath}/audio`}
                                component={Audio}
                            />
                            <Route
                                path={`${basepath}/producer`}
                                component={Producer}
                            />
                            <Route
                                path={`${basepath}/order`}
                                component={Order}
                            />
                            <Route
                                path={`${basepath}/captcha`}
                                component={Captcha}
                            />
                            <Route
                                path={`${basepath}/purchase`}
                                component={Purchase}
                            />
                            <Route
                                path={`${basepath}/withdraw`}
                                component={Withdraw}
                            />
                            <Route
                                path={`${basepath}/customized`}
                                component={Customized}
                            />
                            <Route
                                path={`${basepath}/pricegroup`}
                                component={PriceGroup}
                            />
                            <Route
                                path={`${basepath}/member`}
                                component={Member}
                            />
                            <Route
                                path={`${basepath}/memberset`}
                                component={Memberset}
                            />
                            <Route path={`${basepath}/page`} component={Page} />
                            <Route
                                exact
                                path={`${basepath}/`}
                                component={Dashboard}
                            />
                            <Route
                                path={`${basepath}/cooperation`}
                                component={Cooperation}
                            />
                            <Route
                                path={`${basepath}/customerdata`}
                                component={CustomerData}
                            />
                            <Route
                                path={`${basepath}/unmemberset`}
                                component={UnMemberset}
                            />
                            <Route
                                path={`${basepath}/search`}
                                component={CustomerKeyword}
                            />
                            <Route
                                path={`${basepath}/specialseller`}
                                component={SpecialSeller}
                            />
                            <Route exact component={NotFound} />

                        </Switch>
                        <AudioDetailModal />
                        <Player />
                    </Content>
                </Layout>
            </Layout>
        );
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Home);
