import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import {
    Form,
    Input,
    Tooltip,
    Icon,
    // Cascader,
    // AutoComplete,
    Upload,
    Avatar,
    Modal,
    Radio,
} from "antd";
import agent from "agent";

import ListErrors from "components/ListErrors";
const { TextArea } = Input;
const plainOptions = [{ label: '超管', value: '@admin.com' }, { label: '音乐小能手', value: '@music.com' }, { label: '市场营销', value: '@marketing.com', },];
function getBase64(file) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });
}


class AdminModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            targetValue: "@admin.com",
        };
        this.onChange = this.onChange.bind(this);
    }
    static propTypes = {
        visible: PropTypes.bool,
        onAdminModalOk: PropTypes.func,
        onAdminModalCancel: PropTypes.func,
    };
    state = {
        fileList: [],

    };

    componentDidMount() {
        console.log(this.props.user)

    }
    static getDerivedStateFromProps(nextProps, prevState) {
        if (!nextProps.visible) {
            return { fileList: [] };
        }
        return null;
    }

    handleSubmit = e => {
        e.preventDefault();
    };
    onChange(e) {
        this.setState({
            targetValue: e.target.value
        })
    }
    handleAdminModalOk = e => {
        const { user, form, onAdminModalOk, onAdminModalCancel } = this.props;
        form.validateFieldsAndScroll((err, values) => {
            console.log(values)
            // check password
            if (values.password || values.confirm) {
                if (!values.password || values.password.length < 6) {
                    form.setFields({
                        password: {
                            value: values.password,
                            errors: [
                                new Error(
                                    `password must be at least 6 chars long`
                                ),
                            ],
                        },
                    });
                    return;
                }
                if (values.confirm !== values.password) {
                    form.setFields({
                        confirm: {
                            value: values.confirm,
                            errors: [
                                new Error(
                                    `Two passwords that you enter is inconsistent`
                                ),
                            ],
                        },
                    });
                    return;
                }
            }
            if (!err) {
                const send = {};
                if (values.password) {
                    send.password = values.password;
                }

                const sendKeys = ["description", "email", "nickname", "avatar",'role'];
                for (const key of sendKeys) {
                    if (values[key] != user[key]) {
                        send[key] = values[key];
                    }
                }
                // update avatar
                const { fileList } = this.state;
                if (fileList.length) {
                    send.avatar = fileList[0];
                }
                let emails = localStorage.getItem('userEmail')

                if(emails != values.email + this.state.targetValue){
                    send.email = values.email+this.state.targetValue
                }
                delete(send['role'])
                if (Object.keys(send).length) {
                    onAdminModalOk({ ...send, adminid: user.adminid });
                } else {
                    onAdminModalCancel();
                }
            }
        });
    };
    handleDeleteAvatarClick = e => {
        e.preventDefault();
        e.stopPropagation();
        this.props.form.setFields({
            avatar: {
                value: "",
            },
        });
    };

    render() {
        const {
            visible,
            confirmLoading,
            user,
            onAdminModalCancel,
        } = this.props;
        const { getFieldDecorator, getFieldValue } = this.props.form;
        if (user && user.email) {
            
            let userEmail = user.email.split("@")
            user.email = userEmail[0]
            if (userEmail[1]) {
                localStorage.setItem('userEmail',user.email+'@'+userEmail[1])
                this.setState({
                    targetValue: '@' + userEmail[1]
                })
            }

        }
        const formItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 8 },
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 16 },
            },
        };
        const tltle = user.adminid
            ? `Edit admin: ${user.adminid}`
            : `Create Admin`;
        const { fileList } = this.state;
        const uploadProps = {
            onRemove: file => {
                this.setState(state => {
                    return {
                        fileList: [],
                    };
                });
                this.props.form.setFields({
                    avatar: {
                        value: user.avatar,
                    },
                });
            },
            beforeUpload: file => {
                this.setState(state => ({
                    fileList: [file],
                }));
                getBase64(file).then(data => {
                    this.props.form.setFields({
                        avatar: {
                            value: data,
                        },
                    });
                });

                return false;
            },
            accept: "image/*",
            listType: "picture",
            fileList,
        };

        return (
            <Modal
                title={tltle}
                centered
                destroyOnClose
                confirmLoading={confirmLoading}
                visible={visible}
                onOk={this.handleAdminModalOk}
                onCancel={onAdminModalCancel}
            >
                <ListErrors errors={this.props.errors} />


                <Form onSubmit={this.handleSubmit} >
                    <div style={{ textAlign: "center", marginBottom: " 30px" }}>
                        {getFieldDecorator("avatar", {
                            rules: [
                                {
                                    required: false,
                                },
                            ],
                            initialValue: user.avatar,
                        })(<Input type="hidden" />)}
                        <Upload {...uploadProps}>
                            <Avatar
                                key={getFieldValue("avatar")}
                                size={64}
                                icon="user"
                                src={agent.getApiUrl(getFieldValue("avatar"))}
                            />
                        </Upload>
                        {fileList.length == 0 && (
                            <Link
                                style={{ display: "block" }}
                                to="#"
                                onClick={this.handleDeleteAvatarClick}
                            >
                                Delete
                            </Link>
                        )}
                    </div>

                    <Form.Item {...formItemLayout} label="role" >
                        {getFieldDecorator("role", {
                            rules: [
                                {
                                    required: false,
                                    message: "The input is not valid E-mail!",
                                },
                            ],
                            initialValue: user.role,
                        })(<Radio.Group onChange={this.onChange} defaultChecked={this.state.targetValue} value={this.state.targetValue} options={plainOptions}></Radio.Group>)}

                    </Form.Item>
                    <Form.Item {...formItemLayout} label="E-mail">
                        {getFieldDecorator("email", {
                            rules: [
                                {
                                    required: false,
                                    type: "",
                                    message: "The input is not valid E-mail!",
                                },
                            ],
                            initialValue: user.email,
                        })(<Input style={{ width: '300px' }} />)}
                        <span>{this.state.targetValue}</span>
                    </Form.Item>
                    <Form.Item {...formItemLayout} label="Description">
                        {getFieldDecorator("description", {
                            rules: [
                                {
                                    required: false,
                                },
                            ],
                            initialValue: user.description,
                        })(<TextArea />)}
                    </Form.Item>
                    <Form.Item {...formItemLayout} label="Password">
                        {getFieldDecorator("password", {
                            rules: [
                                {
                                    required: false,
                                    message: "Please input your password!",
                                },
                            ],
                        })(<Input type="password" />)}
                    </Form.Item>
                    <Form.Item {...formItemLayout} label="Confirm Password">
                        {getFieldDecorator("confirm", {
                            rules: [
                                {
                                    required: false,
                                    message: "Please confirm your password!",
                                },
                            ],
                        })(<Input type="password" />)}
                    </Form.Item>
                    <Form.Item
                        {...formItemLayout}
                        label={
                            <span>
                                Nickname&nbsp;
                                <Tooltip title="What do you want others to call you?">
                                    <Icon type="question-circle-o" />
                                </Tooltip>
                            </span>
                        }
                    >
                        {getFieldDecorator("nickname", {
                            rules: [
                                {
                                    required: false,
                                    message: "Please input your nickname!",
                                    whitespace: true,
                                },
                            ],
                            initialValue: user.nickname,
                        })(<Input />)}
                    </Form.Item>
                </Form>
            </Modal>
        );
    }
}

export default Form.create({ name: "AdminModal" })(AdminModal);
