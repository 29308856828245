import React from "react";
import { Table, Button, Modal } from "antd";
import agent from "agent";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
    PLAYLIST_PAGE_LOADED,
    PLAYLIST_PAGE_UNLOADED,
    PLAYLIST_INFO_EDIT,
    PLAYLIST_INFO_CRATE,
    PLAYLIST_INFO_DELETE,
    PLAYLIST_INFO_MODAL_SHOW,
    PLAYLIST_INFO_MODAL_HIDE,
} from "constants/actionTypes";

import ReLogin from "components/ReLogin";
import CustomerModal from "./CustomerModal";

import columnsCreate from "./info-columns";
const columns = columnsCreate();

const mapStateToProps = state => ({
    ...state.audio,
});

const staticParams = {};
const mapDispatchToProps = dispatch => {
    const props = {
        onLoad: (pager, params) => {
            if (pager) {
                staticParams.onLoad = { pager, params };
            } else if (staticParams.onLoad) {
                pager = staticParams.onLoad.pager;
                params = staticParams.onLoad.params;
            } else {
                return;
            }
            dispatch({
                type: PLAYLIST_PAGE_LOADED,
                pager,
                payload: agent.Audio.playlistInfo.get(params),
            });
        },
        onUnload: () => dispatch({ type: PLAYLIST_PAGE_UNLOADED }),

        onEditPlaylist: send =>
            dispatch({
                type: PLAYLIST_INFO_EDIT,
                send,
                payload: agent.Audio.playlistInfo.put(send),
            }),
        onCreatePlaylist: (send, callback) =>
            dispatch({
                type: PLAYLIST_INFO_CRATE,
                payload: agent.Audio.playlistInfo.post(send),
                callback,
            }),

        onDeletePlaylist: send =>
            dispatch({
                type: PLAYLIST_INFO_DELETE,
                payload: agent.Audio.playlistInfo.delete(send),
                callback: () => {
                    // load current page
                    props.onLoad();
                },
            }),
        onShowModal: payload =>
            dispatch({
                type: PLAYLIST_INFO_MODAL_SHOW,
                payload,
            }),
        onHideModal: () =>
            dispatch({
                type: PLAYLIST_INFO_MODAL_HIDE,
            }),
    };
    return props;
};

class Audio extends React.Component {
    static propTypes = {
        pagination: PropTypes.object.isRequired,
        results: PropTypes.object.isRequired,
        loading: PropTypes.bool.isRequired,
        onLoad: PropTypes.func.isRequired,
        onUnload: PropTypes.func.isRequired,
        fetchError: PropTypes.bool.isRequired,
        onEditPlaylist: PropTypes.func.isRequired,
        onCreatePlaylist: PropTypes.func.isRequired,

        updateStatus: PropTypes.shape({
            audioid: PropTypes.string.isRequired,
            status: PropTypes.string.isRequired,
            loading: PropTypes.bool.isRequired,
        }).isRequired,
    };

    componentDidMount() {
        this.onLoad();
    }

    onLoad = () => {
        const { onLoad, pagination } = this.props;
        const pager = { ...pagination };
        pager.current = 1;
        const params = {
            page: pager.current,
            size: pagination.pageSize,
            orderby: "-id",
        };
        onLoad(pager, params);
    };

    componentWillUnmount() {
        this.props.onUnload();
    }

    handleTableChange = (pagination, filters, sorter) => {
        const pager = { ...this.props.pagination };
        pager.current = pagination.current;
        let orderby = "-id";
        if (sorter && sorter.field) {
            orderby = sorter.field;
            if (sorter.order === "descend") {
                orderby = "-" + sorter.field;
            }
        }

        const params = {
            page: pager.current,
            size: pagination.pageSize,
            orderby,
            ...filters,
        };

        this.props.onLoad(pager, params);
    };

    handlePlaylistModalOk = send => {
        const { onCreatePlaylist, onEditPlaylist, onHideModal } = this.props;
        if (typeof send.playlistid !== "undefined") {
            // update
            return onEditPlaylist(send);
        }
        // create
        const callback = () => {
            this.onLoad();
            onHideModal();
        };
        onCreatePlaylist(send, callback.bind(this));
    };

    handleAdd = e => {
        this.props.onShowModal();
    };

    render() {
        const {
            results,
            pagination,
            loading,
            fetchError,
            onShowModal,
            onHideModal,
            onDeletePlaylist,
            modal,
        } = this.props;
        if (fetchError) {
            return <ReLogin />;
        }
        pagination.total = results.total;

        const data = results.infos || [];

        for (const record of data) {
            // record.loading = true;
            record.handleDeleteClick = () => {
                Modal.confirm({
                    title: "确认删除分类吗？操作不可恢复！",
                    content: `将要删除分类： ${record.title}`,
                    okText: "确认删除",
                    okType: "danger",
                    cancelText: "No",
                    okButtonProps: {
                        disabled: record.updating,
                    },
                    onOk: () => {
                        onDeletePlaylist(record);
                    },
                });
            };

            record.handleEditClick = e => {
                e.preventDefault();
                e.stopPropagation();
                const send = {
                    ...record,
                };
                onShowModal(send);
            };
        }
        const { data: modalData, visible, confirmLoading } = modal;
        const userModalProps = {
            onModalOk: this.handlePlaylistModalOk.bind(this),
            onModalCancel: onHideModal,
            modalData,
            confirmLoading,
            visible: visible,
        };
        return (
            <div>
                <Button
                    onClick={this.handleAdd}
                    type="primary"
                    style={{ marginBottom: 16 }}
                >
                    添加分类
                </Button>
                <Table
                    className="c382-audio-list"
                    columns={columns}
                    rowKey={record => record.id}
                    dataSource={data}
                    pagination={pagination}
                    loading={loading}
                    onChange={this.handleTableChange}
                />
                <CustomerModal {...userModalProps} />
            </div>
        );
    }
}
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Audio);
