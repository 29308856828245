import {
    COOPERATION_LIST_LOADED,
    COOPERATION_ADD_SHOW,
    COOPERATION_ADD_HIDE,
    COOPERATION_LIST_DELETE,
} from "../constants/actionTypes";

const defaultState = {
    results: {result: []},
    // pagination: { pageSize: 10, current: 1 },
    loading: false,
    fetchError: false,
    updateStatus: { userid: "", status: "", loading: false },
    userEdit: {},
    userEditModal: false,
    confirmLoading: false,
    modal : {
        data: null,
        visible: false,
    },
    addShow:false,
};
export default (state = { ...defaultState }, action) => {
    switch (action.type) {
        case COOPERATION_LIST_LOADED: {
            return {
                ...state,
                fetchError: !!action.error,
                results: action.payload,
                loading: false,
            };
        }
        case COOPERATION_ADD_SHOW: {
            return { 
                ...state,
                addShow:true,
            };
        }
        case COOPERATION_LIST_DELETE: {
            return{
                ...state,
                loading:false,
            };
        }
        case COOPERATION_ADD_HIDE: {
            return { 
                ...state,
                addShow:false,
            };
        }
        default:
            return state;
    }
};
