import React from "react";
import { Table, Modal } from "antd";
import agent from "agent";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
    AUDIO_PAGE_UNLOADED,
    PLAYLIST_AUDIO_DELETE,
} from "constants/actionTypes";
import { loadPlaylistInfoApp, onOnePlaylistPageLoad } from "dispatch";
import ReLogin from "components/ReLogin";

import columnsCreate from "./one-columns";
const columns = columnsCreate();

const mapStateToProps = state => ({
    ...state.audio,
    _playlistInfo: state.app.playlistInfo,
});

const mapDispatchToProps = dispatch => {
    const props = {
        onUnload: () => dispatch({ type: AUDIO_PAGE_UNLOADED }),
        onDeletePlaylistAudio: send =>
            dispatch({
                type: PLAYLIST_AUDIO_DELETE,
                send,
                payload: agent.Audio.playlist.delete(send),
                callback: () => {
                    // load current page
                    onOnePlaylistPageLoad();
                },
            }),
    };
    return props;
};

class Audio extends React.Component {
    static propTypes = {
        pagination: PropTypes.object.isRequired,
        results: PropTypes.object.isRequired,
        loading: PropTypes.bool.isRequired,
        onUnload: PropTypes.func.isRequired,
        onDeletePlaylistAudio: PropTypes.func.isRequired,
        fetchError: PropTypes.bool.isRequired,
        _playlistInfo: PropTypes.object,
    };

    componentDidMount() {
        const { pagination, match } = this.props;
        const pager = { ...pagination };
        pager.current = 1;
        const params = {
            page: pager.current,
            size: pagination.pageSize,
            orderby: "-id",
            playlist_id: match.params.playlist_id,
        };
        onOnePlaylistPageLoad(pager, params);
        loadPlaylistInfoApp();
    }

    componentWillUnmount() {
        this.props.onUnload();
    }

    handleTableChange = (pagination, filters, sorter) => {
        const pager = { ...this.props.pagination };
        pager.current = pagination.current;
        let orderby = "-id";
        if (sorter && sorter.field) {
            orderby = sorter.field;
            if (sorter.order === "descend") {
                orderby = "-" + sorter.field;
            }
        }

        const { match } = this.props;
        const params = {
            page: pager.current,
            size: pagination.pageSize,
            orderby,
            ...filters,
            playlist_id: match.params.playlist_id,
        };

        onOnePlaylistPageLoad(pager, params);
    };

    render() {
        const {
            results,
            pagination,
            loading,
            history,
            fetchError,
            onDeletePlaylistAudio,
            match,
            _playlistInfo,
        } = this.props;
        if (fetchError) {
            return <ReLogin history={history} />;
        }
        pagination.total = results.total;

        const data = results.audios || [];

        for (const record of data) {
            // record.loading = true;
            record.handleDeleteClick = () => {
                Modal.confirm({
                    title: "确认从分类移除音乐吗？操作不可恢复！",
                    content: `将要从分类移除音乐： ${record.title}`,
                    okText: "确认删除",
                    okType: "danger",
                    cancelText: "No",
                    okButtonProps: {
                        disabled: record.updating,
                    },
                    onOk: () => {
                        onDeletePlaylistAudio(record.playlist);
                    },
                });
            };
        }
        const playlist_id = match.params.playlist_id;
        const playlistInfo = _playlistInfo || {};
        const info = playlistInfo[playlist_id] || {};
        return (
            <Table
                title={currentPageData => {
                    const title = `分类： ${info.title}`;
                    return title;
                }}
                className="c382-audio-list"
                columns={columns}
                rowKey={record => record.audioid}
                dataSource={data}
                pagination={pagination}
                loading={loading}
                onChange={this.handleTableChange}
            />
        );
    }
}
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Audio);
