import {
    CAPTCHA_PAGE_LOADED,
    CAPTCHA_PAGE_UNLOADED,
    ASYNC_START
} from "../constants/actionTypes";

const defaultState = {
    results: {},
    pagination: {pageSize: 10, current: 1},
    loading: false,
    fetchError: false
};
export default (state = {...defaultState}, action) => {
    switch (action.type) {
        case CAPTCHA_PAGE_LOADED: {
            const pagination = action.pager;
            if (!action.error) {
                pagination.total = action.payload.total;
            }
            return {
                ...state,
                fetchError: !!action.error,
                results: action.payload,
                pagination,
                loading: false
            };
        }
        case ASYNC_START:
            if (action.subtype === CAPTCHA_PAGE_LOADED) {
                return {...state, loading: true};
            }
            return state;
        case CAPTCHA_PAGE_UNLOADED:
            return {...defaultState};
        default:
            return state;
    }
};
