import {
    PAGE_LIST_LOADED,
    PAGE_LIST_UNLOADED,
    PAGE_LIST_EDIT,
    PAGE_LIST_CRATE,
    PAGE_LIST_DELETE,
    PAGE_LIST_MODAL_SHOW,
    PAGE_LIST_MODAL_HIDE,
    ASYNC_START,
} from "../constants/actionTypes";

const defaultState = {
    results: {},
    pagination: { pageSize: 10, current: 1 },
    loading: false,
    fetchError: false,
    modal: {
        data: null,
        visible: false,
    },
};
export default (state = { ...defaultState }, action) => {
    switch (action.type) {
        case PAGE_LIST_LOADED: {
            const pagination = action.pager;
            if (!action.error) {
                pagination.total = action.payload.total;
            }
            return {
                ...state,
                fetchError: !!action.error,
                results: action.payload,
                pagination,
                loading: false,
            };
        }
        case PAGE_LIST_DELETE:
        case PAGE_LIST_CRATE: {
            return { ...state, confirmLoading: false, loading: false };
        }
        case PAGE_LIST_EDIT: {
            if (action.error) {
                return { ...state, confirmLoading: false, loading: false };
            }
            const modal = {
                data: null,
                visible: false,
            };
            const output = {
                ...state,
                modal,
                confirmLoading: false,
                loading: false,
            };
            if (Array.isArray(state.results.data)) {
                const data = [];
                for (const record of state.results.data) {
                    if (record.id === action.send.id) {
                        data.push(action.payload.data);
                        continue;
                    }
                    data.push(record);
                }
                output.results.data = data;
            }
            return output;
        }
        case PAGE_LIST_MODAL_SHOW: {
            const modal = {
                data: action.payload,
                visible: true,
            };
            return {
                ...state,
                modal,
            };
        }
        case PAGE_LIST_MODAL_HIDE: {
            const modal = {
                data: null,
                visible: false,
            };
            return {
                ...state,
                modal,
            };
        }

        case ASYNC_START: {
            if ([PAGE_LIST_LOADED, PAGE_LIST_DELETE].includes(action.subtype)) {
                return { ...state, loading: true };
            }
            if ([PAGE_LIST_CRATE, PAGE_LIST_EDIT].includes(action.subtype)) {
                return { ...state, confirmLoading: true, loading: true };
            }
            return state;
        }
        case PAGE_LIST_UNLOADED:
            return { ...defaultState };
        default:
            return state;
    }
};
