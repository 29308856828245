export const APP_LOAD = "APP_LOAD";
export const REDIRECT = "REDIRECT";
// export const HOME_PAGE_LOADED = "HOME_PAGE_LOADED";
export const HOME_PAGE_UNLOADED = "HOME_PAGE_UNLOADED";
export const PROFILE_PAGE_LOADED = "PROFILE_PAGE_LOADED";
export const PROFILE_PAGE_UNLOADED = "PROFILE_PAGE_UNLOADED";
export const LOGIN = "LOGIN";
export const LOGOUT = "LOGOUT";
export const REGISTER = "REGISTER";
export const LOGIN_PAGE_UNLOADED = "LOGIN_PAGE_UNLOADED";
export const REGISTER_PAGE_UNLOADED = "REGISTER_PAGE_UNLOADED";
export const ASYNC_START = "ASYNC_START";
export const ASYNC_END = "ASYNC_END";
export const NEED_SIGIN = "-403 REQUIRE SIGNIN";
// jgq
export const CURRENT_USER = "CURRENT_USER";
export const ADMIN_PAGE_LOADED = "ADMIN_PAGE_LOADED";
export const ADMIN_PAGE_UNLOADED = "ADMIN_PAGE_UNLOADED";
export const AUDIO_PAGE_LOADED = "AUDIO_PAGE_LOADED";
export const AUDIO_PAGE_UNLOADED = "AUDIO_PAGE_UNLOADED";
export const AUDIO_STATUS_UPDATE = "AUDIO_STATUS_UPDATE";
export const AUDIO_DETAIL_HIDE = "AUDIO_DETAIL_HIDE";
export const AUDIO_DETAIL_SHOW = "AUDIO_DETAIL_SHOW";
export const AUDIO_DETAIL_LOAD = "AUDIO_DETAIL_LOAD";

export const AUDIO_CONDITION_ALL_APP = "AUDIO_CONDITION_ALL_APP";
export const SUSUME_AUDIO_DELETE = "SUSUME_AUDIO_DELETE";
export const SUSUME_AUDIO_ADD = "SUSUME_AUDIO_ADD";

export const ADMIN_STATUS_UPDATE = "ADMIN_STATUS_UPDATE";
export const ADMIN_EDIT = "ADMIN_EDIT";
export const ADMIN_EDIT_CANCEL = "ADMIN_EDIT_CANCEL";
export const ADMIN_EDIT_OK = "ADMIN_EDIT_OK";
export const ADMIN_CRATE = "ADMIN_CRATE";

export const CAPTCHA_PAGE_LOADED = "CAPTCHA_PAGE_LOADED";
export const CAPTCHA_PAGE_UNLOADED = "CAPTCHA_PAGE_UNLOADED";

export const USER_PAGE_UNLOADED = "USER_PAGE_UNLOADED";
export const USER_PAGE_LOADED = "USER_PAGE_LOADED";
export const USER_STATUS_UPDATE = "USER_STATUS_UPDATE";
export const USER_EDIT = "USER_EDIT";
export const USER_QRCODE = "USER_QRCODE";
export const USER_EDIT_OK = "USER_EDIT_OK";
export const USER_EDIT_CANCEL = "USER_EDIT_CANCEL";
export const USER_EDIT_CANCELS = "USER_EDIT_CANCELS";
export const USER_CRATE = "USER_CRATE";

export const PRODUCER_PAGE_LOADED = "PRODUCER_PAGE_LOADED";
export const PRODUCER_PAGE_UNLOADED = "PRODUCER_PAGE_UNLOADED";
export const PRODUCER_STATUS_UPDATE = "PRODUCER_STATUS_UPDATE";

export const ORDER_PAGE_LOADED = "ORDER_PAGE_LOADED";
export const ORDER_PAGE_UNLOADED = "ORDER_PAGE_UNLOADED";
export const ORDER_DELETE = "ORDER_DELETE";
export const ORDER_PURCHASE = "ORDER_PURCHASE";
export const ORDER_MODAL_SHOW = "ORDER_MODAL_SHOW";
export const ORDER_MODAL_HIDE = "ORDER_MODAL_HIDE";
export const ORDER_UPDATE_PRICE = "ORDER_UPDATE_PRICE";
export const DOWNLOAD_AUDIO = "DOWNLOAD_AUDIO";

export const PURCHASE_PAGE_LOADED = "PURCHASE_PAGE_LOADED";
export const PURCHASE_PAGE_UNLOADED = "PURCHASE_PAGE_UNLOADED";
export const PURCHASE_STATUS_UPDATE = "PURCHASE_STATUS_UPDATE";
export const PURCHASE_PAGE_OPEN = "PURCHASE_PAGE_OPEN";

export const DASHBOARD_PAGE_UNLOADED = "DASHBOARD_PAGE_UNLOADED";
export const DASHBOARD_PAGE_LOADED = "DASHBOARD_PAGE_LOADED";

export const USUR_DERIVE = "USUR_DERIVE";
export const MEMBER_DERIVE = "MEMBER_DERIVE";
export const PURCHASE_DERIVE = "PURCHASE_DERIVE";
export const MEMBER_DERIVE_FALSE = "MEMBER_DERIVE_FALSE";
export const USUR_DERIVE_FALSE = "USUR_DERIVE_FALSE";
export const PURCHASE_DERIVE_FALSE = "PURCHASE_DERIVE_FALSE";


export const PLAYLIST_PAGE_LOADED = "PLAYLIST_PAGE_LOADED";
export const PLAYLIST_PAGE_UNLOADED = "PLAYLIST_PAGE_UNLOADED";
export const PLAYLIST_INFO_EDIT = "PLAYLIST_INFO_EDIT";
export const PLAYLIST_INFO_MODAL_SHOW = "PLAYLIST_INFO_MODAL_SHOW";
export const PLAYLIST_INFO_MODAL_HIDE = "PLAYLIST_INFO_MODAL_HIDE";
export const PLAYLIST_INFO_APP = "PLAYLIST_INFO_APP";
export const PLAYLIST_AUDIO_DELETE = "PLAYLIST_AUDIO_DELETE";
export const PLAYLIST_AUDIO_REPLACE = "PLAYLIST_AUDIO_REPLACE";
export const PLAYLIST_INFO_CRATE = "PLAYLIST_INFO_CRATE";
export const PLAYLIST_INFO_DELETE = "PLAYLIST_INFO_DELETE";

export const WITHDRAW_PAGE_LOADED = "WITHDRAW_PAGE_LOADED";
export const WITHDRAW_PAGE = "WITHDRAW_PAGE";
export const WITHDRAW_PAGE_UNLOADED = "WITHDRAW_PAGE_UNLOADED";
export const WITHDRAW_STATUS_UPDATE = "WITHDRAW_STATUS_UPDATE";

export const PAGE_LIST_LOADED = "PAGE_LIST_LOADED";
export const PAGE_LIST_UNLOADED = "PAGE_LIST_UNLOADED";
export const PAGE_LIST_EDIT = "PAGE_LIST_EDIT";
export const PAGE_LIST_CRATE = "PAGE_LIST_CRATE";
export const PAGE_LIST_DELETE = "PAGE_LIST_DELETE";
export const PAGE_LIST_MODAL_SHOW = "PAGE_LIST_MODAL_SHOW";
export const PAGE_LIST_MODAL_HIDE = "PAGE_LIST_MODAL_HIDE";
export const BATCH_AUDIT = "BATCH_AUDIT";
export const AUDIO_MODAL_SHOW = "AUDIO_MODAL_SHOW";
export const AUDIO_MODAL_HIDE = "AUDIO_MODAL_HIDE";
export const ADMIN_UPDATE_AUDIO = "ADMIN_UPDATE_AUDIO";
export const PLAY_AUDIO = "PLAY_AUDIO";
export const USER_CUSTOMIZED_UPDATE = "USER_CUSTOMIZED_UPDATE";

//价格组
export const PRICE_GROUP_LOADED = "PRICE_GROUP_LOADED";
export const PRICE_GROUP_MODAL_SHOW = "PRICE_GROUP_MODAL_SHOW";
export const PRICE_GROUP_MODAL_HIDE = "PRICE_GROUP_MODAL_HIDE";
export const PRICE_GROUP_ADD_SHOW = "PRICE_GROUP_ADD_SHOW";
export const PRICE_GROUP_ADD_HIDE = "PRICE_GROUP_ADD_HIDE";
export const ADD_PRICE_GROUP = "ADD_PRICE_GROUP";
export const PRICE_GROUP_UPDATE = "PRICE_GROUP_UPDATE";
export const PRICE_GROUP_UPDATE_STATUS = "PRICE_GROUP_UPDATE_STATUS";
export const PRODUCER_PRICE_STATUS_UPDATE = "PRODUCER_PRICE_STATUS_UPDATE";

// 会员

export const MEM_BER = "MEM_BER";
export const INQUIRE_MEMBER = "INQUIRE_MEMBER";
export const OPEN_MEMBER = "OPEN_MEMBER";

// 优惠设置
export const MEMBER_SET_FONT = "MEMBER_SET_FONT";
export const MEMBER_SET_ADD = "MEMBER_SET_ADD";
export const MEMBER_SET_DEL = "MEMBER_SET_DEL";
export const COUPONCODE_FONT = "COUPONCODE_FONT";
export const COUPONCODE_DEL = "COUPONCODE_DEL";
export const USER_PAGE_LOADEDA = 'USER_PAGE_LOADEDA'
export const USER_PAGE_LOADEDB = 'USER_PAGE_LOADEDB'
export const AMEND_TIME_ADD = 'AMEND_TIME_ADD'
export const AMEND_TIME_HIDE = 'AMEND_TIME_HIDE'
export const MEMMBER_UPDATE = 'MEMMBER_UPDATE'


// 第三方合作
export const COOPERATION_LIST_LOADED = "COOPERATION_LIST_LOADED";
export const COOPERATION_ADD_LIST = "COOPERATION_ADD_LIST";
export const COOPERATION_ADD_SHOW = "COOPERATION_ADD_SHOW";
export const COOPERATION_ADD_HIDE = "COOPERATION_ADD_HIDE";
export const COOPERATION_LIST_DELETE = "COOPERATION_LIST_DELETE";
export const COOPERATION_LIST_ENABLE = "COOPERATION_LIST_ENABLE";
export const COOPERATION_LIST_DOWNLOAD = "COOPERATION_LIST_DOWNLOAD";
