import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Form, Modal, Input } from "antd";
//import agent from "agent";
import "./PriceUpdateModal.less";
const mapStateToProps = state => ({
});

const mapDispatchToProps = dispatch => ({});

class PriceAddModal extends React.Component {
    static propTypes = {
        addShow: PropTypes.bool,
        onModalOk: PropTypes.func,
        onModalCancel: PropTypes.func,
        confirmLoading:PropTypes.bool,
    };

    handleSubmit = e => {
        e.preventDefault();
    };
    
    //提交数据
    handleAddModalOk = e => {
        const { form, onModalOk } = this.props;
        form.validateFieldsAndScroll((err, values) => {
            const send={
                title:values.title,
            }
            onModalOk(send)
        })
    };
    
    render() {
        const {
            addShow,
            confirmLoading,
            onModalCancel,
        } = this.props;
        if (!addShow) {
            return null;
        }
        const { getFieldDecorator } = this.props.form;
        const formItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 8 },
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 16 },
            },
        };
        const tltle =  `新建价格组`;
        const no =false;
        return (
            <Modal
                title={tltle}
                centered
                destroyOnClose
                confirmLoading={confirmLoading}
                visible={addShow}
                onOk={this.handleAddModalOk}
                onCancel={onModalCancel}
                maskClosable={no}
            > 
                <Form onSubmit={this.handleSubmit}>
                    <Form.Item {...formItemLayout} label="名称">
                        {getFieldDecorator("title", {
                            rules: [
                                {
                                    required: true,
                                    message: "请输入名称!",
                                },
                            ],
                        })(<Input />)}
                    </Form.Item>
                </Form>
            </Modal>
        );
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Form.create({ name: "PriceAddModal" })(PriceAddModal));
