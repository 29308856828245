import React from "react";
import { Table, Divider, Avatar, Select, Button, DatePicker } from "antd";
import { Link } from "react-router-dom";
import agent from "agent";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import helper from "utils/helper";

import ExportJsonExcel from 'js-export-excel';
import {
    USER_PAGE_UNLOADED,
    USER_PAGE_LOADED,
    USER_STATUS_UPDATE,
    USER_EDIT,
    USER_QRCODE,
    USER_EDIT_CANCEL,
    USER_EDIT_CANCELS,
    USER_EDIT_OK,
    USER_CRATE,
    WITHDRAW_PAGE,
    USUR_DERIVE,
    USUR_DERIVE_FALSE
} from "constants/actionTypes";
const { RangePicker } = DatePicker;
import Modal from "./Modal";
import QrCode from "./qrCode";
import ReLogin from "components/ReLogin";
import { producerStatus } from "config/producerStatus";

const Option = Select.Option;
const columns = [
    {
        title: "Id",
        dataIndex: "id",
        sorter: true,
        defaultSortOrder: "descend",
    },
    {
        title: "Avatar",
        dataIndex: "avatar",
        render: (avatar, record) => {
            return (
                <div>
                    <Link to="#" onClick={record.handleEditClick}>
                        <Avatar
                            key={avatar}
                            src={agent.getApiUrl(avatar)}
                            size="large"
                            icon="user"
                        />
                    </Link>
                </div>
            );
        },
    },
    {
        title: "Email/Phone/Nickname",
        key: "email/phone/nickname",
        render: (avatar, record) => {
            return (
                <span>
                    <span>ID: </span>
                    {record.userid}
                    <Divider />
                    <span>Name: </span>
                    {record.nickname}
                    <Divider />
                    <span>Email: </span>
                    <a href={`mailto:${record.email}`}>{record.email}</a>
                    <Divider />
                    <span>Phone: </span>
                    {record.phone}
                    <Divider />
                    <span>注册来源: </span>
                    {record.source}
                    <Divider />
                    <span>注册时间: </span>
                    {helper.showDate(record.create_at)}
                </span>
            );
        },
    },
    {
        title: "Status",
        dataIndex: "status",
        filters: [
            { text: "Normal", value: "normal" },
            { text: "Blocked", value: "blocked" },
            // { text: "Deleted", value: "deleted" },
        ],
        render: (status, record) => {
            return (
                <Select
                    defaultValue={status}
                    style={{ width: 120 }}
                    onChange={record.handleStatusChange}
                    loading={record.loading}
                    disabled={record.loading}
                >
                    <Option value="normal">Normal</Option>
                    <Option value="blocked">Blocked</Option>
                </Select>
            );
        },
    },
    {
        title: "卖家",
        dataIndex: "producer",
        render: (producer, record) => {
            // TODO: 实现卖家详细页面
            // if (producer == producerStatus.YES) {
            //     return (
            //         <Link to={`/producer/profile/${record.userid}`}>YES</Link>
            //     );
            // }
            return <span>{producerStatus.show(producer)}</span>;
        },
    },
    {
        title: "Action",
        key: "action",
        render: (text, record) => (
            <span>
                <Link to="#" onClick={record.handleEditClick}>
                    Edit
                </Link>
            </span>
        ),
    },
    {
        title: "二维码",
        key: "erweima",
        render: (text, record) => (
            <span>
                <Link to="#" onClick={record.handleEditClicks}>
                    查看二维码
                </Link>
            </span>
        ),
    },
];

const mapStateToProps = state => ({
    ...state.user,
    exports: state.withdraw.exports,
});

const mapDispatchToProps = dispatch => ({
    onLoad: (pager, payload) =>
        dispatch({ type: USER_PAGE_LOADED, pager, payload }),
    onUnload: () => dispatch({ type: USER_PAGE_UNLOADED }),
    onUpdateStatus: send =>
        dispatch({
            type: USER_STATUS_UPDATE,
            send,
            payload: agent.User.put(send),
        }),
    onEditUser: payload =>
        dispatch({
            type: USER_EDIT,
            payload,
        }),
    onEditQrCode: payload =>
        dispatch({
            type: USER_QRCODE,
            payload,
        }),
    onCreateUser: payload =>
        dispatch({
            type: USER_CRATE,
            payload,
        }),
    onDerive: payload => {
        dispatch({
            type: USUR_DERIVE,
            payload,
        })
    },
    fsDerive: payload => {
        dispatch({
            type:USUR_DERIVE_FALSE,
            payload
        })
    },
    onUserModalOk: (send, callback) => {
        if (send.userid) {
            return dispatch({
                type: USER_EDIT_OK,
                send,
                payload: agent.User.put(send),
            });
        }
        return dispatch({
            type: USER_EDIT_OK,
            send,
            payload: agent.User.post(send),
            callback,
        });
    },
    onExports: params =>
        dispatch({
            type: WITHDRAW_PAGE,
            params,
            payload: agent.Withdraw.out(params),
        }),
    onUserModalCancel: payload =>
        dispatch({
            type: USER_EDIT_CANCEL,
            payload,
        }),
    onUserModalCancels: payload =>
        dispatch({
            type: USER_EDIT_CANCELS,
            payload,
        }),
});

class List extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            keyword: "",
            source: "",
            begin: "",
            end: "",
            sta: "",
            type: 0,
        };
    }
    static propTypes = {
        pagination: PropTypes.object.isRequired,
        results: PropTypes.object.isRequired,
        loading: PropTypes.bool.isRequired,
        onLoad: PropTypes.func.isRequired,
        onUnload: PropTypes.func.isRequired,
        onUpdateStatus: PropTypes.func.isRequired,
        onEditUser: PropTypes.func.isRequired,
        onEditUQrCode: PropTypes.func.isRequired,
        userEdit: PropTypes.object.isRequired,
        onUserModalOk: PropTypes.func.isRequired,
        onUserModalCancel: PropTypes.func.isRequired,
        onUserModalCancels: PropTypes.func.isRequired,
        fetchError: PropTypes.bool.isRequired,
        exports: PropTypes.string,
        onExports: PropTypes.func.isRequired,
        userEditModal: PropTypes.bool.isRequired,
        userEditModals: PropTypes.bool.isRequired,
        updateStatus: PropTypes.shape({
            userid: PropTypes.string.isRequired,
            status: PropTypes.string.isRequired,
            loading: PropTypes.bool.isRequired,
        }).isRequired,
    };

    componentDidMount() {
        window.type = 0
        this.onLoad();
    }

    onLoad = () => {
        const { onLoad, pagination } = this.props;
        const pager = { ...pagination };
        pager.current = 1;
        const params = {
            page: pager.current,
            size: pagination.pageSize,
            orderby: "-id",
        };
        onLoad(pager, agent.User.get(params));
    };
    componentDidUpdate(prevProps) {
        if (prevProps.userDerive === true && this.props.userDerive === true) {
            this.downloadExcel()
            return
        }
    }
    downloadExcel(order) {
        const { results ,fsDerive} = this.props;  //从props中获取数据源
        let option = {};  //option代表的就是excel文件
        let dataTable = [];  //excel文件中的数据内容
        let result = results.users
        if (result && result.length > 0) {
            for (let i in result) {  //循环获取excel中每一行的数据
                let obj = {
                    'Id': String(result[i].id) || '',
                    '用户Id': result[i].userid,
                    '用户名': String(result[i].nickname),
                    '用户邮箱': result[i].email,
                    '用户手机号': result[i].phone,
                    '用户注册时间': helper.showDate(result[i].create_at) || '',
                    '用户状态': result[i].status,
                    '是否是卖家': producerStatus.show(result[i].producer),
                    '用户来源': result[i].source,
                }
                dataTable.push(obj);  //设置excel中每列所获取的数据源
            }
        }
        option.fileName = '用户表';  //excel文件名称
        option.datas = [
            {
                sheetData: dataTable,  //excel文件中的数据源
                sheetName: '用户表',  //excel文件中sheet页名称
                sheetFilter: ['Id', '用户Id', '用户名', '用户邮箱', '用户手机号', '用户注册时间', '用户状态', '是否是卖家','用户来源'],  //excel文件中需显示的列数据
                sheetHeader: ['Id', '用户Id', '用户名', '用户邮箱', '用户手机号', '用户注册时间', '用户状态', '是否是卖家','用户来源'],   //excel文件中每列的表头名称
                columnWidths: ['5', '15', '7', '9', '6', '9', '5', '5','5',]
            }
        ]
        let toExcel = new ExportJsonExcel(option);  //生成excel文件
        toExcel.saveExcel();  //下载excel文件
        fsDerive()
    }
    handlSearchWithdrawss = e => {
        const { onLoad, pagination, onDerive } = this.props;
        const key = this.state.keyword;
        const pager = { ...pagination };
        pager.current = 1;
        const params = {
            page: pager.current,
            size: pagination.total,
            orderby: "-id",
        };
        if (this.state.begin) {
            params.start_time = this.state.begin
        }
        if (this.state.end) {
            params.end_time = this.state.end
        }
        if (key) {
            params.username = key
        }
        onDerive()
        onLoad(pager, agent.User.get(params));
    }
    handleTableChange = (pagination, filters, sorter) => {
        const pager = { ...this.props.pagination };
        pager.current = pagination.current;
        let orderby = "-id";
        if (sorter && sorter.field) {
            orderby = sorter.field;
            if (sorter.order === "descend") {
                orderby = "-" + sorter.field;
            }
        }
        const key = this.state.keyword
        const params = {
            page: pager.current,
            size: pagination.pageSize,
            orderby,
            ...filters,
        };
        if (this.state.begin) {
            params.start_time = this.state.begin
        }
        if (this.state.end) {
            params.end_time = this.state.end
        }
        if (key) {
            params.username = key
        }
        this.props.onLoad(pager, agent.User.get(params));
    };
    handleAdd = e => {
        this.props.onCreateUser();
    };
    handleAdds = e => {
        const { onLoad, pagination } = this.props;
        const pager = { ...pagination };
        const key = this.state.keyword;
        const source=this.state.source;
        pager.current = 1;
        const params = {
            page: pager.current,
            size: pagination.pageSize,
            orderby: "-id",
        };
        if (this.state.begin) {
            params.start_time = this.state.begin
        }
        if (this.state.end) {
            params.end_time = this.state.end
        }
        if (key) {
            params.username = key
        }
        if (source) {
            params.source = source
        }

        onLoad(pager, agent.User.get(params));
    }
    onChange(date, dateString) {
        this.setState({ begin: dateString[0] });
        this.setState({ end: dateString[1] });
    }
    handleKeywordChange = e => {
        this.setState({ keyword: e.target.value });
    };
    handleSourceChange = e =>{
        this.setState({ source: e.target.value });
    }
    handlSearchWithdraws = e => {
        const { onExports } = this.props;
        const key = this.state.keyword;
        const begin = this.state.begin;
        const end = this.state.end;
        const params = {
            keyword: key,
            begin: begin,
            end: end,
        };
        onExports(params);

        // const {exports} = this.props;
        // if(exports!=null&&exports.length>0){
        //     this.downloadExcel(exports)
        // }
    }
    onUserModalOk = send => {
        const { onUserModalOk } = this.props;
        if (typeof send.userid !== "undefined") {
            return onUserModalOk(send);
        }
        const callback = () => this.onLoad();
        onUserModalOk(send, callback.bind(this));
    };

    render() {
        const {
            results,
            pagination,
            loading,
            updateStatus,
            onUpdateStatus,
            onEditUser,
            onEditQrCode,
            onUserModalCancel,
            onUserModalCancels,
            userEdit,
            userEditModal,
            userEditModals,
            confirmLoading,
            errors,
            history,
            fetchError,
        } = this.props;
        if (fetchError) {
            return <ReLogin history={history} />;
        }
        pagination.total = results.total;
        const data = results.users;

        for (const record of data) {
            // record.loading = true;
            if (updateStatus.userid === record.userid) {
                record.loading = updateStatus.loading;
                record.status = updateStatus.status;
            }
            if (userEdit.userid === record.userid) {
                for (const key in userEdit) {
                    record[key] = userEdit[key];
                }
            }

            record.handleStatusChange = value => {
                const send = {
                    userid: record.userid,
                    status: value,
                };
                onUpdateStatus(send);
            };
            record.handleEditClick = e => {
                e.preventDefault();
                e.stopPropagation();
                const send = {
                    ...record,
                };
                onEditUser(send);
            };
            record.handleEditClicks = e => {
                e.preventDefault();
                e.stopPropagation();
                const send = {
                    ...record,
                };
                onEditQrCode(send);
            };
        }
        const userModalProps = {
            onUserModalOk: this.onUserModalOk.bind(this),
            onUserModalCancel,
            userEdit,
            confirmLoading,
            visible: userEditModal,
            user: userEdit,
            errors,
        };
        const userModalPropss = {
            onUserModalOk: this.onUserModalOk.bind(this),
            onUserModalCancels,
            userEdit,
            confirmLoading,
            visible: userEditModals,
            user: userEdit,
            errors,
        };
        const keyword = this.state.keyword;
        const source = this.state.source;
        return (
            <div>
                <Button
                    onClick={this.handleAdd}
                    type="primary"
                    style={{ marginBottom: 16 }}
                >
                    添加用户
                </Button>

                <span style={{ marginLeft: "15%" }}>用户名</span>
                <input
                    type="text"
                    name=""
                    value={keyword}
                    onChange={this.handleKeywordChange}
                    className="searchSkin"
                    placeholder="邮箱/手机号"
                    style={{ width: '200px' }}
                />
                <span style={{ marginLeft: "20px" }}>用户来源</span>
                <input
                    type="text"
                    name=""
                    value={source}
                    onChange={this.handleSourceChange}
                    className="searchSkin"
                    placeholder="IP"
                    style={{ width: '200px' }}
                />
                <span>日期范围:</span>
                <RangePicker style={{ marginLeft: "20px" }} onChange={this.onChange.bind(this)} />
                <Button
                    onClick={this.handleAdds}
                    type="primary"
                    style={{ marginBottom: 16, marginLeft: "20px" }}
                >
                    搜索
                </Button>
                <Button
                    type="primary"
                    onClick={this.handlSearchWithdrawss}
                    style={{ marginBottom: 16, marginLeft: "20px" }}
                >
                    导出Excel
                </Button>
                <Table
                    columns={columns}
                    rowKey={record => record.userid}
                    dataSource={data}
                    pagination={pagination}
                    loading={loading}
                    onChange={this.handleTableChange}
                />
                <Modal {...userModalProps} />
                <QrCode {...userModalPropss} />
            </div>
        );
    }
}
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(List);
