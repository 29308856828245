import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Form, Modal, Input } from "antd";
const mapStateToProps = state => ({
});

const mapDispatchToProps = dispatch => ({});

class OfflineAddModel extends React.Component {
    static propTypes = {
        addShow: PropTypes.bool,
        onModalOk: PropTypes.func,
        onModalCancel: PropTypes.func,
        confirmLoading:PropTypes.bool,
    };

    handleSubmit = e => {
        e.preventDefault();
    };
    
    //提交数据
    handleAddModalOk = e => {
        const { form, onModalOk } = this.props;
        form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                const send={
                    name:values.name,
                    count:values.count,
                }
                onModalOk(send)
            }
        })
    };

    // 1-10000校验
    handleValidator = (rule, val, callback) => {
        if (!val) {
            callback();
        }
        let validateResult = true;  // 自定义规则
        if(parseInt(val)>=1 && parseInt(val)<=10000){
            validateResult = true;
        }else{
            validateResult = false;
        }
        if (!validateResult) {
            callback('授权曲目规模只能是(1-10000)数字!');
        }
        callback();
    }
    
    render() {
        const {
            addShow,
            confirmLoading,
            onModalCancel,
        } = this.props;
        if (!addShow) {
            return null;
        }
        const { getFieldDecorator } = this.props.form;
        const formItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 8 },
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 16 },
            },
        };
        const tltle =  `添加线下用户`;
        const no =false;
        return (
            <Modal
                title={tltle}
                centered
                destroyOnClose
                confirmLoading={confirmLoading}
                visible={addShow}
                onOk={this.handleAddModalOk}
                onCancel={onModalCancel}
                maskClosable={no}
            > 
                <Form onSubmit={this.handleSubmit}>
                    <Form.Item {...formItemLayout} label="合作商名称">
                        {getFieldDecorator("name", {
                            rules: [
                                {
                                    required: true,
                                    message: "请输入合作商名称!",
                                },
                                {
                                    pattern: new RegExp(/^.{1,32}$/),
                                    required: true,
                                    message: "合作商名称，最多32个汉字!",
                                },
                            ],
                        })(<Input />)}
                    </Form.Item>
                    <Form.Item {...formItemLayout} label="授权曲目规模(1-10000)">
                        {getFieldDecorator("count", {
                            rules: [
                                {
                                    required: true,
                                    message: "请输入授权曲目规模!",
                                },
                                {
                                    validator: this.handleValidator
                                },
                            ],
                        })(<Input />)}
                    </Form.Item>
                </Form>
            </Modal>
        );
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Form.create({ name: "OfflineAddModel" })(OfflineAddModel));
