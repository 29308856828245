import {
    AUDIO_PAGE_LOADED,
    AUDIO_PAGE_UNLOADED,
    AUDIO_STATUS_UPDATE,
    PLAYLIST_PAGE_LOADED,
    PLAYLIST_PAGE_UNLOADED,
    PLAYLIST_INFO_MODAL_SHOW,
    PLAYLIST_INFO_MODAL_HIDE,
    PLAYLIST_INFO_EDIT,
    PLAYLIST_INFO_CRATE,
    ASYNC_START,
    BATCH_AUDIT,
    AUDIO_MODAL_HIDE,
    AUDIO_MODAL_SHOW,
    DOWNLOAD_AUDIO,
} from "../constants/actionTypes";

const defaultState = {
    results: { audios: [] },
    pagination: { pageSize: 10, current: 1 },
    loading: false,
    fetchError: false,
    updateStatus: { audioid: "", status: "", loading: false },
    modal: {
        data: null,
        visible: false,
    },
    audio:{}
};
export default (state = { ...defaultState }, action) => {
    switch (action.type) {
        case PLAYLIST_PAGE_LOADED:
        case AUDIO_PAGE_LOADED: {
            const pagination = action.pager;
            if (!action.error) {
                pagination.total = action.payload.total;
            }
            return {
                ...state,
                fetchError: !!action.error,
                results: action.payload,
                pagination,
                loading: false,
            };
        }
        case AUDIO_STATUS_UPDATE: {
            const updateStatus = { ...state.updateStatus };
            updateStatus.audioid = action.error ? "" : action.send.audioid;
            updateStatus.status = action.send.status;
            updateStatus.loading = false;
            return { ...state, updateStatus };
        }
        case PLAYLIST_INFO_CRATE: {
            return { ...state, confirmLoading: false };
        }
        case DOWNLOAD_AUDIO:{
            return { ...state };
        }
        case PLAYLIST_INFO_EDIT: {
            if (action.error) {
                return { ...state, confirmLoading: false };
            }
            const modal = {
                data: null,
                visible: false,
            };
            const output = {
                ...state,
                modal,
                confirmLoading: false,
            };
            if (Array.isArray(state.results.infos)) {
                const infos = [];
                for (const record of state.results.infos) {
                    if (record.playlistid === action.send.playlistid) {
                        // thumb
                        delete action.send.thumb;
                        record.thumb += "?1";
                        // other
                        const line = { ...record, ...action.send };
                        infos.push(line);
                        continue;
                    }
                    infos.push(record);
                }
                output.results.infos = infos;
            }
            return output;
        }
        case PLAYLIST_INFO_MODAL_SHOW: {
            const modal = {
                data: action.payload,
                visible: true,
            };
            return {
                ...state,
                modal,
            };
        }
        case PLAYLIST_INFO_MODAL_HIDE: {
            const modal = {
                data: null,
                visible: false,
            };
            return {
                ...state,
                modal,
            };
        }

        case ASYNC_START: {
            if (
                [AUDIO_PAGE_LOADED, PLAYLIST_PAGE_LOADED].includes(
                    action.subtype
                )
            ) {
                return { ...state, loading: true };
            }
            if (action.subtype === AUDIO_STATUS_UPDATE) {
                const updateStatus = { ...state.updateStatus };
                updateStatus.audioid = action.send.audioid;
                updateStatus.status = action.send.status;
                updateStatus.loading = true;
                return { ...state, updateStatus };
            }
            if (
                [PLAYLIST_INFO_CRATE, PLAYLIST_INFO_EDIT].includes(
                    action.subtype
                )
            ) {
                return { ...state, confirmLoading: true };
            }
            return state;
        }
        case PLAYLIST_PAGE_UNLOADED:
        case BATCH_AUDIT:
            return  state ;
        case AUDIO_PAGE_UNLOADED:
            return { ...defaultState };
        case AUDIO_MODAL_SHOW: {
            const modal = {
                data: action.payload,
                visible: true,
            };
            return {
                ...state,
                modal,
            };
        }
        case AUDIO_MODAL_HIDE: {
            const modal = {
                data: null,
                visible: false,
            };
            return {
                ...state,
                modal,
            };
        }
        default:
            return state;
    }
};
