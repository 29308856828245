import React from "react";
import { Tooltip, Icon, Select,Button } from "antd";
const Option = Select.Option;

import audioColumns from "./audio-columns";

const columns = [
    {
        title: "Id",
        dataIndex: "id",
        sorter: true,
        defaultSortOrder: "descend",
    },
    audioColumns.Audio,
    audioColumns.Author,
    {
        title: (
            <span>
                Status&nbsp;
                <Tooltip title="`deleted` 状态下，用户不可编辑">
                    <Icon type="question-circle-o" />
                </Tooltip>
            </span>
        ),
        dataIndex: "status",
        filters: [
            { text: "Unreviewed", value: "unreviewed" },
            { text: "Normal", value: "normal" },
            { text: "Deleted", value: "deleted" },
            { text: "Reject", value: "reject" },
        ],
        render: (status, record) => {
            const disabled = record.loading;
            return (
                <div>
                    <div style={{ marginBottom: 20 }}>
                        <img
                            onClick={record.handleTryClick}
                            src={"../img/playing.png"}
                            height="32px"
                            width="68px"
                            shape="square"
                            icon="picture"
                        />
                    </div>
                    <div style={{ marginBottom: 20 }} >
                        <Button type="dashed" onClick={record.handleDownloadClick}>
                            下载
                        </Button>
                        {/* <a href="http://backend-api.musiness.bmi/audio/download/" download> 下载</a> */}
                    </div>
                    <div style={{ marginBottom: 20 }}>
                        <Button type="dashed" onClick={record.handleEditClick}>
                            编辑
                        </Button>
                    </div>
                    <Select
                        defaultValue={status}
                        style={{ width: 120 }}
                        onChange={record.handleStatusChange}
                        loading={record.loading}
                        disabled={disabled}
                    >
                        <Option value="normal">Normal</Option>
                        <Option value="reject">Reject</Option>
                        <Option value="deleted">Deleted</Option>
                        <Option value="unreviewed">Unreview</Option>
                    </Select>
                </div>
            );
        },
    },
];

export default () => {
    const data = [];
    for (const record of columns) {
        data.push({ ...record });
    }
    return data;
};
