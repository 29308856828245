import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Form, Modal, Input } from "antd";
import agent from "agent";
import {
    COUPONCODE_FONT,
} from "constants/actionTypes";
import "./PriceUpdateModal.less";
const mapStateToProps = state => ({
    ...state.memberset,
});
const mapDispatchToProps = dispatch => ({
    codeadd: (pager, payload) =>
        dispatch({ type: COUPONCODE_FONT, pager, payload }),
});

class PriceAddModal extends React.Component {
    static propTypes = {
        addShow: PropTypes.bool,
        onModalOk: PropTypes.func,
        onModalCancel: PropTypes.func,
        confirmLoading: PropTypes.bool,
        codeadd: PropTypes.func.isRequired,
        onLoad: PropTypes.func.isRequired
    };

    handleSubmit = e => {
        e.preventDefault();
    };

    //提交数据
    handleAddModalOk = e => {
        console.log(this.props)
        const { codeadd, pagination, onModalCancel, form, onModalOk } = this.props;
        form.validateFieldsAndScroll((err, values) => {
            const pager = { ...pagination };
            const params = {
                code: values.title,
            };
            codeadd(pager, agent.Couponcode.post(params));
            onModalCancel()
            onModalOk()
        })
    };

    render() {
        const {
            addShow,
            confirmLoading,
            onModalCancel,
        } = this.props;

        if (!addShow) {
            return null;
        }

        const { getFieldDecorator } = this.props.form;
        const formItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 8 },
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 16 },
            },
        };
        const tltle = `添加优惠码`;
        const no = false;
        return (
            <Modal
                title={tltle}
                centered
                destroyOnClose
                confirmLoading={confirmLoading}
                visible={addShow}
                onOk={this.handleAddModalOk}
                onCancel={onModalCancel}
                maskClosable={no}
            >
                <Form onSubmit={this.handleSubmit}>
                    <Form.Item {...formItemLayout} label="优惠码">
                        {getFieldDecorator("title", {
                            rules: [
                                {
                                    required: true,
                                    message: "请输入优惠码!",
                                },
                            ],
                        })(<Input />)}
                    </Form.Item>
                </Form>
            </Modal>
        );
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Form.create({ name: "PriceAddModal" })(PriceAddModal));
