import React from "react";
import { Table, Button, Modal } from "antd";
import agent from "agent";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
    PURCHASE_PAGE_UNLOADED,
    PURCHASE_PAGE_LOADED,
    PURCHASE_STATUS_UPDATE,
    ORDER_PURCHASE,
} from "constants/actionTypes";

import ReLogin from "components/ReLogin";

import columnsCreate from "./columns";
const columns = columnsCreate();

for (const record of columns) {
    if (record.dataIndex === "orderid") {
        delete record.sorter;
    } else if (record.dataIndex === "status") {
        delete record.filters;
    }
}

const mapStateToProps = state => ({
    ...state.purchase,
});

const mapDispatchToProps = dispatch => ({
    onLoad: (pager, payload) =>
        dispatch({ type: PURCHASE_PAGE_LOADED, pager, payload }),
    onUnload: () => dispatch({ type: PURCHASE_PAGE_UNLOADED }),
    onUpdateStatus: send =>
        dispatch({
            type: PURCHASE_STATUS_UPDATE,
            send,
            payload: agent.Purchase.putStatus(send),
        }),
    onPurchase: params => dispatch({ type: ORDER_PURCHASE, ...params }),
});

class Purchase extends React.Component {
    static propTypes = {
        pagination: PropTypes.object.isRequired,
        results: PropTypes.object.isRequired,
        loading: PropTypes.bool.isRequired,
        onLoad: PropTypes.func.isRequired,
        onUnload: PropTypes.func.isRequired,
        fetchError: PropTypes.bool.isRequired,
        onUpdateStatus: PropTypes.func.isRequired,
        onPurchase: PropTypes.func.isRequired,

        updateStatus: PropTypes.shape({
            orderid: PropTypes.string.isRequired,
            status: PropTypes.string.isRequired,
            loading: PropTypes.bool.isRequired,
        }).isRequired,
    };

    componentDidMount() {
        this.handleRefreashPage();
    }

    componentWillUnmount() {
        this.props.onUnload();
    }

    handleRefreashPage = e => {
        const { onLoad, pagination } = this.props;
        const pager = { ...pagination };
        pager.current = 1;
        const params = {
            page: pager.current,
            size: pagination.pageSize,
            orderby: "-id",
            status: "unreviewed",
        };
        onLoad(pager, agent.Purchase.offline(params));
    };

    handleTableChange = (pagination, filters, sorter) => {
        const pager = { ...this.props.pagination };
        pager.current = pagination.current;
        const params = {
            page: pager.current,
            size: pagination.pageSize,
            orderby: "-id",
            status: "unreviewed",
        };

        this.props.onLoad(pager, agent.Purchase.offline(params));
    };
    onPurchase = orderid => {
        const send = { orderid };
        this.props.onPurchase({
            send,
            payload: agent.Purchase.post(send),
        });
    };

    render() {
        const {
            results,
            pagination,
            loading,
            history,
            fetchError,
            updateStatus,
            onUpdateStatus,
        } = this.props;
        if (fetchError) {
            return <ReLogin history={history} />;
        }
        pagination.total = results.total;

        const data = results.purchases;

        for (const record of data) {
            if (updateStatus.orderid === record.orderid) {
                record.loading = updateStatus.loading;
                record.status = updateStatus.status;
            }

            record.handleStatusChange = value => {
                const send = {
                    orderid: record.orderid,
                    status: value,
                };
                onUpdateStatus(send);
            };

            record.handlePurchaseClick = () => {
                Modal.confirm({
                    title: "确认提交订单吗？操作不可恢复！",
                    content: `将要提交订单： ${record.orderid}`,
                    okText: "完成交易",
                    okType: "danger",
                    cancelText: "No",
                    okButtonProps: {
                        disabled: record.updating,
                    },
                    onOk: () => {
                        // console.log("OK");
                        this.onPurchase(record.orderid);
                    },
                });
            };
        }
        return (
            <div>
                <Button
                    onClick={this.handleRefreashPage}
                    type="primary"
                    style={{ marginBottom: 16 }}
                >
                    获取新的数据
                </Button>
                <Table
                    className="c382-purchase-list"
                    columns={columns}
                    rowKey={record => record.orderid}
                    dataSource={data}
                    pagination={pagination}
                    loading={loading}
                    onChange={this.handleTableChange}
                />
            </div>
        );
    }
}
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Purchase);
