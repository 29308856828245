import { Message } from "antd";

// Create a defaults context
const request = require("superagent-use")(require("superagent"));
/* A sample superagent plugin/middleware. */
const prefix = require("superagent-prefix");

const apiurl = (() => {
    // 线上部署
    if (process.env.REACT_APP_PRODUCT === "yes") {
        return "https://backend-api.musiness.vip";
    }
    // 线上测试
    if (process.env.REACT_APP_PRODUCT === "test") {
        return "http://beta-backend-api.musiness.vip";
    }
    // 内网测试
    if (process.env.REACT_APP_PRODUCT === "bmi") {
        return "http://backend-api.musiness.bmi";
    }
    // 开发测试
    //return "http://backend-api.musiness.bmi";
    return "http://localhost:9003";
    // return "http://1.119.145.114:9003";
    // return "http://192.168.1.160:9007";
    // return "http://192.168.1.114:9003";
})();

request.use(prefix(apiurl));

const buildURLQuery = obj => {
    return Object.entries(obj)
        .map(pair => pair.map(encodeURIComponent).join("="))
        .join("&");
};
const tokenKey = "X-API-TOKEN";
let token = "";
const setToken = res => {
    try {
        const _token = res.headers[tokenKey.toLowerCase()];
        if (typeof _token === "string" && _token.length > 10) {
            token = _token;
            window.localStorage.setItem("jwt", token);
        }
    } catch (e) {
        console.error("setToken:", e, res);
    }
};
const getToken = () => {
    if (token) {
        return token;
    }
    const _token = window.localStorage.getItem("jwt");
    token = _token;
    return token;
};
const rmToken = () => {
    token = "";
    window.localStorage.setItem("jwt", token);
};

request.use(req => {
    const _token = getToken();
    req.set(tokenKey, `${_token}`);
    return req;
});

const createReqField = (req, send) => {
    for (const [key, value] of Object.entries(send)) {
        if (typeof value === "undefined") {
            continue;
        }
        if (value === null) {
            continue;
        }
        req.field(key, value);
    }
};

const Auth = {
    current: () => request.get("/admin/profile"),
    login: (email, password) =>
        request.post("/admin/signin", { email, password }),
    save: user => request.put("/admin/profile", { user }),
};

// jgq
const Admin = {
    get: (params = {}) =>
        request.get(
            "/admin/users",
            buildURLQuery({
                ...params,
            })
        ),
    put: (send = {}) => {
        const req = request.put(`/admin/profile/${send.adminid}`);
        createReqField(req, send);
        return req;
    },
    post: (send = {}) => {
        const req = request.post(`/admin/signup`);
        createReqField(req, send);
        return req;
    },
};

const User = {
    get: (params = {}) =>
        request.get(
            "/users",
            buildURLQuery({
                ...params,
            })
        ),
    put: (send = {}) => {
        const req = request.put(`/users/profile/${send.userid}`);
        createReqField(req, send);
        return req;
    },
    post: (send = {}) => {
        const req = request.post(`/users/profile`);
        createReqField(req, send);
        return req;
    },
};
const Memberset = {
    get: (params = {}) =>
        request.get("/discountsaccount", {
            ...params,
        }),
    post: (send = {}) => {
        const req = request.post(`/discountsaccount`, send);
        return req;
    },
    delete: send => request.delete(`/discountsaccount/${send}`),
     
    update: (send = {}) => {
        const req = request.post(`/discountsaccount/update`, send);
        return req;
    },
};

/*const ProducerDiscount={
    get: (params = {}) =>
        request.get("/producerdiscount", {
            ...params,
        }),
    post: (send = {}) => {
        const req = request.post(`/discountsaccountun`, send);
        return req;
    },
    delete: send => request.delete(`/discountsaccountun/${send}`),
     
    update: (send = {}) => {
        const req = request.post(`/discountsaccountun/update`, send);
        return req;
    },
}*/

const UnMemberset = {
    get: (params = {}) =>
        request.get("/discountsaccountun", {
            ...params,
        }),
    post: (send = {}) => {
        const req = request.post(`/discountsaccountun`, send);
        return req;
    },
    delete: send => request.delete(`/discountsaccountun/${send}`),
     
    update: (send = {}) => {
        const req = request.post(`/discountsaccountun/update`, send);
        return req;
    },
};


const Couponcode = {
    get: (params = {}) =>
        request.get("/couponcode", {
            ...params,
        }),
    post: (send = {}) => {
        const req = request.post(`/couponcode`, send);
        return req;
    },
    delete: send => request.delete(`/couponcode/${send}`)
};
/**修改订单总价 */
const Price = {
    search: (params = {}) =>
        request.get(
            "/audio/search",
            buildURLQuery({
                ...params,
            })
        ),
    updatePrice: (send = {}) => {
        // send.price = send.price;
        const req = request.put(`/order/updatePrice/${send.id}`, send);
        return req;
    },
};
const Audio = {
    download:send => request.get(`/audio/download/${send}`),
    search: (params = {}) => 
        request.get(
            "/audio/search",
            buildURLQuery({
                ...params,
            })
        ),
    get: musicid => request.get(`/audio/${musicid}`),
    putStatus: (send = {}) =>
        request.put(`/audio/status/${send.audioid}`, {
            ...send,
        }),
    susume: {
        get: (params = {}) => {
            return request.get(`/audio/susume`, {
                ...params,
            });
        },
        post: send => request.post(`/audio/susume`, send),
        delete: send => request.delete(`/audio/susume/${send.id}`),
    },
    playlist: {
        get: (params = {}) => {
            return request.get(`/playlist/${params.playlist_id}`, {
                ...params,
            });
        },
        post: send => request.post(`/playlist`, send),
        delete: send => request.delete(`/playlist/${send.id}`),
    },
    playlistInfo: {
        get: (params = {}) =>
            request.get("/playlist/info", {
                ...params,
            }),
        post: (send = {}) => {
            const req = request.post(`/playlist/info`);
            createReqField(req, send);
            return req;
        },
        put: (send = {}) => {
            const req = request.put(`/playlist/info/${send.id}`);
            createReqField(req, send);
            return req;
        },
        delete: send => request.delete(`/playlist/info/${send.id}`),
    },
    batchAudit: send => {
        const req = request.get(`/audio/batch/${send.email}`);
        return req;
    },
    updateAudio: send => {
        const req = request.put(`/audio/updateAudio/${send.id}`);
        for (const [key, value] of Object.entries(send)) {
            req.field(key, value || "");
        }
        return req;
    },
};
const Page = {
    contactus: {
        get: (params = {}) =>
            request.get("/page/contactus", {
                ...params,
            }),
        put: (send = {}) => {
            const req = request.put(`/page/contactus/${send.id}`, send);
            return req;
        },
        delete: send => request.delete(`/page/contactus/${send.id}`),
    },
    qa: {
        get: (params = {}) =>
            request.get("/page/qa", {
                ...params,
            }),
        post: (send = {}) => {
            const req = request.post(`/page/qa`, send);
            return req;
        },
        put: (send = {}) => {
            const req = request.put(`/page/qa/${send.id}`, send);
            return req;
        },
        delete: send => request.delete(`/page/qa/${send.id}`),
    },
    news: {
        get: (params = {}) =>
            request.get("/page/news", {
                ...params,
            }),
        post: (send = {}) => {
            const req = request.post(`/page/news`, send);
            return req;
        },
        put: (send = {}) => {
            const req = request.put(`/page/news/${send.id}`, send);
            return req;
        },
        delete: send => request.delete(`/page/news/${send.id}`),
    },
    partner: {
        get: (params = {}) =>
            request.get("/page/partner", {
                ...params,
            }),
        post: (send = {}) => {
            const req = request.post(`/page/partner`);
            createReqField(req, send);
            return req;
        },
        put: (send = {}) => {
            const req = request.put(`/page/partner/${send.id}`);
            createReqField(req, send);
            return req;
        },
        delete: send => request.delete(`/page/partner/${send.id}`),
    },
};
const Captcha = {
    get: (params = {}) =>
        request.get(
            "/captcha",
            buildURLQuery({
                ...params,
            })
        ),
};
const Condition = {
    getAll: () => request.get("/condition"),
};

const Producer = {
    get: (params = {}) =>
        request.get(
            "/producer/users",
            buildURLQuery({
                ...params,
            })
        ),
    putStatus: (send = {}) =>
        request.put(`/producer/status/${send.userid}`, {
            ...send,
        }),
    putPriceStatus: (send = {}) =>
        request.put(`/producer/putpricestatus`, {
            ...send,
        }),
};

const Withdraw = {
    get: (params = {}) =>
        request.get(
            "/withdraw/pay",
            buildURLQuery({
                ...params,
            })
        ),
    out: (params = {}) =>
        request.get("/withdraw/export", {
            ...params,
        }),
    putStatus: (send = {}) =>
        request.put(`/withdraw/${send.payid}`, {
            ...send,
        }),
};
const Member = {
    post: (send = {}) => {
        const req = request.post(`/subscribe`, send);
        return req;
    },
    postStatus: (send = {}) => {
        const req = request.post(`/subscribe/update`, send);
        return req;
    },
};

const Order = {
    get: (params = {}) =>
        request.get(
            "/order",
            buildURLQuery({
                ...params,
            })
        ),
    delete: send => request.delete(`/order/${send.orderid}`),
};

const Purchase = {
    post: send => {
        send.auth = "b026324c6904b2a9cb4b88d6d61c81d1";
        return request.post(`/purchase`, send);
    },
    get: (params = {}) =>
        request.get(
            "/purchase/list",
            buildURLQuery({
                ...params,
            })
        ).timeout({
            response: 1000 * 60 * 20,  // Wait 5 seconds for the server to start sending,
        }),
    postStatus: (send = {}) => {
        const req = request.post(`/purchase/receipt`, send);
        return req;
    },
    offline: (params = {}) =>
        request.get(
            "/purchase/offline",
            buildURLQuery({
                ...params,
            })
        ),
    putStatus: send => {
        return request.put(`/purchase/offline/${send.orderid}`, send);
    },
};
const Dashboard = {
    load: () => request.get("/dashboard"),
};
const Customized = {
    get: (params = {}) =>
        request.get("/order/customized", buildURLQuery({ ...params })),
    put: (send = {}) => {
        const req = request.put(`/order/putcustomized/${send.id}`);
        createReqField(req, send);
        return req;
    },
};
const PriceGroup = {
    get: (params = {}) =>
        request.get(
            "/producer/pricegroup",
            buildURLQuery({
                ...params,
            })
        ),

    create: send => {
        return request.post(`/producer/createpricegroup`, send);
    },

    put: (send = {}) => {
        const req = request.put(`/producer/updatepricegroup`, send);
        return req;
    },
    updateStatus: (send = {}) => {
        const req = request.put(`/producer/updatestatus`, send);
        return req;
    },
};

const Cooperation = {
    // 线下
    getOffline: (params = {}) =>
        request.get(
            "/partner/offline",
            buildURLQuery({
                ...params,
            })
        ),
    createOff: send => {
        return request.post(`/partner/offline`, send);
    },
    deleteOff: send => {
        return request.delete(`/partner/offline/${send}`);
    },
    enableOff: (send, params={}) => {
        return request.post(`/partner/offline/${send}`, params);
    },
    downloadOffLine: (params = {}) =>
        request.get(
            "/partner/offline/private",
            buildURLQuery({
                ...params,
            })
        ),

    // 线上
    getOnline: (params = {}) =>
        request.get(
            "/partner/online",
            buildURLQuery({
                ...params,
            })
        ),
    createOnline: send => {
        return request.post(`/partner/online`, send);
    },
    deleteOnline: send => {
        return request.delete(`/partner/online/${send}`);
    },
    enableOnline: (send, params={}) => {
        return request.post(`/partner/online/${send}`, params);
    },
};

const CustomerData = {
    getUsersource: (params = {},callback) =>
    {
        request.get("/custdata/usersource", 
            buildURLQuery({ 
                ...params,
            })
        ).end((err,res)=>{
            if(err)
                return err;
            else 
                callback(res.body); 
            console.log("getUsersource "+JSON.stringify(res));
        });
    }, 
    getSearchkeyword: (params = {},callback) => 
    {
        request.get("/custdata/usersearch", 
            buildURLQuery({
                ...params,
            })
        ).end((err,res)=>{
            if(err)
                return err;
            else 
                callback(res.body); 
        });
    },
};

const SpecialSeller={
    getUserList: (params = {},callback) =>
    {        
        request.get("/specialseller/sellerlist", 
            buildURLQuery({
                ...params,
            })
        ).end((err,res)=>{
            if(err)
                return err;
            else 
                callback(res.body); 
        });
    },
    searchUsers: (params = {},callback) =>
    {        
        request.get("/specialseller/searchusers",  
            buildURLQuery({
                ...params,
            })
        ).end((err,res)=>{
            if(err)
            {
                console.log(err+" hello simon");
                return err;
            }
            else 
                callback(res.body); 
                
        });
    },
    canDiscountUser: (params = {},callback) =>
    { 
        request.get("/specialseller/candiscount",  
            buildURLQuery({
                ...params,
            })
        ).end((err,res)=>{
            if(err)
                return err;
            else  
                Message.success("更新成功") 
        }); 
    }
};

export default {
    Auth,
    Admin,
    User,
    Audio,
    Price,
    Order,
    Captcha,
    Producer,
    Purchase,
    Dashboard,
    Withdraw,
    Condition,
    Page,
    setToken,
    getToken,
    rmToken,
    Customized,
    PriceGroup,
    Member,
    Memberset,
    UnMemberset,
    Couponcode,
    Cooperation,
    CustomerData,
    SpecialSeller,
    getApiUrl: uri => {
        if (typeof uri !== "string") {
            return apiurl;
        }
        if (uri.indexOf("data:") === 0) {
            return uri;
        }
        if (uri.indexOf("http:") === 0) {
            return uri;
        }
        return apiurl + "/" + uri;
    },
};
